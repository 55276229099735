var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
export var LegalModal = function (_a) {
    var content = _a.content;
    return (_jsx("div", __assign({ "data-testid": "legal-modal" }, { children: _jsx(SanitizeHtml, { stringToSanitize: content, tag: "div", className: "content" }) })));
};
export var getLegalModalprops = function () {
    return {};
};
