export var applyOpacityToHexColor = function (hexColor, opacity) {
    if (typeof hexColor !== "string")
        return "#ffffff4a";
    try {
        var parsedOpacity = typeof opacity === "string" ? parseFloat(opacity) : opacity;
        if (parsedOpacity === undefined || parsedOpacity === null || parsedOpacity >= 1 || parsedOpacity < 0 || (hexColor === null || hexColor === void 0 ? void 0 : hexColor.length) !== 7)
            return hexColor;
        return "".concat(hexColor).concat(Math.floor(parsedOpacity * 255)
            .toString(16)
            .padStart(2, "0"));
    }
    catch (_a) {
        return hexColor;
    }
};
