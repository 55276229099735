var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import sanitizeHtml from "sanitize-html";
import Typography from "../../ui/Typography/Typography";
import { useAppDispatch } from "../../../store";
import { displayModal, selectRegistrationFormLegals } from "@adm-media/adam-store-plugin";
import { FAQ_MODAL, LEGAL_MODAL } from "../../modals";
import { useSelector } from "react-redux";
import "./SanitizeHtml.scss";
var Link = Typography.Link;
export var SanitizeHtmlString = function (_a) {
    var stringToSanitize = _a.stringToSanitize, tag = _a.tag, className = _a.className, props = __rest(_a, ["stringToSanitize", "tag", "className"]);
    if (!stringToSanitize)
        return null;
    var clean = sanitizeHtml(stringToSanitize, {
        allowedTags: ["p", "b", "i", "em", "strong", "a", "ul", "li", "ol", "br"],
        allowedAttributes: {
            a: ["href", "target", "style", "rel", "title"],
            p: ["style"],
            b: ["style"],
            i: ["style"],
            em: ["style"],
            strong: ["style"],
            ul: ["style"],
            li: ["style"],
            ol: ["style"]
        }
    });
    var Tag = tag;
    return _jsx(Tag, { "data-testid": props["data-testid"], className: className, dangerouslySetInnerHTML: { __html: clean } });
};
export var SanitizeHtml = function (_a) {
    var stringToSanitize = _a.stringToSanitize, tag = _a.tag, className = _a.className, legalsName = _a.legalsName;
    if (!stringToSanitize)
        return null;
    var dispatch = useAppDispatch();
    var legals = useSelector(selectRegistrationFormLegals);
    var knownTagsAndActions = [
        {
            tag: "FAQ_POPUP",
            action: function () { return dispatch(displayModal({ name: FAQ_MODAL })); }
        },
        {
            tag: "TOS_POPUP",
            action: function () {
                var _a, _b;
                return dispatch(displayModal({
                    name: LEGAL_MODAL,
                    props: {
                        content: legalsName ? (_a = legals === null || legals === void 0 ? void 0 : legals.find(function (legal) { return (legal === null || legal === void 0 ? void 0 : legal.field_name) === legalsName; })) === null || _a === void 0 ? void 0 : _a.content : ((_b = legals === null || legals === void 0 ? void 0 : legals[0]) === null || _b === void 0 ? void 0 : _b.content) || ""
                    }
                }));
            }
        },
        {
            tag: "EXTERNAL_LINK",
            linkAttributes: { rel: "external", target: "_blank" }
        }
    ];
    var matches = knownTagsAndActions === null || knownTagsAndActions === void 0 ? void 0 : knownTagsAndActions.filter(function (tagAndActions) {
        var baseRegex = new RegExp("\\[" + tagAndActions.tag + "](.*?)\\[\\/" + tagAndActions.tag + "]");
        var findAllRegex = new RegExp(baseRegex, "g");
        return stringToSanitize === null || stringToSanitize === void 0 ? void 0 : stringToSanitize.match(findAllRegex);
    });
    if (matches.length === 0) {
        return _jsx(SanitizeHtmlString, { "data-testid": "sanitize-html", tag: tag, className: className, stringToSanitize: stringToSanitize });
    }
    else {
        var strings_1 = [stringToSanitize];
        matches.forEach(function (match) {
            var stringsArray = strings_1.map(function (element) {
                var splitted = element.split(new RegExp("(\\[" + match.tag + "].*?\\[\\/" + match.tag + "])"));
                return splitted;
            });
            strings_1 = stringsArray.reduce(function (prev, next) { return __spreadArray(__spreadArray([], prev, true), next, true); }, []);
        });
        var Tag = tag;
        return (_jsx(Tag, __assign({ className: className, "data-testid": "sanitize-html" }, { children: strings_1.map(function (string) {
                var possibleMatches = matches.map(function (match) {
                    return __assign(__assign({}, match), { baseRegex: new RegExp("\\[" + match.tag + "](.*?)\\[\\/" + match.tag + "]"), findAllRegex: new RegExp(new RegExp("\\[" + match.tag + "](.*?)\\[\\/" + match.tag + "]"), "g") });
                });
                var matchingMatch = possibleMatches.find(function (match) { return string.match(match.findAllRegex); });
                var isOnlyOneMatch = possibleMatches.length === 1;
                if (matchingMatch) {
                    var text = string.replace("[".concat(matchingMatch.tag, "]"), "").replace("[/".concat(matchingMatch.tag, "]"), "");
                    return (_jsx(React.Fragment, { children: matchingMatch.action ? (_jsx("b", __assign({ className: matchingMatch.tag + "-action", onClick: function (e) {
                                e === null || e === void 0 ? void 0 : e.preventDefault();
                                matchingMatch.action();
                            }, style: { cursor: "pointer", display: "inline", textDecoration: "underline" } }, { children: _jsx(SanitizeHtmlString, { tag: "span", stringToSanitize: text }) }))) : matchingMatch.linkAttributes ? (_jsx(Link, __assign({ className: matchingMatch.tag + "-action", rel: matchingMatch.linkAttributes.rel, target: matchingMatch.linkAttributes.target, href: "https://" + text, style: { cursor: "pointer", fontWeight: "bold", textDecoration: "underline" } }, { children: text }))) : null }, text));
                }
                else {
                    return (_jsx(React.Fragment, { children: _jsx(SanitizeHtmlString, { className: isOnlyOneMatch ? possibleMatches[0].tag + "-text" : undefined, tag: "span", stringToSanitize: string }) }, string));
                }
            }) })));
    }
};
