var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from "../Button";
import { useSelector } from "react-redux";
import { selectLocaleLanguage, selectPublicConfig } from "@adm-media/adam-store-plugin";
import classNames from "classnames";
import { DownloadOutlined } from "@ant-design/icons";
import "./HomepageDownloadButton.scss";
export var HomepageDownloadButton = function (_a) {
    var _b;
    var _c, _d;
    var className = _a.className, block = _a.block, hideLabelOnMobile = _a.hideLabelOnMobile;
    var publicConfig = useSelector(selectPublicConfig);
    var locale = useSelector(selectLocaleLanguage);
    var downloadButtonConfig = locale ? ((_d = (_c = publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.homepageDownloadButton) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d[locale]) || undefined : undefined;
    var baseClass = "homepage-download-button";
    if (!(downloadButtonConfig === null || downloadButtonConfig === void 0 ? void 0 : downloadButtonConfig.downloadUrl))
        return null;
    return (_jsx(Button, __assign({ className: classNames(baseClass, className, (_b = {}, _b["".concat(baseClass, "--hide-label-on-mobile")] = hideLabelOnMobile, _b)), target: "_blank", block: block, href: downloadButtonConfig.downloadUrl, icon: _jsx(DownloadOutlined, {}), "data-testid": "homepage-download-button", title: downloadButtonConfig === null || downloadButtonConfig === void 0 ? void 0 : downloadButtonConfig.buttonLabel }, { children: _jsx("span", __assign({ className: "".concat(baseClass, "__label") }, { children: downloadButtonConfig === null || downloadButtonConfig === void 0 ? void 0 : downloadButtonConfig.buttonLabel })) })));
};
