import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HomePage } from "./Home";
import { useAppDispatch } from "../store";
import { displayModal, hideModal, logoutUserThunk, selectUser } from "@adm-media/adam-store-plugin";
import { CONFIRM_UNSUBSCRIPTION_MODAL } from "../components/modals";
import { Paths } from ".";
import { useSelector } from "react-redux";
export var UnsubscribePage = function () {
    var unsubscribeHash = useParams().unsubscribeHash;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var _a = useSelector(selectUser), token = _a.token, identity = _a.identity;
    var _b = useState(!!(identity === null || identity === void 0 ? void 0 : identity.email) && !!token), isLoggedIn = _b[0], setIsLoggedIn = _b[1];
    useEffect(function () {
        setIsLoggedIn(!!(identity === null || identity === void 0 ? void 0 : identity.email) && !!token);
    }, [identity === null || identity === void 0 ? void 0 : identity.email, token]);
    useEffect(function () {
        if (isLoggedIn)
            dispatch(logoutUserThunk());
    }, [isLoggedIn]);
    useEffect(function () {
        if (!!unsubscribeHash) {
            dispatch(displayModal({
                name: CONFIRM_UNSUBSCRIPTION_MODAL,
                props: {
                    unsubscribeHash: unsubscribeHash
                }
            }));
        }
        else
            navigate(Paths.home);
    }, [unsubscribeHash]);
    useEffect(function () {
        return function () {
            dispatch(hideModal({ name: CONFIRM_UNSUBSCRIPTION_MODAL }));
        };
    }, []);
    return _jsx(HomePage, {});
};
