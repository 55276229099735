import { selectAppContent, selectIsLoadingLanguage, selectLoadingDetect } from "@adm-media/adam-store-plugin";
import { useEffect } from "react";
import { useSelector } from "react-redux";
export var useSetWebsiteTitle = function () {
    var contents = useSelector(selectAppContent);
    var isLoadingLanguage = useSelector(selectIsLoadingLanguage);
    var loadingDetect = useSelector(selectLoadingDetect);
    useEffect(function () {
        if (loadingDetect !== "uninitialized" && loadingDetect !== "pending" && isLoadingLanguage === false) {
            document.title = (contents === null || contents === void 0 ? void 0 : contents.site_title) || "Maintenance";
        }
    }, [isLoadingLanguage, loadingDetect]);
};
