var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useDispatch } from "react-redux";
import { displayModal } from "@adm-media/adam-store-plugin";
import { SPEAKER_DETAILS_MODAL } from "../../../modals";
import Card from "../../Card/Card";
import Typography from "../../Typography/Typography";
import Avatar from "../../Avatar/Avatar";
var Meta = Card.Meta;
var Title = Typography.Title, Text = Typography.Text;
export var AgendaItemSpeakers = function (_a) {
    var speakersToShow = _a.speakersToShow, speakersGroupsConfigs = _a.speakersGroupsConfigs, baseClass = _a.baseClass;
    var dispatch = useDispatch();
    return (_jsx(_Fragment, { children: Array.isArray(speakersToShow) && speakersToShow.length > 0 && (_jsx(_Fragment, { children: speakersGroupsConfigs === null ? (_jsx("div", __assign({ className: "".concat(baseClass, "__speakers-item ").concat(baseClass, "__speakers-container") }, { children: speakersToShow === null || speakersToShow === void 0 ? void 0 : speakersToShow.map(function (speaker) {
                    return (_jsx("div", __assign({ className: "".concat(baseClass, "__speaker-wrapper"), "data-testid": "agenda-speaker-information", onClick: function () {
                            return dispatch(displayModal({
                                name: SPEAKER_DETAILS_MODAL,
                                props: speaker
                            }));
                        } }, { children: _jsx(Meta, { className: "".concat(baseClass, "__speaker"), avatar: _jsx(Avatar, { src: speaker.media_url }), title: "".concat(speaker.firstname, " ").concat(speaker.lastname), "data-testid": "agenda-speaker-item" }) }), speaker.id));
                }) }))) : (speakersGroupsConfigs.map(function (speakerConfig) {
                return (_jsxs(React.Fragment, { children: [_jsx("div", __assign({ className: "".concat(baseClass, "__speakers-item ").concat(baseClass, "__speakers-container-label") }, { children: speakerConfig.label })), _jsx("div", __assign({ className: "".concat(baseClass, "__speakers-item ").concat(baseClass, "__speakers-container") }, { children: speakersToShow === null || speakersToShow === void 0 ? void 0 : speakersToShow.filter(function (speaker) { return speakerConfig.speakersIds.includes(speaker.id); }).map(function (speaker) {
                                return (_jsx("div", __assign({ className: "".concat(baseClass, "__speaker-wrapper"), "data-testid": "agenda-speaker-information", onClick: function () {
                                        return dispatch(displayModal({
                                            name: SPEAKER_DETAILS_MODAL,
                                            props: speaker
                                        }));
                                    } }, { children: _jsx(Meta, { className: "".concat(baseClass, "__speaker"), avatar: _jsx(Avatar, { src: speaker.media_url }), title: "".concat(speaker.firstname, " ").concat(speaker.lastname), "data-testid": "agenda-speaker-item" }) }), speaker.id));
                            }) }))] }, speakerConfig.label));
            })) })) }));
};
