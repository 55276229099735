import { useEffect } from "react";
import { useSelector } from "react-redux";
import { logoutUserThunk, selectAppContent, selectNetworkStatus, selectUser } from "@adm-media/adam-store-plugin";
import { useAppDispatch } from "../store";
import { useNotification } from "./useNotification";
export var fallbackNotificationMessage = "You have been logged out because of network problems, please check your internet connection and try again";
export var useLogoutIfOffline = function () {
    var dispatch = useAppDispatch();
    var contents = useSelector(selectAppContent);
    var _a = useSelector(selectUser), token = _a.token, identity = _a.identity;
    var networkStatus = useSelector(selectNetworkStatus);
    var notification = useNotification().notification;
    useEffect(function () {
        if (networkStatus === "OFFLINE" && !!token && !!(identity === null || identity === void 0 ? void 0 : identity.email)) {
            dispatch(logoutUserThunk())
                .unwrap()
                .finally(function () {
                notification.info({ message: contents.offline_forced_logout_notification_message || fallbackNotificationMessage });
            });
        }
    }, [networkStatus, token, identity]);
};
