var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { displayModal, hideModal, selectAppContent } from "@adm-media/adam-store-plugin";
import { LOGIN_MODAL, THANK_YOU_MODAL } from "../../../modals";
import classNames from "classnames";
import { SanitizeHtml } from "../../SanitizeHtml/SanitizeHtml";
export var OpenLoginModalButton = function (_a) {
    var className = _a.className, size = _a.size, props = __rest(_a, ["className", "size"]);
    var dispatch = useDispatch();
    var content = useSelector(selectAppContent);
    var handleShowLoginModal = function () {
        dispatch(hideModal({ name: THANK_YOU_MODAL }));
        dispatch(displayModal({ name: LOGIN_MODAL }));
    };
    return (_jsx(Button, __assign({ className: classNames("open-login-modal-button", className), "data-testid": "open-login-modal-button", onClick: handleShowLoginModal, type: "primary", size: size }, props, { children: _jsx(SanitizeHtml, { tag: "span", stringToSanitize: content.login }) }), "login"));
};
