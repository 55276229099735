export var mapErrorsToFields = function (_a) {
    var error = _a.error, values = _a.values;
    var errors = error.errors;
    var valuesArray = Object.keys(values).map(function (key) {
        return {
            name: key,
            errors: errors[key]
        };
    });
    return valuesArray;
};
