import { useEffect, useState } from "react";
import { useIntersectionObserver } from "./useIntersectionObserver";
export var useAnimationClassName = function (_a) {
    var ref = _a.ref, animation = _a.animation;
    var entry = useIntersectionObserver(ref, {});
    var isVisible = !!(entry === null || entry === void 0 ? void 0 : entry.isIntersecting);
    var _b = useState("not-yet-animated"), elementClass = _b[0], setElementClass = _b[1];
    useEffect(function () {
        if (isVisible)
            setElementClass("animate__animated animate__slow ".concat(typeof animation === "object" && (animation === null || animation === void 0 ? void 0 : animation.animationClass) ? animation === null || animation === void 0 ? void 0 : animation.animationClass : "animate__fadeIn"));
    }, [isVisible]);
    return animation ? elementClass : "";
};
