var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Player from "../../Player/Player";
import Row from "../../../ui/Row/Row";
import Col from "../../../ui/Col/Col";
import { useSelector } from "react-redux";
import { selectActiveMultipleSource, selectCurrentEpisode, selectDocumentVisibilityStatus, selectPlayerSource } from "@adm-media/adam-store-plugin";
import { getDiffTime } from "../../../../utils/date";
import { LIVE, VOD } from "../../../../types/player";
import { startVODFrom } from "../../../../utils/player";
import Card from "../../Card/Card";
import Button from "../../Buttons/Button";
import "./SourcesPreview.scss";
var SourcesPreview = function (_a) {
    var onSelect = _a.onSelect;
    var _b = useSelector(selectPlayerSource), sources = _b.sources, episode_live_started_at = _b.episode_live_started_at;
    var live_started_at = useSelector(selectCurrentEpisode).live_started_at;
    var _c = useState(live_started_at || null), liveStartedAt = _c[0], setLiveStartedAt = _c[1];
    var activeMultipleSource = useSelector(selectActiveMultipleSource);
    useEffect(function () {
        if (episode_live_started_at)
            setLiveStartedAt(episode_live_started_at);
    }, [episode_live_started_at]);
    var _d = useState(activeMultipleSource || sources[0]), currentSource = _d[0], setCurrentSource = _d[1];
    var handleSelectSource = function (source) {
        setCurrentSource(source);
        onSelect(source);
    };
    return (_jsx(Row, __assign({ "data-testid": "player-sources-preview", className: "player-sources-preview", gutter: [12, 12] }, { children: sources.map(function (source) {
            if (currentSource.id === source.id)
                return _jsx("div", { "data-testid": "player-sources-preview-current-source" }, source.id);
            return (_jsx(Col, __assign({ "data-testid": "player-sources-preview-wrapper", xs: 24 }, { children: _jsx(Card, __assign({ bodyStyle: { padding: 0 }, title: source.title, extra: _jsx(Button, __assign({ onClick: function () { return handleSelectSource(source); } }, { children: "Select source" })) }, { children: _jsx(SourcePreviewPlayer, { liveStartedAt: liveStartedAt, source: source }) })) }), source.id));
        }) })));
};
var SourcePreviewPlayer = function (_a) {
    var liveStartedAt = _a.liveStartedAt, source = _a.source;
    var _b = useState(undefined), playerRef = _b[0], setPlayerRef = _b[1];
    var _c = useSelector(selectPlayerSource), channelStatus = _c.status, keepVODCurrentTime = _c.keep_current_time;
    var epispdeId = useSelector(selectCurrentEpisode).id;
    var documentVisibilityStatus = useSelector(selectDocumentVisibilityStatus);
    useEffect(function () {
        if (playerRef && channelStatus === VOD && keepVODCurrentTime !== false) {
            playerRef.currentTime = startVODFrom(epispdeId);
            playerRef === null || playerRef === void 0 ? void 0 : playerRef.play();
        }
    }, [playerRef, channelStatus]);
    useEffect(function () {
        if (playerRef && documentVisibilityStatus === "visible" && channelStatus === LIVE) {
            playerRef.currentTime = getDiffTime(liveStartedAt);
            playerRef.play();
        }
    }, [documentVisibilityStatus, playerRef, liveStartedAt, channelStatus]);
    return (_jsx(Player, { onPlayerReady: function (player) { return setPlayerRef(player); }, hideControls: true, autoplay: true, muted: true, source: { sources: [{ src: source === null || source === void 0 ? void 0 : source.src, type: source === null || source === void 0 ? void 0 : source.type }], textTracks: [] } }));
};
export default SourcesPreview;
