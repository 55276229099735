import { selectStyles, displayModal } from "@adm-media/adam-store-plugin";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INFO_MODAL } from "../components/modals";
export var useShowInitialModal = function () {
    var dispatch = useDispatch();
    var show_initial_popup = useSelector(selectStyles).show_initial_popup;
    useEffect(function () {
        if (!show_initial_popup)
            return;
        dispatch(displayModal({ name: INFO_MODAL }));
    }, [show_initial_popup]);
};
