export var isEpisodesGroups = function (_a) {
    var episodesGroups = _a.episodesGroups, availableEpisodesIds = _a.availableEpisodesIds;
    return (Array.isArray(episodesGroups) &&
        typeof (episodesGroups === null || episodesGroups === void 0 ? void 0 : episodesGroups.length) === "number" &&
        (episodesGroups === null || episodesGroups === void 0 ? void 0 : episodesGroups.length) > 0 &&
        episodesGroups.filter(function (episodeGroup) {
            var isChildrenAvailable = !!episodeGroup.childrenEpisodes.find(function (childrenEpisode) { return availableEpisodesIds.includes(childrenEpisode.id); });
            if (isChildrenAvailable)
                return true;
            else
                return false;
        }).length > 0);
};
