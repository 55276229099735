var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from "../../../ui/Form/Form";
import Input from "../../../ui/Input/Input";
import Button from "../../../ui/Buttons/Button";
import { useSelector } from "react-redux";
import { loginUserThunk, selectAppContent, selectLocaleLanguage, selectLogin } from "@adm-media/adam-store-plugin";
import { useAppDispatch } from "../../../../store";
import { useNotification } from "../../../../hooks/useNotification";
import { LocaleChangeOmitCall, useChangeAppLocale } from "../../../../hooks/useChangeAppLocale";
import { Space } from "antd";
var Item = Form.Item;
export var FirstLogin = function (_a) {
    var setIsFirstLogin = _a.setIsFirstLogin, onSuccessCallback = _a.onSuccessCallback;
    var dispatch = useAppDispatch();
    var form = Form.useForm()[0];
    var locale = useSelector(selectLocaleLanguage);
    var notification = useNotification().notification;
    var changeAppLocale = useChangeAppLocale().changeAppLocale;
    var loadingLogin = useSelector(selectLogin).loading;
    var contents = useSelector(selectAppContent);
    var handleFirstLogin = function (_a) {
        var email = _a.email;
        var loginData = typeof locale === "string"
            ? {
                email: email,
                password: "",
                lang_id: locale
            }
            : {
                email: email,
                password: ""
            };
        dispatch(loginUserThunk(loginData))
            .unwrap()
            .then(function (payload) {
            var _a, _b, _c;
            onSuccessCallback && onSuccessCallback(payload);
            if (!!((_a = payload === null || payload === void 0 ? void 0 : payload.me) === null || _a === void 0 ? void 0 : _a.lang_id) && ((_b = payload === null || payload === void 0 ? void 0 : payload.me) === null || _b === void 0 ? void 0 : _b.lang_id) !== locale) {
                changeAppLocale({ newLocale: ((_c = payload === null || payload === void 0 ? void 0 : payload.me) === null || _c === void 0 ? void 0 : _c.lang_id) || "en", callsToSkip: [LocaleChangeOmitCall.episodes] });
            }
        })
            .catch(function (errorResponse) {
            var _a, _b, _c, _d;
            var errorToShow = Array.isArray((_a = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _a === void 0 ? void 0 : _a.errors)
                ? (_b = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _b === void 0 ? void 0 : _b.errors.join(", ")
                : typeof ((_c = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _c === void 0 ? void 0 : _c.message) === "string"
                    ? (_d = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _d === void 0 ? void 0 : _d.message
                    : "There was an error while performing the login";
            notification.error({ message: errorToShow });
        });
    };
    return (_jsxs(Form, __assign({ form: form, onFinish: handleFirstLogin, layout: "vertical" }, { children: [_jsx(Item, __assign({ "data-testid": "first-login-email-field", name: "email", label: contents.email, rules: [
                    {
                        type: "email",
                        required: true,
                        message: contents.error_email_invalid
                    }
                ] }, { children: _jsx(Input, { placeholder: contents.email, type: "email" }) })), _jsxs(Space, __assign({ direction: "horizontal" }, { children: [_jsx(Button, __assign({ "data-testid": "first-login-submit-button", className: "login-form__submit-button", htmlType: "submit", loading: loadingLogin === "pending" }, { children: contents.proceed_to_first_login || "Proceed" })), _jsx(Button, __assign({ htmlType: "button", onClick: function () { return setIsFirstLogin(false); } }, { children: contents.go_back || "Go back" }))] }))] })));
};
