import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { setNetworkStatus } from "@adm-media/adam-store-plugin";
import { OFFLINE, ONLINE } from "../types/network";
export var useSetNetworkStatus = function () {
    var dispatch = useAppDispatch();
    useEffect(function () {
        window.addEventListener("online", function () { return dispatch(setNetworkStatus(ONLINE)); });
        window.addEventListener("offline", function () { return dispatch(setNetworkStatus(OFFLINE)); });
    }, []);
};
