var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormPropsMsdApidForumCovid19 = function () {
    var _a = useRegistrationForm(), contents = _a.contents, allCountries = _a.allCountries, eventCountries = _a.eventCountries, hasInternal = _a.hasInternal, isInternalUser = _a.isInternalUser, languages = _a.languages, specialtyInputValue = _a.specialtyInputValue, getTranslatedOptions = _a.getTranslatedOptions, getRegistrationFormProps = _a.getRegistrationFormProps, departmentInputValue = _a.departmentInputValue;
    var _b = contents, error_email_invalid = _b.error_email_invalid, error_email_required = _b.error_email_required, error_firstname_required = _b.error_firstname_required, error_lastname_required = _b.error_lastname_required, error_language_required = _b.error_language_required, error_country_required = _b.error_country_required, email = _b.email, country_of_work = _b.country_of_work, phone = _b.phone, affiliation = _b.affiliation, language = _b.language, internal = _b.internal, internal_false_value_label = _b.internal_false_value_label, firstname = _b.firstname, lastname = _b.lastname, country = _b.country, specialty = _b.specialty, otherSpecialty = _b.otherSpecialty, other_department = _b.other_department, istitution = _b.istitution, department = _b.department, internal_true_value_label = _b.internal_true_value_label;
    var inputs = [
    // TODO: check with Robi
    //     {
    //         elementType: "hidden",
    //         id: "episodes",
    //         name: "episodes",
    //         value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
    // }
    ];
    if (hasInternal) {
        inputs.push({
            type: "select",
            label: internal,
            required: true,
            name: "is_internal",
            rules: [
                {
                    required: true,
                    message: ""
                }
            ],
            options: [
                {
                    value: 1,
                    label: internal_true_value_label
                },
                {
                    value: 0,
                    label: internal_false_value_label
                }
            ]
        });
    }
    if (!isInternalUser) {
        inputs = __spreadArray(__spreadArray([], inputs, true), [
            {
                label: country,
                name: "country_id",
                options: eventCountries,
                required: true,
                type: "select",
                rules: [
                    {
                        required: true,
                        message: error_country_required
                    }
                ]
            },
            {
                type: "text",
                label: istitution,
                required: true,
                name: "institution",
                rules: [
                    {
                        required: true,
                        message: "Please input your institution!"
                    }
                ]
            },
            {
                label: specialty,
                name: "specialty",
                options: getTranslatedOptions(),
                required: true,
                type: "select",
                rules: [
                    {
                        required: true,
                        message: "Please input your speciality!"
                    }
                ]
            }
        ], false);
        if (specialtyInputValue === "Other") {
            inputs.push({
                type: "text",
                label: otherSpecialty,
                required: true,
                name: "other_specialty",
                rules: [
                    {
                        required: true,
                        message: "Please input your other speciality!"
                    }
                ]
            });
        }
    }
    else {
        inputs = __spreadArray(__spreadArray([], inputs, true), [
            {
                label: country_of_work,
                name: "country_id_of_work",
                options: allCountries,
                required: true,
                type: "select",
                rules: [
                    {
                        required: true,
                        message: error_country_required
                    }
                ]
            },
            {
                type: "select",
                label: department,
                required: true,
                name: "department",
                rules: [
                    {
                        required: true,
                        message: "Please select your department!"
                    }
                ],
                options: [
                    {
                        value: "MRL/GMSA",
                        label: "MRL/GMSA"
                    },
                    {
                        value: "Other",
                        label: "Other"
                    }
                ]
            }
        ], false);
        if (departmentInputValue === "Other") {
            inputs.push({
                type: "text",
                label: other_department,
                required: true,
                name: "other_department",
                rules: [
                    {
                        required: true,
                        message: "Please input your other department!"
                    }
                ]
            });
        }
    }
    var languageInput = {
        type: "hidden",
        name: "lang_id",
        value: languages[0].value
    };
    if (languages.length > 1) {
        languageInput = {
            label: language,
            name: "lang_id",
            options: languages,
            required: true,
            type: "select"
        };
    }
    inputs.push(languageInput);
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            type: "text",
            label: firstname,
            required: true,
            name: "firstname",
            rules: [
                {
                    required: true,
                    message: error_firstname_required
                }
            ]
        },
        {
            label: lastname,
            name: "lastname",
            required: true,
            type: "text",
            rules: [
                {
                    required: true,
                    message: error_lastname_required
                }
            ]
        },
        {
            label: email,
            name: "email",
            required: true,
            type: "email",
            rules: [
                { type: "email", message: error_email_invalid },
                {
                    required: true,
                    message: error_email_required
                }
            ]
        }
    ], false);
    return getRegistrationFormProps({ inputs: inputs });
};
