var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { selectAppContent, selectUnsubscribeSubscriber, unsubscribeSubscriberThunk } from "@adm-media/adam-store-plugin";
import { useAppDispatch } from "../../../store";
import { useNavigate } from "react-router";
import { Paths } from "../../../pages";
import { Flex } from "antd";
import Button from "../../ui/Buttons/Button";
import Typography from "../../ui/Typography/Typography";
import Divider from "../../ui/Divider/Divider";
import { useSelector } from "react-redux";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import { useNotification } from "../../../hooks/useNotification";
export var ConfirmUnsubscriptionModal = function (_a) {
    var unsubscribeHash = _a.unsubscribeHash;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var contents = useSelector(selectAppContent);
    var notification = useNotification().notification;
    var loading = useSelector(selectUnsubscribeSubscriber).loading;
    var conConfirmUnsubscribe = function () {
        if (unsubscribeHash) {
            dispatch(unsubscribeSubscriberThunk({ unsubscribeHash: unsubscribeHash }))
                .unwrap()
                .then(function () {
                notification.success({ message: contents.unsubscription_success_message || "Success" });
            })
                .catch(function () {
                notification.error({ message: contents.generic_error_message || "There was an error in processing the request" });
            })
                .finally(function () {
                navigate(Paths.home, { replace: true });
            });
        }
        else {
            notification.error({ message: contents.generic_error_message || "There was an error in processing the request" });
            navigate(Paths.home, { replace: true });
        }
    };
    var onCancel = function () {
        navigate(Paths.home, { replace: true });
        window.location.reload();
    };
    return (_jsxs("div", __assign({ "data-testid": "confirm-unsubscription-modal" }, { children: [_jsx(Typography.Text, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.unsubscription_description || "Hi, are you sure that you want to unsubscribe from the event?", tag: "div" }) }), _jsx(Divider, {}), _jsxs(Flex, __assign({ justify: "end", gap: "small" }, { children: [_jsx(Button, __assign({ "data-testid": "confirm-unsubscription-modal-confirm-button", loading: loading === "pending", onClick: conConfirmUnsubscribe, type: "primary" }, { children: contents.unsubscription_confirm || "Confirm" })), _jsx(Button, __assign({ "data-testid": "confirm-unsubscription-modal-cancel-button", onClick: onCancel }, { children: contents.unsubscription_cancel || "Cancel" }))] }))] })));
};
export var getConfirmUnsubscriptionModalProps = function () {
    return {
        maskClosable: false,
        closeIcon: null
    };
};
