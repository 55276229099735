import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { adminReducer, appReducer, modalsReducer, playerReducer, qaReducer, episodesReducer, userReducer, drawerReducer, quizReducer } from "@adm-media/adam-store-plugin";
import { useDispatch } from "react-redux";
var rootReducer = combineReducers({
    admin: adminReducer,
    user: userReducer,
    episodes: episodesReducer,
    app: appReducer,
    modals: modalsReducer,
    player: playerReducer,
    qa: qaReducer,
    drawers: drawerReducer,
    quiz: quizReducer
});
var store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production"
});
export function setupStore(preloadedState) {
    return configureStore({
        reducer: rootReducer,
        preloadedState: preloadedState
    });
}
export default store;
export var useAppDispatch = function () { return useDispatch(); };
