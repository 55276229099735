import { selectCustomCSS } from "@adm-media/adam-store-plugin";
import { useEffect } from "react";
import { useSelector } from "react-redux";
export var useCustomCss = function () {
    var customCSS = useSelector(selectCustomCSS);
    useEffect(function () {
        if (!!customCSS && typeof customCSS === "string") {
            var style = document.createElement("style");
            style.textContent = customCSS;
            style.setAttribute("data-testid", "custom-style-tag");
            document.head.appendChild(style);
        }
    }, [customCSS]);
};
