var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { selectAppContent, selectDetectTimezoneAbbr, selectDsTimezoneAbbreviation, selectLocaleLanguage, selectStyles, selectTimezone } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { SanitizeHtml } from "../../../ui/SanitizeHtml/SanitizeHtml";
import { formatUtcDateInEventTimezone } from "../../../../utils/get-live-date";
import "./HeroEpisodeCard.scss";
export var HeroEpisodeCard = function (_a) {
    var _b, _c;
    var episode = _a.episode, className = _a.className;
    var contents = useSelector(selectAppContent);
    var styles = useSelector(selectStyles);
    var locale = useSelector(selectLocaleLanguage);
    var timezone = useSelector(selectTimezone);
    var timezoneAbbreviation = useSelector(selectDetectTimezoneAbbr);
    var dsTimezoneAbbreviation = useSelector(selectDsTimezoneAbbreviation);
    var formattedLiveDate = formatUtcDateInEventTimezone({
        date: (_b = episode === null || episode === void 0 ? void 0 : episode.live_scheduling) === null || _b === void 0 ? void 0 : _b.start_at,
        timezone: timezone,
        timeFormatType: contents.time_format,
        dateFormatType: contents.date_format,
        dsTimezoneAbbreviation: dsTimezoneAbbreviation,
        timezoneAbbreviation: timezoneAbbreviation,
        hideTime: styles === null || styles === void 0 ? void 0 : styles.hero_hide_live_time,
        locale: locale
    });
    var formattedVodDate = formatUtcDateInEventTimezone({
        date: (_c = episode === null || episode === void 0 ? void 0 : episode.vod_scheduling) === null || _c === void 0 ? void 0 : _c.start_at,
        timezone: timezone,
        timeFormatType: contents.time_format,
        dateFormatType: contents.date_format,
        dsTimezoneAbbreviation: dsTimezoneAbbreviation,
        timezoneAbbreviation: timezoneAbbreviation,
        hideTime: styles === null || styles === void 0 ? void 0 : styles.hero_hide_vod_time,
        locale: locale
    });
    var baseClass = "hero-episode-card";
    var elementClassName = className ? "".concat(baseClass, " ").concat(className) : baseClass;
    /** on click of the card, it scrolls to the bottom of the hero and clicks the selected agenda tab item */
    var handleEpisodeCardClick = function () {
        // Scroll to agenda block
        var agendaElement = document.getElementById("agenda");
        if (agendaElement) {
            agendaElement === null || agendaElement === void 0 ? void 0 : agendaElement.scrollIntoView({ behavior: "smooth" });
        }
        else {
            var arrowDown = document.querySelector(".hero-wrapper .hero-scroll-down-button");
            if (arrowDown)
                arrowDown === null || arrowDown === void 0 ? void 0 : arrowDown.scrollIntoView({ behavior: "smooth" });
        }
        // Activate agenda tab if present
        var tabPanel = document.querySelector(".block-agenda *[data-node-key=\"".concat(episode.id, "\"] .ant-tabs-tab-btn"));
        if (tabPanel)
            tabPanel === null || tabPanel === void 0 ? void 0 : tabPanel.click();
    };
    return (_jsx("div", __assign({ "data-testid": "hero-episode-card", className: elementClassName, onClick: handleEpisodeCardClick }, { children: _jsxs("div", __assign({ className: "".concat(baseClass, "__container") }, { children: [_jsx("div", __assign({ className: "".concat(baseClass, "__title") }, { children: episode.title })), formattedLiveDate && (_jsxs("div", __assign({ className: "".concat(baseClass, "__live-date") }, { children: [!!contents.live_datetime_title && (_jsxs(_Fragment, { children: [_jsx(SanitizeHtml, { stringToSanitize: contents.live_datetime_title, tag: styles.date_on_new_line ? "div" : "span" }), " "] })), formattedLiveDate] }))), formattedVodDate && (_jsxs("div", __assign({ className: "".concat(baseClass, "__vod-date") }, { children: [!!contents.vod_datetime_title && (_jsxs(_Fragment, { children: [_jsx(SanitizeHtml, { stringToSanitize: contents.vod_datetime_title, tag: styles.date_on_new_line ? "div" : "span" }), " "] })), formattedVodDate] })))] })) }), episode.id));
};
