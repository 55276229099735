import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { displayModal, logoutUserThunk, selectLoadingDetect, selectMe } from "@adm-media/adam-store-plugin";
import { ERROR_MODAL } from "../components/modals";
export var useHandleErrors = function () {
    var dispatch = useAppDispatch();
    var errorMe = useSelector(selectMe).error;
    var loadingDetect = useSelector(selectLoadingDetect);
    // Detect errors
    useEffect(function () {
        if (loadingDetect === "failed") {
            dispatch(displayModal({ name: ERROR_MODAL, props: { closable: false, maskClosable: false } }));
        }
    }, [loadingDetect]);
    // Me errors
    useEffect(function () {
        if ((errorMe === null || errorMe === void 0 ? void 0 : errorMe.status) === 463 || (errorMe === null || errorMe === void 0 ? void 0 : errorMe.status) === 403 || (errorMe === null || errorMe === void 0 ? void 0 : errorMe.status) === 401) {
            dispatch(logoutUserThunk())
                .unwrap()
                .catch(function () { })
                .finally(function () {
                window.location.reload();
            });
        }
    }, [errorMe]);
};
