var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormPropsMsdType3 = function () {
    var _a = useRegistrationForm(), contents = _a.contents, allCountries = _a.allCountries, eventCountries = _a.eventCountries, hasInternal = _a.hasInternal, isInternalUser = _a.isInternalUser, languages = _a.languages, specialtyInputValue = _a.specialtyInputValue, getTranslatedOptions = _a.getTranslatedOptions, getRegistrationFormProps = _a.getRegistrationFormProps, departmentInputValue = _a.departmentInputValue;
    var _b = contents, error_language_required = _b.error_language_required, error_lastname_required = _b.error_lastname_required, error_firstname_required = _b.error_firstname_required, error_institution_reuired = _b.error_institution_reuired, error_specialty_required = _b.error_specialty_required, error_email_required = _b.error_email_required, department = _b.department, country_of_work = _b.country_of_work, error_country_required = _b.error_country_required, istitution = _b.istitution, email = _b.email, language = _b.language, internal = _b.internal, firstname = _b.firstname, lastname = _b.lastname, country = _b.country, specialty = _b.specialty, otherSpecialty = _b.otherSpecialty, other_department = _b.other_department, internal_true_value_label = _b.internal_true_value_label, internal_false_value_label = _b.internal_false_value_label, error_department_required = _b.error_department_required, password = _b.password, password_confirmation = _b.password_confirmation, error_password_required = _b.error_password_required, error_psw_do_not_match = _b.error_psw_do_not_match;
    var inputs = [];
    var languageInput = languages.length > 1
        ? {
            label: language,
            name: "lang_id",
            options: languages,
            required: true,
            type: "select",
            rules: [{ required: true, message: error_language_required }]
        }
        : {
            type: "hidden",
            name: "lang_id",
            value: languages[0].value
        };
    inputs.push(languageInput);
    if (hasInternal) {
        inputs.push({
            type: "select",
            label: internal,
            required: true,
            name: "is_internal",
            rules: [
                {
                    required: true,
                    message: ""
                }
            ],
            options: [
                {
                    value: 1,
                    label: internal_true_value_label
                },
                {
                    value: 0,
                    label: internal_false_value_label
                }
            ]
        });
    }
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            type: "text",
            label: firstname,
            required: true,
            name: "firstname",
            rules: [
                {
                    required: true,
                    message: error_firstname_required
                }
            ]
        },
        {
            label: lastname,
            name: "lastname",
            required: true,
            type: "text",
            rules: [
                {
                    required: true,
                    message: error_lastname_required
                }
            ]
        }
    ], false);
    if (!isInternalUser) {
        inputs.push({
            label: country,
            name: "country_id",
            options: eventCountries,
            required: true,
            showSearch: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: error_country_required
                }
            ]
        }, {
            type: "text",
            label: istitution,
            required: true,
            name: "institution",
            rules: [
                {
                    required: true,
                    message: error_institution_reuired
                }
            ]
        }, {
            label: specialty,
            name: "specialty",
            // options: getTranslatedOptions(),
            options: [
                {
                    value: "Gynecology",
                    label: "Gynecology"
                },
                {
                    value: "Internal Medicine",
                    label: "Internal Medicine"
                },
                {
                    value: "Medical Oncology",
                    label: "Medical Oncology"
                },
                {
                    value: "Pathology",
                    label: "Pathology"
                },
                {
                    value: "Radiology",
                    label: "Radiology"
                },
                {
                    value: "Surgeon",
                    label: "Surgeon"
                },
                {
                    value: "Other",
                    label: "Others"
                }
            ],
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: error_specialty_required
                }
            ]
        });
        if (specialtyInputValue === "Other") {
            inputs.push({
                type: "text",
                // TODO: add label in admin area
                label: otherSpecialty || "Others",
                required: true,
                name: "other_specialty",
                rules: [
                    {
                        required: true,
                        message: error_specialty_required
                    }
                ]
            });
        }
    }
    else {
        inputs.push({
            label: country_of_work,
            name: "country_id_of_work",
            options: allCountries,
            required: true,
            showSearch: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: error_country_required
                }
            ]
        }, {
            type: "select",
            label: department,
            required: true,
            name: "department",
            rules: [
                {
                    required: true,
                    message: error_department_required
                }
            ],
            options: [
                {
                    value: "MRL/GMSA",
                    label: "MRL/GMSA"
                },
                {
                    value: "Other",
                    label: "Other"
                }
            ]
        });
        if (departmentInputValue === "Other") {
            inputs.push({
                type: "text",
                // TODO: add label in admin area
                // label: other_department,
                label: "Other department",
                required: true,
                name: "other_department",
                rules: [
                    {
                        required: true,
                        message: error_department_required
                    }
                ]
            });
        }
    }
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            label: email,
            name: "email",
            required: true,
            type: "email",
            rules: [
                {
                    required: true,
                    message: error_email_required
                }
            ]
        },
        {
            type: "password",
            label: password || "Password",
            required: true,
            name: "password",
            rules: [
                {
                    required: true,
                    message: error_password_required
                }
            ]
        },
        {
            type: "password",
            label: password_confirmation,
            required: true,
            name: "password_confirmation",
            rules: [
                {
                    required: true,
                    message: error_psw_do_not_match
                }
            ]
        },
        {
            type: "hidden",
            name: "change_password_required",
            value: false
        }
    ], false);
    return getRegistrationFormProps({ inputs: inputs });
};
