var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormPropsEdizioniDm = function () {
    var _a = useRegistrationForm(), contents = _a.contents, countries = _a.countries, languages = _a.languages, getRegistrationFormProps = _a.getRegistrationFormProps;
    var _b = contents, error_country_required = _b.error_country_required, error_firstname_required = _b.error_firstname_required;
    var inputs = [
    // TODO: check with Robi
    //     {
    //         elementType: "hidden",
    //         id: "episodes",
    //         name: "episodes",
    //         value: window._.map(props.openEpisodes, (item => item.id))//[window._.map(props.openEpisodes, (item => item.id))],
    // }
    ];
    var languageInput = {
        type: "hidden",
        name: "lang_id",
        value: languages[0].value
    };
    if (languages.length > 1) {
        languageInput = {
            type: "select",
            label: contents.language,
            required: true,
            name: "lang_id",
            rules: [
                {
                    required: true,
                    message: contents.error_language_required
                }
            ],
            options: languages
        };
    }
    inputs.push(languageInput);
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            type: "select",
            name: "country_id",
            label: contents.country,
            options: countries || [],
            rules: [
                {
                    required: true,
                    message: error_country_required
                }
            ]
        },
        {
            type: "text",
            name: "firstname",
            label: contents.firstname,
            rules: [
                {
                    required: true,
                    message: error_firstname_required
                }
            ]
        },
        {
            type: "text",
            name: "lastname",
            required: true,
            label: contents.lastname,
            rules: [
                {
                    required: true,
                    message: contents.error_lastname_required
                }
            ]
        },
        {
            type: "email",
            name: "email",
            required: true,
            label: contents.email,
            rules: [
                {
                    required: true,
                    message: contents.error_email_required
                }
            ]
        },
        {
            type: "text",
            id: "phone",
            name: "phone",
            label: contents.phone
        }
    ], false);
    return getRegistrationFormProps({ inputs: inputs });
};
