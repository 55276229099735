import { selectAppContent } from "@adm-media/adam-store-plugin";
import { message } from "antd";
import { App } from "antd";
import { useSelector } from "react-redux";
export var useNotification = function () {
    var _a = App.useApp(), message = _a.message, antdNotification = _a.notification;
    var contents = useSelector(selectAppContent);
    var notification = {
        notificationEntity: antdNotification,
        showGenericError: function () { return message.error(contents.generic_error_message || "An error has occcurred, please try again later"); },
        showGenericSuccess: function () { return message.success(contents.generic_success_message || "The operation has been performed successfully"); },
        info: antdNotification.info,
        success: antdNotification.success,
        error: antdNotification.error
    };
    return { notification: notification };
};
export { message };
