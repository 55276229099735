var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Collapse, List } from "antd";
import { SanitizeHtml } from "../SanitizeHtml/SanitizeHtml";
import { selectLocaleLanguage, selectPublicConfig } from "@adm-media/adam-store-plugin";
import Typography from "../../ui/Typography/Typography";
import "./AgendaTableItem.scss";
import { AgendaTableItemSpeakers } from "./AgendaTableItemSpeakers/AgendaTableItemSpeakers";
import { useSelector } from "react-redux";
var Title = Typography.Title;
export var AgendaTableItem = function (_a) {
    var _b, _c, _d;
    var agendaItem = _a.agendaItem, hiddenSpeakersId = _a.hiddenSpeakersId, hideAgendaStartTime = _a.hideAgendaStartTime;
    var baseClass = "agenda-table-item";
    var publicConfigs = useSelector(selectPublicConfig);
    var locale = useSelector(selectLocaleLanguage);
    var agendaHasChildren = Array.isArray(agendaItem.children) && agendaItem.children.length > 0;
    var agendaDurationLabel = hideAgendaStartTime ? agendaItem.label : "(".concat(agendaItem.label, ")");
    var speakersToShow = hiddenSpeakersId
        ? (_b = agendaItem === null || agendaItem === void 0 ? void 0 : agendaItem.speakers) === null || _b === void 0 ? void 0 : _b.filter(function (speaker) { return !(hiddenSpeakersId === null || hiddenSpeakersId === void 0 ? void 0 : hiddenSpeakersId.includes(speaker.id)); })
        : agendaItem === null || agendaItem === void 0 ? void 0 : agendaItem.speakers;
    var speakersGroupsConfigs = locale
        ? ((_d = (_c = publicConfigs === null || publicConfigs === void 0 ? void 0 : publicConfigs.agendaSpeakersGroups) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.agendaId === agendaItem.id; })) === null || _d === void 0 ? void 0 : _d[locale]) || null
        : null;
    return (_jsx(Collapse, { activeKey: !agendaHasChildren ? [] : agendaItem.id, className: baseClass, expandIcon: function () { return null; }, items: [
            {
                key: agendaItem.id,
                label: (_jsxs("div", __assign({ className: "".concat(baseClass, "__header"), "data-testid": "agenda-table-item" }, { children: [_jsxs("div", __assign({ className: "".concat(baseClass, "__texts") }, { children: [_jsx(Title, __assign({ level: 5, className: "".concat(baseClass, "__title") }, { children: _jsx(SanitizeHtml, { stringToSanitize: agendaItem.title, tag: "div" }) })), agendaItem.description && (_jsx(SanitizeHtml, { className: "".concat(baseClass, "__description"), stringToSanitize: agendaItem.description, tag: "div" }))] })), _jsxs("div", __assign({ className: "".concat(baseClass, "__time-info") }, { children: [!!!hideAgendaStartTime && _jsxs("span", __assign({ className: "".concat(baseClass, "__time") }, { children: [agendaItem.start_at, " "] })), agendaItem.label && _jsx("span", __assign({ className: "".concat(baseClass, "__timing") }, { children: agendaDurationLabel }))] })), !agendaHasChildren ? (_jsx("div", __assign({ className: "".concat(baseClass, "__speakers-container") }, { children: _jsx(AgendaTableItemSpeakers, { baseClass: baseClass, speakersToShow: speakersToShow, speakersGroupsConfigs: speakersGroupsConfigs }) }))) : (_jsx("div", { className: "".concat(baseClass, "__details-button-container") }))] }))),
                children: agendaHasChildren ? (_jsx(List, { dataSource: agendaItem.children, renderItem: function (childrenItem) {
                        var _a, _b, _c;
                        var speakersToShowChildren = hiddenSpeakersId
                            ? (_a = childrenItem === null || childrenItem === void 0 ? void 0 : childrenItem.speakers) === null || _a === void 0 ? void 0 : _a.filter(function (speaker) { return !(hiddenSpeakersId === null || hiddenSpeakersId === void 0 ? void 0 : hiddenSpeakersId.includes(speaker.id)); })
                            : childrenItem === null || childrenItem === void 0 ? void 0 : childrenItem.speakers;
                        var childAgendaDurationLabel = hideAgendaStartTime ? childrenItem.label : "(".concat(childrenItem.label, ")");
                        var speakersGroupsConfigs = locale
                            ? ((_c = (_b = publicConfigs === null || publicConfigs === void 0 ? void 0 : publicConfigs.agendaSpeakersGroups) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.agendaId === childrenItem.id; })) === null || _c === void 0 ? void 0 : _c[locale]) || null
                            : null;
                        return (_jsxs("div", __assign({ className: "".concat(baseClass, "__nested-item"), "data-testid": "agenda-table-nested-item" }, { children: [_jsxs("div", __assign({ className: "".concat(baseClass, "__nested-texts") }, { children: [_jsx(Title, __assign({ level: 5, className: "".concat(baseClass, "__nested-title") }, { children: _jsx(SanitizeHtml, { stringToSanitize: childrenItem.title, tag: "div" }) })), childrenItem.description && (_jsx(SanitizeHtml, { className: "".concat(baseClass, "__nested-description"), stringToSanitize: childrenItem.description, tag: "div" }))] })), _jsxs("div", __assign({ className: "".concat(baseClass, "__nested-time-info") }, { children: [!!!hideAgendaStartTime && _jsxs("span", __assign({ className: "".concat(baseClass, "__nested-time") }, { children: [childrenItem.start_at, " "] })), childrenItem.label && _jsx("span", __assign({ className: "".concat(baseClass, "__nested-timing") }, { children: childAgendaDurationLabel }))] })), Array.isArray(speakersToShowChildren) && speakersToShowChildren.length > 0 && (_jsx("div", __assign({ className: "".concat(baseClass, "__nested-speakers-container") }, { children: _jsx(AgendaTableItemSpeakers, { baseClass: baseClass, speakersToShow: speakersToShowChildren, speakersGroupsConfigs: speakersGroupsConfigs }) })))] })));
                    } })) : null
            }
        ] }));
};
