var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import availableModals from "./index";
import { hideModal, removeModal, selectModals } from "@adm-media/adam-store-plugin";
import { useAppDispatch } from "../../store";
import Modal from "../ui/Modal/Modal";
var ModalsConnect = function () {
    var dispatch = useAppDispatch();
    var modals = useSelector(selectModals);
    return (_jsx(_Fragment, { children: modals.map(function (modal) {
            var Element = availableModals[modal.name].content;
            var props = __assign(__assign({}, availableModals[modal.name].getProps()), modal.props);
            var onCancelFromProps = props === null || props === void 0 ? void 0 : props.onCancel;
            return (_jsx(Modal, __assign({ open: modal.isOpen, onCancel: function (e) {
                    typeof onCancelFromProps === "function" && onCancelFromProps(e);
                    dispatch(hideModal({ name: modal.name }));
                }, afterClose: function () { return dispatch(removeModal({ name: modal.name })); }, footer: (props === null || props === void 0 ? void 0 : props.footer) || null }, props, { children: _jsx(Element, __assign({}, props)) }), modal.name));
        }) }));
};
export default ModalsConnect;
