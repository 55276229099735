var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Container from "../../ui/Container/Container";
import Row from "../../ui/Row/Row";
import { useSelector } from "react-redux";
import { selectAppContent, selectClientName, selectTimezone, selectUser, selectVirtual } from "@adm-media/adam-store-plugin";
import Statistic from "../../ui/Statistic/Statistic";
import { isEmpty } from "../../../utils/obj-check";
import useQueryLocation from "../../../hooks/useQueryLocation";
import { localTime } from "../../../utils/date";
import { moment } from "../../../utils/moment";
import "./VirtualWorldModal.scss";
var Countdown = Statistic.Countdown;
export var VirtualWorldModal = function () {
    var now = new Date();
    var query = useQueryLocation().query;
    var contents = useSelector(selectAppContent);
    var identity = useSelector(selectUser).identity;
    var timezone = useSelector(selectTimezone);
    var virtualWorld = useSelector(selectVirtual);
    var clientName = useSelector(selectClientName);
    var footerContent = clientName ? "Copyright \u00A9 ".concat(now.getFullYear(), " ").concat(clientName) : null;
    var goToVw = function () {
        if (virtualWorld.url && !isEmpty(identity)) {
            var vwUrl = virtualWorld.url + "?iam=".concat(identity.email);
            if (Boolean(query.get("preview"))) {
                vwUrl += "&preview=".concat(query.get("preview"));
            }
            window.location.assign(vwUrl);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Container, __assign({ fluid: true, className: "text-center virtual-world-modal" }, { children: [_jsx(Row, __assign({ className: "mt-3" }, { children: _jsx("div", __assign({ className: "col virtual-world-modal__event-title" }, { children: contents.event_title })) })), _jsx(Row, __assign({ className: "my-3" }, { children: _jsx("div", { className: "col", dangerouslySetInnerHTML: {
                                __html: "".concat("Virtual World will open its doors on ".concat(localTime(virtualWorld.startDate, timezone || "utc").format("D-M-Y HH:mm")))
                            } }) })), _jsx(Row, __assign({ className: "my-3" }, { children: _jsx("div", __assign({ className: "col virtual-world-modal__countdown" }, { children: _jsx(Countdown, { value: moment.utc(virtualWorld.startDate).format(), onFinish: function () { return goToVw(); }, format: "D[d] H[h] m[m] s[s]" }) })) }))] })), footerContent] }));
};
export var getVirtualWorldModalProps = function () {
    return {
        destroyModal: false,
        destroyOnClose: false
    };
};
