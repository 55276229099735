var PusherConfig = /** @class */ (function () {
    function PusherConfig() {
        this.channel = undefined;
    }
    PusherConfig.prototype.setChannel = function (channel) {
        this.channel = channel;
    };
    PusherConfig.prototype.getChannel = function () {
        return this.channel;
    };
    return PusherConfig;
}());
var PusherconfigInstance = new PusherConfig();
export { PusherconfigInstance };
