import { useEffect, useState } from "react";
import { convertSourceId } from "../utils/convertSourceId";
export var useWatermarkContent = function (_a) {
    var watermarkString = _a.watermarkString, playerSource = _a.playerSource, playerReadyState = _a.playerReadyState, playerRef = _a.playerRef;
    var _b = useState(false), hasWatermarkAtSomePoint = _b[0], setHasWatermarkAtSomePoint = _b[1];
    var _c = useState(undefined), watermarkContent = _c[0], setWatermarkContent = _c[1];
    var _d = useState(0), currentPlayerTime = _d[0], setCurrentPlayerTime = _d[1];
    var _e = useState(undefined), channelStatus = _e[0], setChannelStatus = _e[1];
    useEffect(function () {
        if (playerSource.status && channelStatus === undefined)
            setChannelStatus(playerSource.status);
        else if (playerSource.status !== channelStatus && playerReadyState === 4 && (playerRef === null || playerRef === void 0 ? void 0 : playerRef.readyState) === 4) {
            setCurrentPlayerTime(0);
            setChannelStatus(playerSource.status || null);
        }
    }, [playerReadyState, channelStatus, playerSource.channel, playerRef]);
    useEffect(function () {
        if (playerSource.source_id !== null && playerSource.source_id !== undefined)
            setHasWatermarkAtSomePoint(true);
        else
            setHasWatermarkAtSomePoint(false);
    }, [playerSource]);
    useEffect(function () {
        try {
            /**
             * source_id is a purpusefully misnamed attribute, it contains the information on
             * when to activate the watermark on the player.
             * type after removing the third char and subsequent decoding: number[][]
             */
            var watermarkTimelineEncoded = playerSource === null || playerSource === void 0 ? void 0 : playerSource.source_id;
            var isInWatermarkSection_1 = false;
            if (typeof watermarkTimelineEncoded === "string" && channelStatus) {
                var watermarkTimelineForCurrentStatus = convertSourceId(watermarkTimelineEncoded, channelStatus);
                if (Array.isArray(watermarkTimelineForCurrentStatus)) {
                    watermarkTimelineForCurrentStatus === null || watermarkTimelineForCurrentStatus === void 0 ? void 0 : watermarkTimelineForCurrentStatus.forEach(function (timelinePiece) {
                        if (Array.isArray(timelinePiece) &&
                            timelinePiece.length === 2 &&
                            typeof timelinePiece[0] === "number" &&
                            typeof timelinePiece[1] === "number") {
                            if (currentPlayerTime > timelinePiece[0] && currentPlayerTime <= timelinePiece[1]) {
                                isInWatermarkSection_1 = true;
                            }
                        }
                    });
                }
            }
            if (isInWatermarkSection_1 && watermarkContent === undefined) {
                setWatermarkContent(watermarkString);
            }
            else if (!isInWatermarkSection_1 && watermarkContent !== undefined) {
                setWatermarkContent(undefined);
            }
        }
        catch (e) {
            setWatermarkContent(undefined);
        }
    }, [currentPlayerTime, channelStatus, watermarkContent, playerSource.source_id, playerSource.status]);
    return { setCurrentPlayerTime: setCurrentPlayerTime, watermarkContent: watermarkContent, hasWatermarkAtSomePoint: hasWatermarkAtSomePoint };
};
