var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { List, ListItem, ListItemMeta } from "../../ui/List/List";
import { PdfIcon, ImageIcon, FileIcon } from "../../ui/Icons/Icons";
import Button from "../../ui/Buttons/Button";
import { useSelector } from "react-redux";
import { selectAppContent, selectCurrentEpisode, selectEventId, selectSelectedEpisodeId, selectUser } from "@adm-media/adam-store-plugin";
import "./DownloadList.scss";
import { useState } from "react";
// import { useAppDispatch } from "../../../store";
import { saveAs } from "file-saver";
import { useNotification } from "../../../hooks/useNotification";
import { Popconfirm } from "antd";
import { detectMobile } from "../../../utils/detect-mobile";
export var DownloadsList = function (_a) {
    var isCurrentDrawerOpen = _a.isCurrentDrawerOpen;
    // const dispatch = useAppDispatch();
    var contents = useSelector(selectAppContent);
    var downloadable = useSelector(selectCurrentEpisode).downloadable;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(null), pdfFileUrl = _c[0], setPdfFileUrl = _c[1];
    var notification = useNotification().notification;
    var isMobile = detectMobile();
    var mapMimeToIcon = function (mime) {
        if (mime === "application/pdf")
            return _jsx(PdfIcon, { className: "download-list__icon" });
        else if (mime.startsWith("image"))
            return _jsx(ImageIcon, { className: "download-list__icon" });
        else
            return _jsx(FileIcon, { className: "download-list__icon" });
    };
    // TOOD: improve handling of download
    // const downloadDownloadable = async (id: number, title: string) => {
    //     setIsLoading(true);
    //     dispatch(downloadDownloadableThunk({ downloadableId: id }))
    //         .unwrap()
    //         .then((payload) => {
    //             const parsedBlob = new Blob([payload.blob], {
    //                 type: payload?.contentType
    //             });
    //             saveAs(parsedBlob, title.replaceAll(" ", "-") || "download");
    //         })
    //         .catch(() => {
    //             notification.showGenericError();
    //         })
    //         .finally(() => setIsLoading(false));
    // };
    var selectedEpisodeId = useSelector(selectSelectedEpisodeId);
    var eventId = useSelector(selectEventId);
    var token = useSelector(selectUser).token;
    var downloadDownloadable = function (id, title, mime) { return __awaiter(void 0, void 0, void 0, function () {
        var response, blob, parsedBlob, fileURL, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    setIsLoading(id);
                    return [4 /*yield*/, fetch("".concat(process.env.BASE_URL, "/v2/event/").concat(eventId, "/episodes/").concat(selectedEpisodeId, "/downloadable/").concat(id, "/download"), {
                            method: "GET",
                            credentials: "same-origin",
                            headers: {
                                Authorization: "Bearer ".concat(token),
                                "Content-Type": "application/json, */*",
                                Accept: "application/json, */*"
                            }
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.blob()];
                case 2:
                    blob = _a.sent();
                    parsedBlob = new Blob([blob], {
                        type: blob.type
                    });
                    if (isMobile && (mime === "application/pdf" || (mime === null || mime === void 0 ? void 0 : mime.startsWith("image")))) {
                        fileURL = URL.createObjectURL(parsedBlob);
                        setPdfFileUrl(fileURL);
                    }
                    else {
                        saveAs(parsedBlob, title && typeof title === "string" ? title.replaceAll(" ", "-") : "download");
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    notification.showGenericError();
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(List, { dataSource: downloadable === null || downloadable === void 0 ? void 0 : downloadable.filter(function (downloadableItem) { return downloadableItem.media !== null && downloadableItem.media !== undefined; }), bordered: true, className: "download-list", renderItem: function (downloadItem) {
            var _a, _b, _c, _d;
            return (_jsx(ListItem, __assign({ "data-testid": "individual-download-item", className: "download-list__item", actions: isMobile &&
                    (((_a = downloadItem === null || downloadItem === void 0 ? void 0 : downloadItem.media) === null || _a === void 0 ? void 0 : _a.mime) === "application/pdf" || ((_c = (_b = downloadItem === null || downloadItem === void 0 ? void 0 : downloadItem.media) === null || _b === void 0 ? void 0 : _b.mime) === null || _c === void 0 ? void 0 : _c.startsWith("image"))) &&
                    isCurrentDrawerOpen
                    ? [
                        _jsx(Popconfirm, __assign({ icon: null, title: isLoading === downloadItem.id ? "Downloading..." : "File successfully downloaded", description: pdfFileUrl && (_jsx("div", __assign({ className: "download-list__save-file-container" }, { children: _jsx("a", __assign({ className: "download-list__save-file-button", href: pdfFileUrl, target: "_blank" }, { children: "Save file" })) }))), okButtonProps: { style: { display: "none" } }, cancelText: "Close" }, { children: _jsx(Button, __assign({ "data-testid": "download-button", onClick: function () {
                                    var _a;
                                    setPdfFileUrl(null);
                                    downloadDownloadable(downloadItem.id, downloadItem.title, (_a = downloadItem.media) === null || _a === void 0 ? void 0 : _a.mime);
                                } }, { children: contents.download_button_text || "Download" })) }))
                    ]
                    : [
                        _jsx(Button, __assign({ "data-testid": "download-button", loading: isLoading === downloadItem.id, onClick: function () { var _a; return downloadDownloadable(downloadItem.id, downloadItem.title, (_a = downloadItem.media) === null || _a === void 0 ? void 0 : _a.mime); } }, { children: contents.download_button_text || "Download" }))
                    ] }, { children: _jsx(ListItemMeta, { className: "download-list__item-meta", avatar: mapMimeToIcon(((_d = downloadItem === null || downloadItem === void 0 ? void 0 : downloadItem.media) === null || _d === void 0 ? void 0 : _d.mime) || ""), title: downloadItem.title }) })));
        } }));
};
