var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormPropsMsdId = function () {
    var _a = useRegistrationForm(), contents = _a.contents, allCountries = _a.allCountries, eventCountries = _a.eventCountries, hasInternal = _a.hasInternal, isInternalUser = _a.isInternalUser, languages = _a.languages, getTranslatedOptions = _a.getTranslatedOptions, getRegistrationFormProps = _a.getRegistrationFormProps;
    var _b = contents, error_language_required = _b.error_language_required, email = _b.email, country_of_work = _b.country_of_work, phone = _b.phone, affiliation = _b.affiliation, language = _b.language, internal = _b.internal, internal_false_value_label = _b.internal_false_value_label, firstname = _b.firstname, lastname = _b.lastname, country = _b.country, specialty = _b.specialty, internal_true_value_label = _b.internal_true_value_label;
    var inputs = [];
    var languageInput = {
        type: "hidden",
        name: "lang_id",
        value: languages[0].value
    };
    if (languages.length > 1) {
        languageInput = {
            label: language,
            name: "lang_id",
            options: languages,
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: error_language_required
                }
            ]
        };
    }
    inputs.push(languageInput);
    if (hasInternal) {
        inputs.push({
            type: "select",
            label: internal,
            required: true,
            name: "is_internal",
            rules: [
                {
                    required: true,
                    message: ""
                }
            ],
            options: [
                {
                    value: 1,
                    label: internal_true_value_label
                },
                {
                    value: 0,
                    label: internal_false_value_label
                }
            ]
        });
    }
    var countriesInput = {
        label: country_of_work,
        name: "country_id_of_work",
        options: allCountries,
        required: true,
        type: "select",
        rules: [
            {
                required: true,
                message: "Please select your country!"
            }
        ]
    };
    if (!isInternalUser) {
        countriesInput = {
            label: country,
            name: "country_id",
            options: eventCountries,
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: "Please select your country!"
                }
            ]
        };
    }
    inputs.push(countriesInput);
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            type: "text",
            label: firstname,
            required: true,
            name: "firstname",
            rules: [
                {
                    required: true,
                    message: "Please input your firstname!"
                }
            ]
        },
        {
            label: lastname,
            name: "lastname",
            required: true,
            type: "text",
            rules: [
                {
                    required: true,
                    message: "Please input your lastname!"
                }
            ]
        },
        {
            label: specialty,
            name: "specialty",
            options: getTranslatedOptions(),
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: "Please select your speciality!"
                }
            ]
        },
        {
            label: affiliation,
            name: "affiliation",
            required: true,
            type: "text",
            rules: [
                {
                    required: true,
                    message: "Please input your affiliation!"
                }
            ]
        },
        {
            label: email,
            name: "email",
            required: true,
            type: "email",
            rules: [
                { type: "email", message: "Please insert a valid email" },
                {
                    required: true,
                    message: "Please input your email!"
                }
            ]
        },
        {
            label: phone,
            name: "phone",
            type: "text"
        }
    ], false);
    return getRegistrationFormProps({ inputs: inputs });
};
