import { selectFetchPlayerSource, selectFormattedPlayerSource, selectIsSourceReady, selectLocaleLanguage, selectPlayerStatus, setChaptersTabEntries, setCurrentChapterCueId } from "@adm-media/adam-store-plugin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import { VOD } from "../types/player";
export var useSetDrawerChapters = function (_a) {
    var playerRef = _a.playerRef, playerReadyState = _a.playerReadyState;
    var dispatch = useAppDispatch();
    var playerStatus = useSelector(selectPlayerStatus);
    var locale = useSelector(selectLocaleLanguage);
    var isSourceReady = useSelector(selectIsSourceReady);
    var formattedPlayerSource = useSelector(selectFormattedPlayerSource);
    var loadingFetchSource = useSelector(selectFetchPlayerSource).loading;
    var _b = useState(null), updateChaptersStatus = _b[0], setUpdateChaptersStatus = _b[1];
    var _c = useState(false), isTrackReady = _c[0], setIsTrackReady = _c[1];
    var handleChapterTrackReady = function () {
        var _a;
        try {
            var activeAndReadyTrack = (_a = playerRef === null || playerRef === void 0 ? void 0 : playerRef.textTracks) === null || _a === void 0 ? void 0 : _a.find(function (track) {
                return track.kind === "chapters" && track.mode === "showing";
            });
            if (activeAndReadyTrack) {
                var cues = activeAndReadyTrack === null || activeAndReadyTrack === void 0 ? void 0 : activeAndReadyTrack.cues;
                var formattedCues = cues === null || cues === void 0 ? void 0 : cues.map(function (cue) {
                    if (typeof cue.startTime === "number" && typeof cue.endTime === "number") {
                        return {
                            startTime: cue.startTime,
                            endTime: cue.endTime,
                            id: cue.id,
                            content: cue.content,
                            text: ""
                        };
                    }
                    else
                        return null;
                });
                var filteredCues = formattedCues === null || formattedCues === void 0 ? void 0 : formattedCues.filter(function (formattedCue) { return formattedCue !== null; });
                if (filteredCues && (filteredCues === null || filteredCues === void 0 ? void 0 : filteredCues.length))
                    dispatch(setChaptersTabEntries(filteredCues));
            }
        }
        catch (_b) {
            console.warn("Unable to set chapters");
        }
    };
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var areSourcesEqual = ((_b = (_a = formattedPlayerSource === null || formattedPlayerSource === void 0 ? void 0 : formattedPlayerSource.sources) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.src) !== undefined &&
            ((_e = (_d = (_c = playerRef === null || playerRef === void 0 ? void 0 : playerRef.source) === null || _c === void 0 ? void 0 : _c.sources) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.src) === ((_g = (_f = formattedPlayerSource === null || formattedPlayerSource === void 0 ? void 0 : formattedPlayerSource.sources) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.src);
        if (isSourceReady &&
            areSourcesEqual &&
            loadingFetchSource === "succeeded" &&
            playerReadyState == 4 &&
            (playerRef === null || playerRef === void 0 ? void 0 : playerRef.readyState) === 4 &&
            updateChaptersStatus !== playerStatus &&
            playerStatus === VOD) {
            try {
                var chaptersTracks = (_h = playerRef === null || playerRef === void 0 ? void 0 : playerRef.textTracks) === null || _h === void 0 ? void 0 : _h.filter(function (track) { return track.kind === "chapters"; });
                var chapterTrackToShow = chaptersTracks === null || chaptersTracks === void 0 ? void 0 : chaptersTracks[0];
                if (chapterTrackToShow) {
                    // this is to fire the entercue event again when all data are loaded
                    chapterTrackToShow.mode = "disabled";
                    chapterTrackToShow.mode = "showing";
                    // https://developer.mozilla.org/en-US/docs/Web/API/TextTrackCue/enter_event
                    chapterTrackToShow.addEventListener("entercue", function (event) {
                        var _a;
                        if (typeof ((_a = event === null || event === void 0 ? void 0 : event.cue) === null || _a === void 0 ? void 0 : _a.id) === "string") {
                            setIsTrackReady(true);
                            dispatch(setCurrentChapterCueId(event === null || event === void 0 ? void 0 : event.cue.id));
                        }
                    });
                }
                setUpdateChaptersStatus(playerStatus || null);
            }
            catch (_j) {
                console.warn("Unable to set chapters markers");
            }
        }
    }, [playerReadyState, playerStatus, playerRef, updateChaptersStatus, isSourceReady, loadingFetchSource]);
    useEffect(function () {
        if (isTrackReady)
            handleChapterTrackReady();
    }, [isTrackReady]);
    useEffect(function () {
        setUpdateChaptersStatus(null);
        setIsTrackReady(false);
    }, [locale]);
    useEffect(function () {
        if (playerStatus !== VOD)
            dispatch(setChaptersTabEntries([]));
    }, [playerStatus]);
};
