import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { HomePage } from "./Home";
import { useAppDispatch } from "../store";
import { displayModal } from "@adm-media/adam-store-plugin";
import { RESET_PASSWORD } from "../components/modals";
import { Paths } from ".";
export var ResetPasswordPage = function () {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var emailUrl = searchParams.get("email");
    var tokenUrl = decodeURIComponent(searchParams.get("token") || "");
    useEffect(function () {
        if (Boolean(tokenUrl) && Boolean(emailUrl)) {
            dispatch(displayModal({
                name: RESET_PASSWORD,
                props: {
                    emailUrl: emailUrl,
                    tokenUrl: tokenUrl
                }
            }));
        }
        else
            navigate(Paths.home);
    }, []);
    return _jsx(HomePage, {});
};
