import { pusherInitUnauthenticated } from "./base-unauthenticated";
import { PusherMock } from "pusher-js-mock";
export var CHANGE_PUBLIC_QA_ENABLED_STATUS_PUSHER_EVENT = "App\\Events\\PublicChangeEpisodeQaaStatus";
export var subscribeToPublicQAPusherChannel = function (_a) {
    var appId = _a.config.appId, qaStatusChangeCallback = _a.qaStatusChangeCallback;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        var pusherInstance = new PusherMock();
        fetch(process.env.PUSHER_BASE_URL + "broadcasting/auth/pusher-unauthenticated", { method: "POST" });
        var channel = pusherInstance.subscribe("".concat(appId, ".none"));
        channel.bind(CHANGE_PUBLIC_QA_ENABLED_STATUS_PUSHER_EVENT, function (status) {
            console.log("STATUS: ", status);
            qaStatusChangeCallback(status);
        });
        if (window.Cypress) {
            window.Cypress.pusherUnauthenticated = pusherInstance;
        }
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        var channel = pusherInitUnauthenticated().subscribe("".concat(appId, ".none"));
        channel.bind(CHANGE_PUBLIC_QA_ENABLED_STATUS_PUSHER_EVENT, function (status) {
            qaStatusChangeCallback(status);
        });
        return channel;
    }
};
