import { jsx as _jsx } from "react/jsx-runtime";
import { displayModal, hideModal } from "@adm-media/adam-store-plugin";
import { CHANGE_PASSWORD_MODAL, LOGIN_MODAL } from "..";
import { useAppDispatch } from "../../../store";
import { ChangePassword } from "../../functional/ChangePassword/ChangePassword";
import { useNavigate } from "react-router-dom";
import "./ChangePasswordModal.scss";
export var ChangePasswordModal = function () {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var onSuccessCallback = function () {
        navigate("/");
        dispatch(hideModal({ name: CHANGE_PASSWORD_MODAL }));
        dispatch(displayModal({ name: LOGIN_MODAL }));
    };
    return _jsx(ChangePassword, { onSuccessCallback: onSuccessCallback });
};
export var getChangePasswordModalProps = function () {
    return {
        modalClassName: "change-password-modal",
        maskClosable: false,
        closable: false,
        footer: null
    };
};
