export var subscribeToPusherIdChannel = function (_a) {
    var pusherSingleton = _a.pusherSingleton, _b = _a.config, appId = _b.appId, channelId = _b.channelId;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        if (pusherSingleton) {
            fetch(process.env.PUSHER_BASE_URL + "/broadcasting/auth/id-channel", { method: "POST" });
            var channel = pusherSingleton.subscribe("presence-".concat(appId, ".123456789"));
            channel.unsubscribe = function () { };
            return channel;
        }
        else
            return null;
    }
    else {
        if (pusherSingleton) {
            try {
                var channel = pusherSingleton.subscribe("presence-".concat(appId, ".").concat(channelId));
                return channel;
            }
            catch (e) {
                console.warn("Error while trying to connect to pusher channel id: ", e);
                return null;
            }
        }
        else {
            return null;
        }
    }
};
