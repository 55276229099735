var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { PlusOutlined, MinusOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import Row from "../../ui/Row/Row";
import Col from "../../ui/Col/Col";
import Collapse from "../../ui/Collapse/Collapse";
import { Speakers } from "../Speakers/Speakers";
import Select from "../../ui/Select/Select";
import { useSelector } from "react-redux";
import { selectAppContent, selectDetectTimezoneAbbr, selectDsTimezoneAbbreviation, selectLocaleLanguage, selectMedia, selectStyles, selectTimezone, selectVodDurationMetrics } from "@adm-media/adam-store-plugin";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import { Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { Agenda } from "../Agenda/Agenda";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import Divider from "../../ui/Divider/Divider";
import { rowGutter } from "../configs";
import { formatUtcDateInEventTimezone } from "../../../utils/get-live-date";
import "./Episodes.scss";
import { useAnimationClassName } from "../../../hooks/useAnimationClassName";
import { Logo } from "../../ui/Logo/Logo";
import { useEpisodesToShowInHomepage } from "../../../hooks/useEpisodesToShowInHomepage";
var Title = Typography.Title;
var EpisodeDetails = function (_a) {
    var liveStartUtc = _a.liveStartUtc, vodStartUtc = _a.vodStartUtc, episodeMaxChannelDuration = _a.episodeMaxChannelDuration;
    var contents = useSelector(selectAppContent);
    var timezone = useSelector(selectTimezone);
    var locale = useSelector(selectLocaleLanguage);
    var styles = useSelector(selectStyles);
    var timezoneAbbreviation = useSelector(selectDetectTimezoneAbbr);
    var dsTimezoneAbbreviation = useSelector(selectDsTimezoneAbbreviation);
    var vodDurationMetrics = useSelector(selectVodDurationMetrics);
    var formattedLiveDate = formatUtcDateInEventTimezone({
        date: liveStartUtc,
        timezone: timezone,
        timeFormatType: contents.time_format,
        dateFormatType: contents.date_format,
        dsTimezoneAbbreviation: dsTimezoneAbbreviation,
        timezoneAbbreviation: timezoneAbbreviation,
        hideTime: styles === null || styles === void 0 ? void 0 : styles.hero_hide_live_time,
        locale: locale
    });
    var formattedVodDate = formatUtcDateInEventTimezone({
        date: vodStartUtc,
        timezone: timezone,
        timeFormatType: contents.time_format,
        dateFormatType: contents.date_format,
        dsTimezoneAbbreviation: dsTimezoneAbbreviation,
        timezoneAbbreviation: timezoneAbbreviation,
        hideTime: styles === null || styles === void 0 ? void 0 : styles.hero_hide_vod_time,
        locale: locale
    });
    var liveStartTitle = contents.live_datetime_title;
    var vodStartTitle = contents.vod_datetime_title;
    var maxChannelDurationInHours = typeof episodeMaxChannelDuration === "string" ? parseInt(episodeMaxChannelDuration) : episodeMaxChannelDuration;
    var maxChannelDuration = !!vodDurationMetrics && maxChannelDurationInHours ? maxChannelDurationInHours / 24 : maxChannelDurationInHours;
    return (_jsxs("div", __assign({ className: "block-episodes__details-container" }, { children: [liveStartTitle && formattedLiveDate && (_jsxs("div", { children: [_jsx(SanitizeHtml, { tag: "span", stringToSanitize: liveStartTitle }), " ", styles.date_on_new_line ? _jsx("br", {}) : _jsx("span", {}), " ", _jsx("span", __assign({ className: "block-episodes__details-text" }, { children: formattedLiveDate })), _jsx("br", {})] })), vodStartTitle && formattedVodDate && (_jsxs("div", { children: [_jsx(SanitizeHtml, { tag: "span", stringToSanitize: vodStartTitle }), " ", styles.date_on_new_line ? _jsx("br", {}) : _jsx("span", {}), _jsx("span", __assign({ className: "block-episodes__details-text" }, { children: formattedVodDate })), " ", !!maxChannelDurationInHours && !Number.isNaN(maxChannelDuration) && (_jsxs("span", __assign({ className: "block-episodes__details-text" }, { children: [contents.up_to || "up to", " ", maxChannelDuration === null || maxChannelDuration === void 0 ? void 0 : maxChannelDuration.toFixed(0), " ", contents.hours || "hours"] })))] }))] })));
};
export var Episodes = function (_a) {
    var _b, _c;
    var _d = _a.viewType, viewType = _d === void 0 ? "full-page" : _d, title = _a.title, animation = _a.animation, props = __rest(_a, ["viewType", "title", "animation"]);
    var timezoneAbbreviation = useSelector(selectDetectTimezoneAbbr);
    var dsTimezoneAbbreviation = useSelector(selectDsTimezoneAbbreviation);
    var contents = useSelector(selectAppContent);
    var locale = useSelector(selectLocaleLanguage);
    var styles = useSelector(selectStyles);
    var episodesToShowInHomepage = useEpisodesToShowInHomepage().episodesToShowInHomepage;
    var timezone = useSelector(selectTimezone);
    var right_col_logo = useSelector(selectMedia).right_col_logo;
    var _e = useState(((_b = episodesToShowInHomepage === null || episodesToShowInHomepage === void 0 ? void 0 : episodesToShowInHomepage[0]) === null || _b === void 0 ? void 0 : _b.id) || null), selectedEpisodeId = _e[0], setSelectedEpisodeId = _e[1];
    var episodesTitle = title === null || title === "" ? null : contents.event_title;
    useEffect(function () {
        var _a;
        setSelectedEpisodeId(((_a = episodesToShowInHomepage === null || episodesToShowInHomepage === void 0 ? void 0 : episodesToShowInHomepage[0]) === null || _a === void 0 ? void 0 : _a.id) || null);
    }, [episodesToShowInHomepage]);
    var titleRef = useRef(null);
    var titleAnimationClass = useAnimationClassName({ ref: titleRef, animation: animation });
    var contentRef = useRef(null);
    var contentAnimationClass = useAnimationClassName({ ref: contentRef, animation: animation });
    var baseClass = "block-episodes";
    var mainClassNames = classNames(baseClass, {
        "block-episodes--full-page": viewType === "full-page",
        "block-episodes--list": viewType === "list"
    });
    var episodesToShow = viewType === "full-page" ? episodesToShowInHomepage === null || episodesToShowInHomepage === void 0 ? void 0 : episodesToShowInHomepage.filter(function (episode) { return episode.id === selectedEpisodeId; }) : episodesToShowInHomepage;
    return (_jsxs(BlockWrapper, __assign({}, props, { className: mainClassNames, "data-testid": "block-episodes" }, { children: [(right_col_logo === null || right_col_logo === void 0 ? void 0 : right_col_logo.url) && (_jsx(Title, __assign({ level: 2, style: { marginBottom: "25px" } }, { children: _jsx(Logo, { align: "left", src: right_col_logo === null || right_col_logo === void 0 ? void 0 : right_col_logo.url }) }))), episodesTitle && (_jsxs("div", __assign({ className: titleAnimationClass }, { children: [_jsx(Title, __assign({ ref: titleRef, className: "".concat(baseClass, "__title"), level: 1 }, { children: episodesTitle })), contents.sub_title && _jsx("div", __assign({ className: "".concat(baseClass, "__sub-title") }, { children: contents.sub_title })), contents.sub_sub_title && _jsx("div", __assign({ className: "".concat(baseClass, "__sub-sub-title") }, { children: contents.sub_sub_title })), _jsx(Divider, {})] }))), viewType === "full-page" ? (_jsxs("div", __assign({ ref: contentRef, className: "".concat(baseClass, "__full-page-container ").concat(contentAnimationClass) }, { children: [viewType === "full-page" && episodesToShowInHomepage.length > 1 && (_jsx("div", __assign({ className: "".concat(baseClass, "__episode-selector") }, { children: _jsx(Select, { onSelect: setSelectedEpisodeId, style: { marginBottom: 15 }, value: selectedEpisodeId, options: episodesToShowInHomepage === null || episodesToShowInHomepage === void 0 ? void 0 : episodesToShowInHomepage.map(function (episode) {
                                return { value: episode.id, label: episode.dropdown_label || episode.title };
                            }) }) }))), episodesToShow === null || episodesToShow === void 0 ? void 0 : episodesToShow.map(function (episode) {
                        var _a, _b, _c;
                        var hiddenSpeakerIds = !!(episode === null || episode === void 0 ? void 0 : episode.is_agenda_speakers_hidden)
                            ? (_a = episode.speakers) === null || _a === void 0 ? void 0 : _a.map(function (speaker) { return speaker.id; })
                            : episode.hidden_speakers_cards;
                        return (_jsxs(Row, { children: [_jsxs(Col, __assign({ xs: 24 }, { children: [_jsx(Title, __assign({ className: "".concat(baseClass, "__episode-title"), style: { marginBottom: 10, marginTop: (episodesToShow === null || episodesToShow === void 0 ? void 0 : episodesToShow.length) > 1 ? 20 : 5 }, level: !episodesTitle ? 2 : 3 }, { children: episode.title })), episode.subtitle && (_jsx(Title, __assign({ style: { marginTop: 0 }, level: 5, className: "".concat(baseClass, "__episode-subtitle") }, { children: episode.subtitle })))] })), _jsx(Col, __assign({ xs: 24 }, { children: _jsx("div", __assign({ className: "".concat(baseClass, "__description") }, { children: _jsx(EpisodeDetails, { liveStartUtc: (_b = episode === null || episode === void 0 ? void 0 : episode.live_scheduling) === null || _b === void 0 ? void 0 : _b.start_at, vodStartUtc: (_c = episode === null || episode === void 0 ? void 0 : episode.vod_scheduling) === null || _c === void 0 ? void 0 : _c.start_at, episodeMaxChannelDuration: episode.max_channel_duration }) })) })), _jsx(Col, __assign({ xs: 24 }, { children: _jsxs(Row, __assign({ gutter: rowGutter }, { children: [_jsx(Agenda, { viewType: "list", xl: { dimension: "1-2" }, verticalSpacing: "small", noGutter: true, agendaItems: episode.agenda, hiddenSpeakersId: hiddenSpeakerIds, titleLevel: 4 }), _jsx(Speakers, { viewType: "list", xl: { dimension: "1-2" }, verticalSpacing: "small", noGutter: true, speakers: episode.speakers, hiddenSpeakersId: hiddenSpeakerIds, titleLevel: 4 })] })) }))] }, episode.id));
                    })] }))) : (_jsx("div", __assign({ ref: contentRef, className: contentAnimationClass }, { children: _jsx(Collapse, { ghost: true, expandIconPosition: "end", expandIcon: function (_a) {
                        var isActive = _a.isActive;
                        var isArrows = styles.episode_accordion_title_icon === "1";
                        if (isArrows)
                            return isActive ? _jsx(UpOutlined, {}) : _jsx(DownOutlined, {});
                        else
                            return isActive ? _jsx(MinusOutlined, {}) : _jsx(PlusOutlined, {});
                    }, className: "".concat(baseClass, "__collapse"), defaultActiveKey: (_c = episodesToShow === null || episodesToShow === void 0 ? void 0 : episodesToShow[0]) === null || _c === void 0 ? void 0 : _c.id, items: episodesToShow === null || episodesToShow === void 0 ? void 0 : episodesToShow.map(function (episode) {
                        var _a, _b, _c, _d;
                        var hiddenSpeakerIdsAgenda = !!(episode === null || episode === void 0 ? void 0 : episode.is_agenda_speakers_hidden)
                            ? (_a = episode.speakers) === null || _a === void 0 ? void 0 : _a.map(function (speaker) { return speaker.id; })
                            : episode.hidden_speakers_cards;
                        var hiddenSpeakerIds = episode.hidden_speakers_cards;
                        var formattedLiveDate = formatUtcDateInEventTimezone({
                            date: (_b = episode === null || episode === void 0 ? void 0 : episode.live_scheduling) === null || _b === void 0 ? void 0 : _b.start_at,
                            timezone: timezone,
                            timeFormatType: contents.time_format,
                            dateFormatType: contents.date_format,
                            dsTimezoneAbbreviation: dsTimezoneAbbreviation,
                            timezoneAbbreviation: timezoneAbbreviation,
                            hideTime: true,
                            locale: locale
                        });
                        return {
                            key: episode.id,
                            label: (_jsxs(Row, __assign({ className: "".concat(baseClass, "__collapse-header"), "data-testid": "episode-collapse-head" }, { children: [_jsx(Col, __assign({ xs: 24, lg: 5, xl: 5, xxl: 4, className: "".concat(baseClass, "__collapse-episode-day") }, { children: formattedLiveDate })), _jsxs(Col, __assign({ xs: 24, lg: 19, xl: 19, xxl: 20 }, { children: [_jsx(Title, __assign({ style: { margin: 0 }, level: 3, className: "".concat(baseClass, "__collapse-episode-title") }, { children: episode.title })), episode.subtitle && (_jsx(Title, __assign({ style: { marginTop: 0 }, className: "".concat(baseClass, "__collapse-episode-subtitle"), level: 5 }, { children: episode.subtitle }))), _jsx(EpisodeDetails, { liveStartUtc: (_c = episode === null || episode === void 0 ? void 0 : episode.live_scheduling) === null || _c === void 0 ? void 0 : _c.start_at, vodStartUtc: (_d = episode === null || episode === void 0 ? void 0 : episode.vod_scheduling) === null || _d === void 0 ? void 0 : _d.start_at, episodeMaxChannelDuration: episode.max_channel_duration })] })), _jsx(Divider, { style: { marginBottom: 0 } })] }))),
                            children: (_jsxs(Row, __assign({ gutter: [42, 0], className: "".concat(baseClass, "__collapse-body") }, { children: [_jsx(Agenda, { viewType: "list", xl: { dimension: "1-2" }, noGutter: true, verticalSpacing: "small", agendaItems: episode.agenda, hiddenSpeakersId: hiddenSpeakerIdsAgenda, titleLevel: 4 }), _jsx(Speakers, { viewType: "list", xl: { dimension: "1-2" }, noGutter: true, verticalSpacing: "small", speakers: episode.speakers, hiddenSpeakersId: hiddenSpeakerIds, titleLevel: 4 })] })))
                        };
                    }) }) })))] })));
};
