var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Player from "../../ui/Player/Player";
import { UNKNOWN, VOD, LIVE } from "../../../types/player";
import Card from "../../ui/Card/Card";
import { fetchSourceEpisode } from "../../../api/fetchSource";
import { logoutUserThunk, selectLocaleLanguage } from "@adm-media/adam-store-plugin";
import { getDiffTime } from "../../../utils/date";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import "./SelectEpisodeCard.scss";
var Meta = Card.Meta;
/**
 * Component that show video preview.
 * At the time of writing we only have a single fetchsource available,
 * the idea is to consume the api N times and store it within the component state
 *
 * For LIVE - we want to show video w/out audio always taking the live_started_ at as reference
 * For VOD - we want to show videos w/out audio always starting from current time 0
 * @returns
 */
var SelectEpisodeCard = function (_a) {
    var episode = _a.episode, playerChannelAction = _a.playerChannelAction;
    var dispatch = useAppDispatch();
    var _b = useState({
        isFakeLive: null,
        status: null,
        id: null
    }), channel = _b[0], setChannel = _b[1];
    var locale = useSelector(selectLocaleLanguage);
    var _c = useState({ sources: [] }), playerSource = _c[0], setPlayerSource = _c[1];
    var _d = useState(episode.live_started_at), liveStartedAt = _d[0], setLiveStartedAt = _d[1];
    var _e = useState(undefined), playerRef = _e[0], setPlayerRef = _e[1];
    var _f = useState(), previousSourceCurrentTime = _f[0], setPreviousSourceCurrentTime = _f[1];
    var _g = useState(undefined), keepVODCurrentTime = _g[0], setKeepVODCurrentTime = _g[1];
    var _h = useState("visible"), documentVisibilityState = _h[0], setDocumentVisibilityState = _h[1];
    useEffect(function () {
        getSource(episode.id);
    }, [locale]);
    useEffect(function () {
        if (playerChannelAction)
            changeSource(playerChannelAction);
    }, [playerChannelAction]);
    var getSource = function (episodeId) { return __awaiter(void 0, void 0, void 0, function () {
        var data, playlist, status_1, id, channel_1, episode_live_started_at, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchSourceEpisode(episodeId)];
                case 1:
                    data = _a.sent();
                    playlist = data.playlist, status_1 = data.status, id = data.id, channel_1 = data.channel, episode_live_started_at = data.episode_live_started_at;
                    setLiveStartedAt(episode_live_started_at);
                    setChannel({ id: id, status: status_1, isFakeLive: !!(channel_1 === null || channel_1 === void 0 ? void 0 : channel_1.is_fake_live) });
                    setPlayerSource({ sources: playlist ? [playlist[status_1 || UNKNOWN]] : [] });
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    // TODO: remove when problem solved
                    console.warn("Error while fetching episode sources. ", e_1);
                    dispatch(logoutUserThunk());
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var changeSource = function (data) {
        var _a;
        var newPlaylist = (_a = data === null || data === void 0 ? void 0 : data.actions) === null || _a === void 0 ? void 0 : _a.find(function (action) { return action.id === channel.id; });
        if (!newPlaylist)
            return;
        var status = newPlaylist.status, keep_current_time = newPlaylist.keep_current_time, episode_live_started_at = newPlaylist.episode_live_started_at, is_fake_live = newPlaylist.is_fake_live, source = newPlaylist.source;
        if (is_fake_live && episode_live_started_at)
            setLiveStartedAt(episode_live_started_at);
        setKeepVODCurrentTime(keep_current_time);
        setChannel(function (previousChannel) { return (__assign(__assign({}, previousChannel), { status: status, isFakeLive: !!is_fake_live })); });
        setPreviousSourceCurrentTime((playerRef === null || playerRef === void 0 ? void 0 : playerRef.currentTime) || 0);
        setPlayerSource({ sources: [source] });
    };
    // Handling timing of VOD
    useEffect(function () {
        if (playerRef && channel.status === VOD && keepVODCurrentTime && previousSourceCurrentTime) {
            playerRef.currentTime = previousSourceCurrentTime;
            playerRef === null || playerRef === void 0 ? void 0 : playerRef.play();
        }
    }, [channel.status, keepVODCurrentTime, playerRef, previousSourceCurrentTime]);
    // Handling timing of LIVE
    useEffect(function () {
        if (playerRef && channel.status === LIVE && channel.isFakeLive && liveStartedAt && documentVisibilityState === "visible") {
            playerRef.currentTime = getDiffTime(liveStartedAt);
            playerRef === null || playerRef === void 0 ? void 0 : playerRef.play();
        }
    }, [playerRef, liveStartedAt, channel.status, channel.isFakeLive, documentVisibilityState, playerSource]);
    /** Logic on user navigation between pages, if user goes out the video we pause the video and reset
     * Do in case of: channel is fake live && channel status is live */
    useEffect(function () {
        var handler = function (event) {
            setDocumentVisibilityState(event.target.visibilityState);
        };
        document.addEventListener("visibilitychange", handler);
        return function () { return document.removeEventListener("visibilitychange", handler); };
    }, []);
    return (_jsx(Card, __assign({ style: { flex: 1 }, hoverable: true, "data-testid": "select-episode-card", className: "select-episode-card", cover: _jsxs("div", __assign({ className: "select-episode-card__cover" }, { children: [_jsx(Player, { hideControls: true, loop: channel.status !== LIVE && channel.status !== VOD, autoplay: true, source: playerSource, onPlayerReady: function (player) { return setPlayerRef(player); }, forceLiveUi: true }), channel.status === LIVE && (_jsx("div", __assign({ "data-testid": "live-vod-label", className: "select-episode-card__status-tag" }, { children: "LIVE" }))), channel.status === VOD && (_jsx("div", __assign({ "data-testid": "live-vod-label", className: "select-episode-card__status-tag" }, { children: "VOD" })))] })) }, { children: _jsx(Meta, { title: episode.title, description: episode.subtitle }) })));
};
export default SelectEpisodeCard;
