var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { selectAppContent, selectAppLanguages, selectLayoutUi, selectLocaleLanguage, selectPublicConfig, selectStyles } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { OpenLoginModalButton } from "../../ui/Buttons/OpenLoginModalButton/OpenLoginModalButton";
import SelectLang from "../../ui/Select/SelectLang/SelectLang";
import { DownloadICSButton } from "../../ui/Buttons/DownloadICSButton/DownloadICSButton";
import { Button, Col, Divider, Flex, Row } from "antd";
import Drawer from "../../ui/Drawer/Drawer";
import { useEffect, useState } from "react";
import { HamburgerIcon } from "../../ui/Icons/Icons";
import Space from "../../ui/Space/Space";
import { HomepageDownloadButton } from "../../ui/Buttons/HomepageDownloadButton/HomepageDownloadButton";
var enrichNavbarProps = function (_a) {
    var _b, _c, _d;
    var props = __rest(_a, []);
    var styles = useSelector(selectStyles);
    var availableLanguages = useSelector(selectAppLanguages);
    var layoutUi = useSelector(selectLayoutUi);
    var contents = useSelector(selectAppContent);
    var _e = useState(false), isAnchorDrawerOpen = _e[0], setIsAnchorDrawerOpen = _e[1];
    var publicConfig = useSelector(selectPublicConfig);
    var locale = useSelector(selectLocaleLanguage);
    var downloadButtonConfig = locale
        ? ((_c = (_b = publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.homepageDownloadButton) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c[locale]) || undefined
        : undefined;
    var displayAnchors = !!(styles === null || styles === void 0 ? void 0 : styles.display_navbar_anchor_links);
    var sectionLinks = (_d = layoutUi === null || layoutUi === void 0 ? void 0 : layoutUi.children) === null || _d === void 0 ? void 0 : _d.map(function (element) {
        if (element.component === "Agenda")
            return { id: "agenda", label: contents.agenda_title };
        else if (element.component === "Speakers" && !!(styles === null || styles === void 0 ? void 0 : styles.display_homepage_speakers_section)) {
            return { id: "speakers", label: contents.speakers_title };
        }
        else if (element.component === "Contacts")
            return { id: "contacts", label: contents.contacts_title };
        else
            return null;
    }).filter(function (element) { return element !== null; });
    var actions = [];
    if ((availableLanguages === null || availableLanguages === void 0 ? void 0 : availableLanguages.length) > 1)
        actions = __spreadArray(__spreadArray([], actions, true), [_jsx(SelectLang, {}, "withSelectLang")], false);
    if (props.withLoginButton) {
        actions = __spreadArray(__spreadArray([], actions, true), [_jsx(OpenLoginModalButton, { "data-testid": "navbar-login-button", className: "navbar-login-button" }, "login-button")], false);
    }
    if (styles === null || styles === void 0 ? void 0 : styles.add_save_the_date_button) {
        actions = __spreadArray([
            _jsx(DownloadICSButton, { className: displayAnchors ? "hide-on-mobile" : undefined, hideLabelOnMobile: true }, "download-ics-button")
        ], actions, true);
    }
    if (downloadButtonConfig !== undefined) {
        actions = __spreadArray([
            _jsx(HomepageDownloadButton, { className: displayAnchors ? "hide-on-mobile" : undefined, hideLabelOnMobile: true }, "homepage-download-button")
        ], actions, true);
    }
    useEffect(function () {
        var handleOrientationChange = function () {
            setIsAnchorDrawerOpen(false);
        };
        try {
            if (screen.orientation && screen.orientation.addEventListener) {
                screen.orientation.addEventListener("change", handleOrientationChange);
            }
        }
        catch (_a) { }
        return function () {
            try {
                if (screen.orientation && screen.orientation.removeEventListener) {
                    screen.orientation.removeEventListener("change", handleOrientationChange);
                }
            }
            catch (_a) { }
        };
    }, []);
    var scrollToEndOfHeroBlock = function (id) {
        var elementToScroll = document.getElementById(id);
        if (elementToScroll)
            elementToScroll === null || elementToScroll === void 0 ? void 0 : elementToScroll.scrollIntoView({ behavior: "smooth" });
        setIsAnchorDrawerOpen(false);
    };
    if (displayAnchors) {
        actions = __spreadArray(__spreadArray([
            _jsx(Row, __assign({ "data-testid": "navbar-anchor-links", className: "navbar__anchor-links-desktop" }, { children: _jsx(Col, __assign({ xs: 24 }, { children: _jsx(Space, { children: sectionLinks.map(function (sectionLink) {
                            return (_jsx(Button, __assign({ "data-testid": "navbar-anchor-link", type: "text", onClick: function () { return scrollToEndOfHeroBlock(sectionLink.id); }, className: "navbar__anchor-link" }, { children: sectionLink.label }), sectionLink.id));
                        }) }) })) }), "navbar-anchor-links"),
            _jsxs(Drawer, __assign({ title: null, open: isAnchorDrawerOpen, closable: false, onClose: function () { return setIsAnchorDrawerOpen(false); }, placement: "right", width: 220, rootClassName: "navbar__anchor-drawer", "data-testid": "navbar-anchor-drawer" }, { children: [_jsx(Space, __assign({ direction: "vertical", className: "navbar__anchor-drawer-buttons-container" }, { children: sectionLinks.map(function (sectionLink) {
                            return (_jsx(Button, __assign({ "data-testid": "navbar-anchor-link-drawer", className: "navbar__anchor-link-drawer", block: true, onClick: function () { return scrollToEndOfHeroBlock(sectionLink.id); } }, { children: sectionLink.label }), sectionLink.id));
                        }) })), ((styles === null || styles === void 0 ? void 0 : styles.add_save_the_date_button) || downloadButtonConfig !== undefined) && (_jsxs(Flex, __assign({ vertical: true, gap: 10 }, { children: [_jsx(Divider, {}), (styles === null || styles === void 0 ? void 0 : styles.add_save_the_date_button) && _jsx(DownloadICSButton, { block: true }), downloadButtonConfig !== undefined && _jsx(HomepageDownloadButton, { block: true })] })))] }), "navbar-anchor-drawer")
        ], actions, true), [
            _jsx(HamburgerIcon, { className: "navbar__anchor-drawer-burger-button", open: isAnchorDrawerOpen, onClick: function () { return setIsAnchorDrawerOpen(function (ps) { return !ps; }); } }, "navbar-anchor-drawer-burger-button")
        ], false);
    }
    return __assign(__assign({}, props), { actions: actions, verticalSpacing: "small" });
};
export default enrichNavbarProps;
