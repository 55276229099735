import moment from "moment";
import momentTimezone from "moment-timezone";
var dateFormat = "YYYY-MM-DD HH:mm:ss";
export var utcToTimeZone = function (_a) {
    var time = _a.time, zone = _a.zone, format = _a.format;
    if (!time)
        return null;
    var unformatted = momentTimezone.utc(time).tz(zone || "utc");
    return unformatted.format(format || dateFormat);
};
export var timeZoneToUtc = function (_a) {
    var _b, _c;
    var time = _a.time, zone = _a.zone;
    if (!time)
        return null;
    return momentTimezone
        .tz((_c = (_b = moment(time)) === null || _b === void 0 ? void 0 : _b.set({ second: 0 })) === null || _c === void 0 ? void 0 : _c.format(dateFormat), dateFormat, zone || "utc")
        .utc()
        .format(dateFormat);
};
