var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Player from "../../components/ui/Player/Player";
import { useSelector } from "react-redux";
import { selectLoadingDetect, selectVideoTestUrl } from "@adm-media/adam-store-plugin";
import { Result } from "antd";
import Button from "../../components/ui/Buttons/Button";
import { useNavigate } from "react-router";
import Navbar from "../../components/blocks/Navbar/Navbar";
import Footer from "../../components/blocks/Footer/Footer";
import Row from "../../components/ui/Row/Row";
import { OpenLoginModalButton } from "../../components/ui/Buttons/OpenLoginModalButton/OpenLoginModalButton";
import "../../components/ui/Player/Player.scss";
import "../../components/ui/Player/Wrapper.scss";
import "./Videotest.scss";
export var VideotestPage = function () {
    var navigate = useNavigate();
    var videoTestUrl = useSelector(selectVideoTestUrl);
    var loadingDetect = useSelector(selectLoadingDetect);
    var sourceString = videoTestUrl || process.env.DEMO_PLAYER_SRC;
    var source = { sources: [{ src: sourceString }] };
    if (loadingDetect === "uninitialized" || loadingDetect === "pending")
        return null;
    return (_jsxs(Row, __assign({ "data-testid": "videotest", className: "videotest" }, { children: [_jsx(Navbar, { verticalSpacing: "small", actions: _jsx(OpenLoginModalButton, {}) }), !!sourceString ? (_jsx(Player, { autoplay: true, isFakeLive: true, loop: true, source: source, widescreen: true, forceLiveUi: true, muted: true, hideSpeed: true })) : (_jsx("div", __assign({ "data-testid": "no-videotest-message" }, { children: _jsx(Result, { status: "404", title: "No test source has been set yet", subTitle: "Please be patient while we take care of it", extra: _jsx(Button, __assign({ onClick: function () { return navigate("/"); } }, { children: "Go to the homepage" })) }) }))), _jsx(Footer, {})] })));
};
