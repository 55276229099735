var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox as AntCheckbox } from "antd";
import "./Checkbox.scss";
var Checkbox = function (_a) {
    var children = _a.children, _b = _a.clickableLabel, clickableLabel = _b === void 0 ? true : _b, className = _a.className, props = __rest(_a, ["children", "clickableLabel", "className"]);
    return !clickableLabel ? (_jsx(AntCheckbox, __assign({}, props, { className: "checkbox ".concat(className || "") }, { children: children }))) : (_jsxs(_Fragment, { children: [_jsx(AntCheckbox, __assign({}, props, { className: "checkbox ".concat(className || "") }, { children: " " })), _jsx("span", { children: children })] }));
};
export default Checkbox;
