import { jsx as _jsx } from "react/jsx-runtime";
import EvaluationForm from "../../ui/EvaluationForm/EvaluationForm";
import "./EvaluationModal.scss";
export var EvaluationModal = function () {
    return _jsx(EvaluationForm, {});
};
export var getEvaluationModalProps = function () {
    return {
        modalClassName: "evaluation-modal"
    };
};
