import { logPlayerEventThunk, logPlayerInsightsThunk, selectLastPlayerLoggedEventId } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
export var usePlayerService = function () {
    var dispatch = useAppDispatch();
    var lastPlayerLoggedEventId = useSelector(selectLastPlayerLoggedEventId);
    var logPlayerEvent = function (action, _a) {
        var playerSource = _a.playerSource, player = _a.player, episodeId = _a.episodeId, pauseTime = _a.pauseTime;
        dispatch(logPlayerEventThunk({
            channelStatus: playerSource === null || playerSource === void 0 ? void 0 : playerSource.status,
            name: action,
            episodeId: episodeId,
            duration: player === null || player === void 0 ? void 0 : player.duration,
            time: pauseTime ? Math.round(pauseTime) : Math.round(player === null || player === void 0 ? void 0 : player.currentTime),
            log_id: lastPlayerLoggedEventId,
            channelId: playerSource.id
        }));
    };
    var logPlayerInsight = function (playerPayload) { return dispatch(logPlayerInsightsThunk(playerPayload)); };
    return { logPlayerEvent: logPlayerEvent, logPlayerInsight: logPlayerInsight };
};
