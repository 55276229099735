var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { hideModal, selectCurrentEpisode, selectMeCurrentStreamInfo, sendQuestionThunk, selectNewQuestion } from "@adm-media/adam-store-plugin";
import QAForm from "../../ui/QAForm/QAForm";
import { QA_QUESTION_MODAL } from "..";
import { useAppDispatch } from "../../../store";
import { useNotification } from "../../../hooks/useNotification";
/**
 * Modal visibile by Moderator to add new question to chairman
 * @returns
 */
export var QAQuestionModal = function () {
    var dispatch = useAppDispatch();
    var loadingNewQuestion = useSelector(selectNewQuestion).loading;
    var episodeId = useSelector(selectCurrentEpisode).id;
    var _a = useSelector(selectMeCurrentStreamInfo), streamId = _a.streamId, streamName = _a.streamName;
    var notification = useNotification().notification;
    // Submit new question from modal, if "Send immediately" it will be submitted immediately
    var handleSubmitNewQuestion = function (_a) {
        var confirmed = _a.confirmed, question = _a.question;
        return dispatch(sendQuestionThunk({
            episode_id: episodeId,
            confirmed: confirmed,
            original_content: question,
            edited_content: confirmed ? question : undefined,
            stream_id: streamId,
            stream_name: streamName
        })).then(function (actionThunkPayload) {
            var _a, _b, _c, _d, _e, _f, _g;
            var payload = actionThunkPayload === null || actionThunkPayload === void 0 ? void 0 : actionThunkPayload.payload;
            if ((payload === null || payload === void 0 ? void 0 : payload.status) === 422) {
                dispatch(hideModal({ name: QA_QUESTION_MODAL }));
                var message = Array.isArray((_b = (_a = payload === null || payload === void 0 ? void 0 : payload.data) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.edited_content) && ((_d = (_c = payload === null || payload === void 0 ? void 0 : payload.data) === null || _c === void 0 ? void 0 : _c.errors) === null || _d === void 0 ? void 0 : _d.edited_content.length) > 0
                    ? (_g = (_f = (_e = payload === null || payload === void 0 ? void 0 : payload.data) === null || _e === void 0 ? void 0 : _e.errors) === null || _f === void 0 ? void 0 : _f.edited_content) === null || _g === void 0 ? void 0 : _g.join(", ")
                    : "There was an error";
                notification.error({ message: message });
            }
            else if (typeof (payload === null || payload === void 0 ? void 0 : payload.episode_id) === "number" && typeof (payload === null || payload === void 0 ? void 0 : payload.id) === "number") {
                dispatch(hideModal({ name: QA_QUESTION_MODAL }));
                if (confirmed) {
                    notification.success({ message: "Question sent to chairman" });
                }
            }
            else {
                dispatch(hideModal({ name: QA_QUESTION_MODAL }));
            }
        });
    };
    return (_jsx("div", __assign({ "data-testid": "qa-moderator-add-question-modal" }, { children: _jsx(QAForm, { title: "Add question", withConfirmation: true, confirmationLabel: "Send immediately", submitButtonText: "Send question", loading: loadingNewQuestion === "pending", onSubmit: handleSubmitNewQuestion }) })));
};
export var getQAQuestionModalProps = function () {
    return {};
};
