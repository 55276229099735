import { useEffect } from "react";
import loadFont from "../utils/load-font";
import { selectStyles } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { defaultAntdFontFamily } from "../utils/fonts";
export var useLoadFonts = function () {
    var styles = useSelector(selectStyles);
    useEffect(function () {
        if (!(styles === null || styles === void 0 ? void 0 : styles.default_font_family) || (styles === null || styles === void 0 ? void 0 : styles.default_font_family) === defaultAntdFontFamily)
            return;
        loadFont(styles === null || styles === void 0 ? void 0 : styles.default_font_family);
    }, [styles]);
};
