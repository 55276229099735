import { useEffect, useState } from "react";
export var ScreenSize;
(function (ScreenSize) {
    ScreenSize["XS"] = "xs";
    ScreenSize["SM"] = "sm";
    ScreenSize["MD"] = "md";
    ScreenSize["LG"] = "lg";
    ScreenSize["XL"] = "xl";
    ScreenSize["XXL"] = "xxl";
})(ScreenSize || (ScreenSize = {}));
// make sure that these are coordinated with scss breakpoints variables in src/styles/variables.scss
var breakpoints = {
    0: "xs",
    576: "sm",
    768: "md",
    992: "lg",
    1200: "xl",
    1600: "xxl"
};
var useBreakpoint = function () {
    var _a = useState(""), breakpoint = _a[0], setBreakPoint = _a[1];
    var _b = useState({
        width: window.innerWidth,
        height: window.innerHeight
    }), windowSize = _b[0], setWindowSize = _b[1];
    var handleResize = function () {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };
    var isMobile = windowSize.width < 576;
    var isMiniTablet = 576 <= windowSize.width && windowSize.width < 768;
    var isTablet = 768 <= windowSize.width && windowSize.width < 992;
    var isDesktop = 992 <= windowSize.width && windowSize.width < 1200;
    var isLgDesktop = 992 <= windowSize.width && windowSize.width < 1200;
    var isXlDesktop = windowSize.width >= 1600;
    var getBreakPoints = function (breakpointsValue) {
        if (isMobile) {
            return breakpointsValue[0];
        }
        if (isMiniTablet) {
            return breakpointsValue[576];
        }
        if (isTablet) {
            return breakpointsValue[768];
        }
        if (isDesktop) {
            return breakpointsValue[992];
        }
        if (isLgDesktop) {
            return breakpointsValue[1200];
        }
        if (isXlDesktop) {
            return breakpointsValue[1600];
        }
        return "";
    };
    useEffect(function () {
        window.addEventListener("resize", handleResize);
        handleResize();
        setBreakPoint(getBreakPoints(breakpoints));
        return function () { return window.removeEventListener("resize", handleResize); };
    }, [windowSize.width]);
    return { breakpoint: breakpoint, windowSize: windowSize, isMobile: isMobile, isMiniTablet: isMiniTablet, isTablet: isTablet, isDesktop: isDesktop, isLgDesktop: isLgDesktop, isXlDesktop: isXlDesktop };
};
export default useBreakpoint;
