var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List, ListItem, ListItemMeta } from "../List/List";
import Button from "../Buttons/Button";
import { getTimeFromSeconds } from "../../../utils/getTimeFromSeconds";
import { ClockIcon, PlayCircleIcon } from "../Icons/Icons";
import Space from "../Space/Space";
import { useAppDispatch } from "../../../store";
import { selectCurrectChapterCueId, setChapterTimeSelection } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
export var ChapterSelectionList = function (_a) {
    var chaptersList = _a.chaptersList, closeDrawerCallback = _a.closeDrawerCallback;
    var dispatch = useAppDispatch();
    var currentChapterCueId = useSelector(selectCurrectChapterCueId);
    var handleChapterTimeSelection = function (startAt) {
        closeDrawerCallback();
        dispatch(setChapterTimeSelection(startAt));
    };
    return (_jsx(List, { itemLayout: "horizontal", dataSource: chaptersList, renderItem: function (item) { return (_jsx(ListItem, __assign({ style: { padding: "20px 0px" }, actions: [
                _jsx(Button, { size: "large", onClick: function () { return handleChapterTimeSelection(item.startTime); }, icon: _jsx(PlayCircleIcon, {}), name: "Go to chapter", "data-testid": "go-to-chapter-button" })
            ] }, { children: _jsx(ListItemMeta, { title: item.id === currentChapterCueId ? _jsx("span", __assign({ style: { color: "red" } }, { children: item.content })) : item.content, description: _jsxs(Space, __assign({ direction: "horizontal" }, { children: [_jsx(ClockIcon, {}), getTimeFromSeconds(item.startTime)] })) }) }))); } }));
};
