import { selectEpisodes, selectSelectedEpisodeId } from "@adm-media/adam-store-plugin";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIsEvaluationFormAvailable } from "./useIsEvaluationFormAvailable";
export var useForcedEvaluationForm = function () {
    var episodes = useSelector(selectEpisodes);
    var _a = useState(false), showEvaluationFormPrompt = _a[0], setShowEvaluationFormPrompt = _a[1];
    var selectedEpisodeId = useSelector(selectSelectedEpisodeId);
    var _b = useIsEvaluationFormAvailable(), isEvaluationFormAvailable = _b.isEvaluationFormAvailable, isEvaluationFormAlreadyAnswered = _b.isEvaluationFormAlreadyAnswered;
    useEffect(function () {
        var _a, _b;
        var startAt = (_a = episodes.find(function (ep) { return ep.id === selectedEpisodeId; })) === null || _a === void 0 ? void 0 : _a.force_ef_start_at;
        var finishAt = (_b = episodes.find(function (ep) { return ep.id === selectedEpisodeId; })) === null || _b === void 0 ? void 0 : _b.force_ef_finish_at;
        if (!isEvaluationFormAlreadyAnswered &&
            isEvaluationFormAvailable &&
            !!startAt &&
            !!finishAt &&
            moment(startAt).isBefore() &&
            moment(finishAt).isAfter()) {
            setShowEvaluationFormPrompt(true);
        }
        else {
            setShowEvaluationFormPrompt(false);
        }
    }, [selectedEpisodeId]);
    return { showEvaluationFormPrompt: showEvaluationFormPrompt };
};
