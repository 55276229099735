var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectAppContent, selectMedia, selectStyles } from "@adm-media/adam-store-plugin";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import "./Footer.scss";
import { useAnimationClassName } from "../../../hooks/useAnimationClassName";
import { useRef } from "react";
var Footer = function (_a) {
    var _b, _c, _d;
    var copyrightText = _a.copyrightText, details = _a.details, _e = _a.centered, centered = _e === void 0 ? true : _e, animation = _a.animation, props = __rest(_a, ["copyrightText", "details", "centered", "animation"]);
    var styles = useSelector(selectStyles);
    var contents = useSelector(selectAppContent);
    var media = useSelector(selectMedia);
    var baseClass = "block-footer";
    var contentRef = useRef(null);
    var contentAnimationClass = useAnimationClassName({ ref: contentRef, animation: animation });
    var footerClassNames = classNames(baseClass, { centered: centered });
    return (_jsx(BlockWrapper, __assign({}, props, { className: footerClassNames, "data-testid": "block-footer" }, { children: _jsx("div", __assign({ ref: contentRef, className: contentAnimationClass }, { children: _jsxs("div", __assign({ className: "block-footer__container" }, { children: [details && _jsx(SanitizeHtml, { className: "".concat(baseClass, "__details"), tag: "div", stringToSanitize: details }), _jsx("div", __assign({ className: "".concat(baseClass, "__content") }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.website_footer, tag: "div" }) })), (((_b = media.footer_logo_desktop) === null || _b === void 0 ? void 0 : _b.url) || contents.footer_logo_text) && (_jsxs("div", __assign({ className: "".concat(baseClass, "__powered") }, { children: [contents.footer_logo_text && _jsx("span", __assign({ className: "".concat(baseClass, "__powered-label") }, { children: contents.footer_logo_text })), " ", (styles === null || styles === void 0 ? void 0 : styles.footer_logo_href) ? (_jsx("a", __assign({ href: styles.footer_logo_href, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("img", { src: (_c = media.footer_logo_desktop) === null || _c === void 0 ? void 0 : _c.url, alt: "" }) }))) : (_jsx("img", { src: (_d = media.footer_logo_desktop) === null || _d === void 0 ? void 0 : _d.url, alt: "" }))] }))), copyrightText && _jsx(SanitizeHtml, { className: "".concat(baseClass, "__copyright"), stringToSanitize: copyrightText, tag: "div" })] })) })) })));
};
export default Footer;
