var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import Button from "../Button";
import { SoundFilledIcon } from "../../Icons/Icons";
import "./UnMuteButton.scss";
import { useSelector } from "react-redux";
import { selectAppContent } from "@adm-media/adam-store-plugin";
var MuteButton = function (_a) {
    var onClick = _a.onClick, _b = _a.hideButton, hideButton = _b === void 0 ? false : _b;
    var contents = useSelector(selectAppContent);
    return (_jsx("div", __assign({ className: classNames("unmute-button-wrapper", { hideButton: hideButton }) }, { children: _jsx(Button, __assign({ "data-testid": "unmute-button", type: "default", onClick: onClick, icon: _jsx(SoundFilledIcon, { className: "unmute-button-icon" }) }, { children: contents.unmute })) })));
};
export default MuteButton;
