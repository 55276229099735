import { ErrorPage } from "./Errors/ErrorPage";
import { EpisodeProposalPage } from "./EpisodeProposal/EpisodeProposal";
import { MaintenancePage } from "./Maintenance/Maintenance";
import { WaitingRoomPage } from "./WaitingRoom/WaitingRoom";
import { WatchPage } from "./Watch/Watch";
import { ChangePasswordPage } from "./ChangePassword";
import { CountdownPage } from "./Countdown";
import { ModeratorPage } from "./Moderator";
import { ResetPasswordPage } from "./ResetPassword";
import { HomePage } from "./Home";
import { ChairmanPage } from "./Chairman";
import { VideotestPage } from "./Videotest/Videotest";
import { UnsubscribePage } from "./UnsubscribePage";
import { PublicQandAPage } from "./PublicQandA/PublicQandA";
import { ConferencePage } from "./Conference/Conference";
export { ChairmanPage, HomePage, ErrorPage, EpisodeProposalPage, MaintenancePage, WaitingRoomPage, WatchPage, ChangePasswordPage, CountdownPage, ModeratorPage, ResetPasswordPage, VideotestPage, UnsubscribePage, PublicQandAPage, ConferencePage };
export var Paths = {
    home: "/",
    watch: "/watch",
    player: "/player",
    chairman: "/chairman",
    moderator: "/moderator",
    resetPassword: "/reset-password",
    changePassword: "/change-password",
    watchPlayer: "/watch/player",
    videotest: "/videotest",
    publicQandA: "/public-qna",
    unsubscribe: "/unsubscribe"
};
