var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { fetchSourceThunk, logoutUserThunk, selectAppContent, selectCurrentEpisode, selectEpisodes, selectPlayerSource, subscribeChannelPresenceThunk } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import "./Conference.scss";
import { useNavigate } from "react-router";
import { Paths } from "..";
import { useAppDispatch } from "../../store";
import { useSetCurrentEpisode } from "../../hooks/useSetCurrentEpisode";
import { JitsiMeeting } from "../../components/functional/JitsiMeeting/JitsiMeeting";
import { useEffect, useState } from "react";
import RightNavigation from "../../components/ui/RightNavigation/RightNavigation";
import { LIVE, PRE_LIVE } from "../../types/player";
import { Flex } from "antd";
import { SanitizeHtml } from "../../components/ui/SanitizeHtml/SanitizeHtml";
export var ConferencePage = function (_a) {
    var conferenceId = _a.conferenceId;
    var _b = useSelector(selectCurrentEpisode), channel = _b.channel, episodeId = _b.id;
    var baseClass = "conference-page";
    var contents = useSelector(selectAppContent);
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var episodes = useSelector(selectEpisodes);
    var setCurrentEpisode = useSetCurrentEpisode().setCurrentEpisode;
    var playerSource = useSelector(selectPlayerSource);
    useEffect(function () {
        if (episodeId)
            dispatch(fetchSourceThunk(episodeId));
    }, [episodeId]);
    var _c = useState(false), showConference = _c[0], setShowConference = _c[1];
    useEffect(function () {
        if (playerSource.status === LIVE)
            setShowConference(true);
        else
            setShowConference(false);
    }, [playerSource.status]);
    useEffect(function () {
        if (channel.id)
            dispatch(subscribeChannelPresenceThunk(channel.id));
    }, [channel.id]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: baseClass, "data-testid": "conference-page" }, { children: [showConference && conferenceId && (_jsx(JitsiMeeting, { conferenceId: conferenceId, onReadyToClose: function () {
                            if (episodes.length === 1)
                                dispatch(logoutUserThunk());
                            else {
                                setCurrentEpisode({ episodeId: null });
                                navigate(Paths.watch);
                            }
                        } })), !showConference && playerSource.status !== null && playerSource.status !== undefined && playerSource.status !== LIVE && (_jsx(Flex, __assign({ justify: "center", align: "center", "data-testid": "non-live-conference-info" }, { children: playerSource.status === PRE_LIVE ? (_jsx("div", __assign({ className: "".concat(baseClass, "__pre-meeting-text"), "data-testid": "conference-pre-live-info" }, { children: _jsx(SanitizeHtml, { className: "".concat(baseClass, "__pre-meeting-text"), stringToSanitize: contents.virtual_world_will_open || "The conference has not started yet", tag: "div" }) }))) : (_jsx("div", __assign({ className: "".concat(baseClass, "__pre-meeting-text"), "data-testid": "conference-post-live-info" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.website_notes || "Conference unavailable", tag: "div" }) }))) })))] })), _jsx(RightNavigation, { isCountdownActive: true })] }));
};
