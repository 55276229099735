var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from "../Buttons/Button";
import Row from "../Row/Row";
import Col from "../Col/Col";
import Form from "../Form/Form";
import Input from "../Input/Input";
import Typography from "../Typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import { answerEvaluationFormThunk, resetAnswerEvaluationForm, selectAnswerEvaluationForm, selectAppContent, selectCurrentEpisode, selectLocaleLanguage, selectSelectedEpisodeId } from "@adm-media/adam-store-plugin";
import "./EvaluationForm.scss";
import { useNotification } from "../../../hooks/useNotification";
import { SanitizeHtml } from "../SanitizeHtml/SanitizeHtml";
import { useIsEvaluationFormAvailable } from "../../../hooks/useIsEvaluationFormAvailable";
var Title = Typography.Title;
var EvaluationForm = function () {
    var notification = useNotification().notification;
    var dispatch = useDispatch();
    var form = Form.useForm()[0];
    var currentLocale = useSelector(selectLocaleLanguage);
    var selectedEpisodeId = useSelector(selectSelectedEpisodeId);
    var contents = useSelector(selectAppContent);
    var questions = useSelector(selectCurrentEpisode).evaluation_questions;
    var isEvaluationFormAlreadyAnswered = useIsEvaluationFormAvailable().isEvaluationFormAlreadyAnswered;
    var loadingAnswerEvaluationForm = useSelector(selectAnswerEvaluationForm).loading;
    var handleSubmit = function (values) {
        dispatch(answerEvaluationFormThunk({ episode_id: episodeId, answers: values, lang_id: currentLocale }))
            .unwrap()
            .catch(function () {
            notification.showGenericError();
        })
            .finally(function () { return resetAnswerEvaluationForm(); });
    };
    var _a = useSelector(selectCurrentEpisode), evaluationTitle = _a.evaluation_form_title, title = _a.title, episodeId = _a.id;
    var hasAlreadyAnswered = selectedEpisodeId && isEvaluationFormAlreadyAnswered;
    return (_jsxs(_Fragment, { children: [evaluationTitle && (_jsx(Title, __assign({ level: 3, className: "evaluation-form__title" }, { children: evaluationTitle || title }))), !hasAlreadyAnswered ? (_jsx("div", __assign({ className: "evaluation-form", "data-testid": "evaluation-form" }, { children: _jsxs(Form, __assign({ onFinish: handleSubmit, form: form, layout: "vertical" }, { children: [questions === null || questions === void 0 ? void 0 : questions.map(function (_a) {
                            var evaluationquestions = _a.evaluationquestions, title = _a.title;
                            return (_jsxs("div", __assign({ className: "evaluation-form__section" }, { children: [_jsx(Row, { children: _jsx(Col, __assign({ span: 24 }, { children: _jsx(Title, __assign({ level: 4 }, { children: _jsx(SanitizeHtml, { stringToSanitize: title, tag: "div" }) })) })) }), evaluationquestions === null || evaluationquestions === void 0 ? void 0 : evaluationquestions.map(function (question) {
                                        var _a;
                                        /** is_radio is not used in evaluation form, therefore radio buttons are not used */
                                        // const type = question.is_radio ? "radio" : question.question_type === 1 ? "select" : "textarea";
                                        var type = question.question_type === 1 ? "checkbox" : "textarea";
                                        var rules = [];
                                        if (question.is_required)
                                            rules.push({ required: true, message: contents.field_required });
                                        if (question.max_allowed_choices && type !== "textarea")
                                            rules.push(function () { return ({
                                                validator: function (_, value) {
                                                    if (Array.isArray(value) && value.length > question.max_allowed_choices) {
                                                        return Promise.reject(new Error(contents.error_too_many_options_selected ||
                                                            "The number of answers entered exceeds the maximum permitted"));
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }); });
                                        return (_jsxs("div", __assign({ "data-testid": "evaluation-form-single-question" }, { children: [type === "checkbox" && (question === null || question === void 0 ? void 0 : question.options) && (_jsx(Form.Item, __assign({ name: question.id, label: question.content, rules: rules, extra: question.legend }, { children: _jsx(Checkbox.Group, __assign({ name: String(question.id), style: { width: "100%" } }, { children: _jsxs(Row, __assign({ gutter: [10, 10] }, { children: [Array.isArray(question.options) &&
                                                                    ((_a = question === null || question === void 0 ? void 0 : question.options) === null || _a === void 0 ? void 0 : _a.map(function (option) { return (_jsx(Col, __assign({ xs: 24 }, { children: _jsx(Checkbox, __assign({ "data-testid": "evaluation-form-option", className: "single-radio", value: String(option) }, { children: option }), option) }), option)); })), question.options !== null &&
                                                                    !Array.isArray(question.options) &&
                                                                    typeof question.options === "object" &&
                                                                    Object.keys(question.options).map(function (key) {
                                                                        if (question.options[key])
                                                                            return (_jsx(Col, __assign({ xs: 24 }, { children: _jsx(Checkbox, __assign({ "data-testid": "evaluation-form-option", className: "single-radio", value: question.options[key] }, { children: question.options[key] }), question.options[key]) }), question.options[key]));
                                                                    })] })) })) }))), type === "textarea" && (_jsx(Form.Item, __assign({ name: question.id, label: question.content, rules: rules, extra: question.legend }, { children: _jsx(Input.TextArea, { "data-testid": "evaluation-form-textarea", autoSize: { minRows: 3, maxRows: 8 } }) })))] }), question.id));
                                    })] }), title));
                        }), !!(questions === null || questions === void 0 ? void 0 : questions.length) && (_jsx(Button, __assign({ "data-testid": "evaluation-form-submit-button", loading: loadingAnswerEvaluationForm === "pending", type: "primary", htmlType: "submit" }, { children: contents.evaluation_submit_button_text || (contents === null || contents === void 0 ? void 0 : contents.submit_button_text) || "Submit" })))] })) }))) : hasAlreadyAnswered ? (_jsx(Title, __assign({ level: 3, className: "evaluation-modal__success", "data-testid": "evaluation-form-answered-text" }, { children: contents.evaluation_form_thankyou_message || "Thank you for answering" }))) : null] }));
};
export default EvaluationForm;
