import { jsx as _jsx } from "react/jsx-runtime";
import { hideModal, selectLayoutUi } from "@adm-media/adam-store-plugin";
import Row from "../../ui/Row/Row";
import classNames from "classnames";
import { useAppDispatch } from "../../../store";
import { LOGIN_MODAL } from "..";
import AuthBlock from "../../functional/AuthBlock/AuthBlock";
import { useSelector } from "react-redux";
import { LoginForm } from "../../blocks/LoginForm/LoginForm";
import "./LoginModal.scss";
export var LoginModal = function () {
    var _a;
    var dispatch = useAppDispatch();
    var layoutUi = useSelector(selectLayoutUi);
    var onLoginSuccess = function (payload) {
        if (payload === null || payload === void 0 ? void 0 : payload.token)
            dispatch(hideModal({ name: LOGIN_MODAL }));
    };
    var onRegisterSuccess = function () {
        dispatch(hideModal({ name: LOGIN_MODAL }));
    };
    var doesThemeHaveRegistrationForm = !!((_a = layoutUi === null || layoutUi === void 0 ? void 0 : layoutUi.children) === null || _a === void 0 ? void 0 : _a.find(function (child) { return (child === null || child === void 0 ? void 0 : child.component) === "RegistrationForm"; }));
    return (_jsx(Row, { children: doesThemeHaveRegistrationForm ? (_jsx(LoginForm, { noBackground: true, noGutter: true, verticalSpacing: "none", titleLevel: 3, onSuccessCallback: onLoginSuccess })) : (_jsx(AuthBlock, { defaultAuthState: "login", onLoginSuccess: onLoginSuccess, onRegisterSuccess: onRegisterSuccess })) }));
};
export var getLoginModalProps = function () {
    return {
        modalClassName: classNames("login-modal")
    };
};
