import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useSetCurrentEpisode } from "../../../hooks/useSetCurrentEpisode";
export var EpisodeSelectedWrapper = function (_a) {
    var children = _a.children;
    var setCurrentEpisode = useSetCurrentEpisode().setCurrentEpisode;
    useEffect(function () {
        return function () {
            setCurrentEpisode({ episodeId: null, doReset: true });
        };
    }, []);
    return _jsx(_Fragment, { children: children });
};
