import { kebabCase } from "lodash";
var loadFont = function (fontFamily) {
    // Prevent fail load
    if (!fontFamily)
        return;
    var font = document.createElement("link");
    font.rel = "stylesheet";
    font.id = "dynamic-font";
    document.head.appendChild(font);
    font.href = "".concat(process.env.FONT_BASE_URL, "/css/").concat(kebabCase(fontFamily), ".css");
};
export default loadFont;
