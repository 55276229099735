import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { selectAppContent } from "@adm-media/adam-store-plugin";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import "./InfoModal.scss";
export var InfoModal = function () {
    var contents = useSelector(selectAppContent);
    return _jsx(SanitizeHtml, { stringToSanitize: contents.initial_popup_footer, tag: "div" });
};
export var getInfoModalProps = function () {
    return {
        modalClassName: "info-modal"
    };
};
