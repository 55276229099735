var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Select from "../Select";
import { useSelector } from "react-redux";
import { selectAppLanguages, selectLocaleLanguage } from "@adm-media/adam-store-plugin";
import { useChangeAppLocale } from "../../../../hooks/useChangeAppLocale";
import classNames from "classnames";
import "./SelectLang.scss";
var SelectLang = function (_a) {
    var className = _a.className, _b = _a.noGlobalUpdate, noGlobalUpdate = _b === void 0 ? false : _b, size = _a.size, props = __rest(_a, ["className", "noGlobalUpdate", "size"]);
    var currentLocale = useSelector(selectLocaleLanguage);
    var languages = useSelector(selectAppLanguages);
    var changeAppLocale = useChangeAppLocale().changeAppLocale;
    var handleLangChange = function (value) {
        changeAppLocale({
            newLocale: value
        });
    };
    return (_jsx(Select, __assign({ "data-testid": "language-selector", className: classNames("select-language", className), value: currentLocale, onChange: function (value) {
            if (noGlobalUpdate)
                return;
            handleLangChange(value);
        }, popupMatchSelectWidth: false, defaultValue: currentLocale, size: size }, props, { children: languages.map(function (el) { return (_jsx(Select.Option, __assign({ value: el.id }, { children: el.label }), el.id)); }) })));
};
export default SelectLang;
