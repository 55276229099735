var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { selectAppModalLogo } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import Col from "../../ui/Col/Col";
import QuizForm from "../../ui/QuizForm/QuizForm";
import "./QuizModal.scss";
export var QuizModal = function () {
    var modalLogo = useSelector(selectAppModalLogo);
    return (_jsx("div", __assign({ className: "quiz-modal__body" }, { children: _jsx(Col, __assign({ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24, className: "quiz-modal__form" }, { children: _jsx(QuizForm, {}) })) })));
};
export var getQuizModalProps = function () {
    return {};
};
