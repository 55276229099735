var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LogoutButton } from "../components/ui/Buttons/LogoutButton/LogoutButton";
import { addSelectedQuestion, removeSelectedQuestion, updateQuestionThunk, deleteQuestionThunk } from "@adm-media/adam-store-plugin";
import Navbar from "../components/blocks/Navbar/Navbar";
import { QuestionsBoard } from "../components/functional/QuestionsBoard/QuestionsBoard";
import { useNotification } from "../hooks/useNotification";
import { useAppDispatch } from "../store";
export var ModeratorPage = function () {
    var dispatch = useAppDispatch();
    var notification = useNotification().notification;
    var cols = [{ title: "Questions from users" }, { title: "Selected questions" }, { title: "Sent to chairman" }];
    var handleSelectQuestion = function (id) {
        dispatch(addSelectedQuestion(id));
        notification.info({ message: "Question selected" });
    };
    var handleCancelQuestionSelection = function (id) {
        dispatch(removeSelectedQuestion(id));
        notification.info({ message: "Question unselected" });
    };
    var handleSubmit = function (payload) {
        dispatch(updateQuestionThunk({
            questionId: payload.id,
            edited_content: payload.edited_content,
            confirmed: payload.confirmed,
            stream_id: payload.stream_id,
            stream_name: payload.stream_name,
            episode_id: payload.episode_id,
            original_content: payload.original_content
        }))
            .unwrap()
            .then(function () {
            notification.success({ message: "Question sent to chairman" });
        });
    };
    var handleDeleteQuestion = function (questionId) {
        dispatch(deleteQuestionThunk(questionId))
            .unwrap()
            .then(function () {
            notification.success({ message: "Question deleted" });
        });
    };
    return (_jsxs("div", __assign({ style: { display: "flex", flexDirection: "column", height: "100vh" } }, { children: [_jsx(Navbar, { actions: [_jsx(LogoutButton, { className: "navbar-logout-button" }, "logout")], verticalSpacing: "small" }), _jsx(QuestionsBoard, { onSelect: handleSelectQuestion, onCancelSelection: handleCancelQuestionSelection, onSubmit: handleSubmit, cols: cols, withAddNew: true, onDeleteQuestion: handleDeleteQuestion })] })));
};
