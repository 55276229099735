var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { selectFAQContent } from "@adm-media/adam-store-plugin";
import Collapse from "../../ui/Collapse/Collapse";
import "./FAQModal.scss";
export var FAQModal = function (_a) {
    var props = __rest(_a, []);
    var faqs = useSelector(selectFAQContent);
    var getItems = function (faq, index) {
        var items = [
            { key: index, label: faq.question, children: _jsx("div", { dangerouslySetInnerHTML: { __html: faq.answer } }) }
        ];
        return items;
    };
    return (_jsx("div", __assign({ className: "content", "data-testid": "faqs-modal" }, { children: faqs.map(function (faq, i) { return (_jsx(Collapse, { ghost: true, className: "collapse", expandIconPosition: "end", items: getItems(faq, i) }, i)); }) })));
};
export var getFAQModalProps = function () {
    return {
        modalClassName: "faq-modal"
    };
};
