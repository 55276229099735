export var NEW_NOTIFICATION_PUSHER_EVENT = "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated";
export var subscribeToPusherNotificationChannel = function (_a) {
    var pusherSingleton = _a.pusherSingleton, subscriberId = _a.subscriberId, callback = _a.callback;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        if (pusherSingleton) {
            fetch(process.env.PUSHER_BASE_URL + "broadcasting/auth/notification-channel", { method: "POST" });
            var channel = pusherSingleton.subscribe("private-subscribers.".concat(subscriberId));
            channel.bind(NEW_NOTIFICATION_PUSHER_EVENT, function (notification) {
                callback(notification);
            });
            channel.unsubscribe = function () { };
            return channel;
        }
        else
            return null;
    }
    else {
        if (pusherSingleton) {
            try {
                var channel = pusherSingleton.subscribe("private-subscribers.".concat(subscriberId));
                channel.bind(NEW_NOTIFICATION_PUSHER_EVENT, function (notification) {
                    callback(notification);
                });
                return channel;
            }
            catch (e) {
                console.warn("Error while trying to connect to pusher notification channel: ", e);
                return null;
            }
        }
        else {
            return null;
        }
    }
};
