var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as AntModal, Divider, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { Logo } from "../Logo/Logo";
import { useSelector } from "react-redux";
import { selectAppContent, selectMedia } from "@adm-media/adam-store-plugin";
import { SanitizeHtml } from "../SanitizeHtml/SanitizeHtml";
import "./Modal.scss";
var CustomCloseIcon = function () { return (_jsx("div", __assign({ className: "default-modal__custom-close-icon" }, { children: _jsx(CloseOutlined, {}) }))); };
var Modal = function (_a) {
    var _b = _a.destroyModal, destroyModal = _b === void 0 ? true : _b, _c = _a.destroyOnClose, destroyOnClose = _c === void 0 ? true : _c, _d = _a.footer, footer = _d === void 0 ? null : _d, children = _a.children, props = __rest(_a, ["destroyModal", "destroyOnClose", "footer", "children"]);
    var _e = useSelector(selectMedia), popup_logo_desktop = _e.popup_logo_desktop, popup_logo_mobile = _e.popup_logo_mobile, header_logo_desktop = _e.header_logo_desktop, header_logo_mobile = _e.header_logo_mobile;
    var contents = useSelector(selectAppContent);
    var getModalFooter = function () {
        if (!!contents.default_modal_footer && footer === null)
            return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(SanitizeHtml, { stringToSanitize: contents.default_modal_footer, tag: "div" })] }));
        if (!contents.default_modal_footer && footer === null)
            return null;
        if (!contents.default_modal_footer && footer)
            return _jsx("div", __assign({ style: { paddingTop: 10 } }, { children: footer }));
        if (contents.default_modal_footer && footer)
            return (_jsx(_Fragment, { children: _jsxs(Space, __assign({ direction: "vertical", style: { width: "100%" } }, { children: [_jsx("div", __assign({ style: { paddingTop: 10 } }, { children: footer })), _jsx(Divider, {}), _jsx(SanitizeHtml, { stringToSanitize: contents.default_modal_footer, tag: "div" })] })) }));
    };
    var modalLogo = !(popup_logo_mobile === null || popup_logo_mobile === void 0 ? void 0 : popup_logo_mobile.url) && !(popup_logo_desktop === null || popup_logo_desktop === void 0 ? void 0 : popup_logo_desktop.url)
        ? (header_logo_desktop === null || header_logo_desktop === void 0 ? void 0 : header_logo_desktop.url) || (header_logo_mobile === null || header_logo_mobile === void 0 ? void 0 : header_logo_mobile.url)
        : (popup_logo_desktop === null || popup_logo_desktop === void 0 ? void 0 : popup_logo_desktop.url) || (popup_logo_mobile === null || popup_logo_mobile === void 0 ? void 0 : popup_logo_mobile.url);
    return (_jsxs(AntModal, __assign({ closeIcon: _jsx(CustomCloseIcon, {}), destroyOnClose: destroyModal ? destroyModal : destroyOnClose, footer: getModalFooter(), style: props.bodyStyle, className: "default-modal ".concat(props.modalClassName), keyboard: false, centered: true }, props, { children: [!props.noLogo && (_jsx("div", __assign({ className: "default-modal__logo" }, { children: _jsx(Logo, { src: modalLogo }) }))), _jsx("div", __assign({ "data-testid": "modal-body", className: classNames(props.modalBodyClassName) }, { children: children }))] })));
};
export default Modal;
