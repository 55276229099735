var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Row from "../../ui/Row/Row";
import Col from "../../ui/Col/Col";
import Tabs from "../../ui/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { selectEpisodes, selectPublicConfig, selectSelectedEpisodesGroupTab, setSelectedEpisodesGroupTab } from "@adm-media/adam-store-plugin";
import Card from "../../ui/Card/Card";
import { useSetCurrentEpisode } from "../../../hooks/useSetCurrentEpisode";
import "./EpisodesGroupsTabs.scss";
import classNames from "classnames";
import { rowGutter } from "../../blocks/configs";
import Typography from "../../ui/Typography/Typography";
import SelectEpisodeCard from "../SelectEpisodeCard/SelectEpisodeCard";
var Meta = Card.Meta;
export var EpisodesGroupsTabs = function (_a) {
    var _b, _c;
    var playerChannelAction = _a.playerChannelAction;
    var dispatch = useDispatch();
    var publicConfig = useSelector(selectPublicConfig);
    var episodes = useSelector(selectEpisodes);
    var setCurrentEpisode = useSetCurrentEpisode().setCurrentEpisode;
    var selectedEpisodeGroupTab = useSelector(selectSelectedEpisodesGroupTab);
    var availableEpisodesIds = episodes.map(function (episode) { return episode.id; });
    var baseClass = "episodes-groups-tabs";
    return (_jsxs("div", __assign({ "data-testid": "episode-proposal-episodes-groups" }, { children: [_jsx("div", __assign({ className: classNames(baseClass, "".concat(baseClass, "--desktop-view")) }, { children: _jsx(Row, __assign({ gutter: rowGutter, "data-testid": "episodes-groups-columns" }, { children: (_b = publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.episodesGroups) === null || _b === void 0 ? void 0 : _b.map(function (episodeGroup) {
                        var _a, _b;
                        var parentEpisode = episodes.find(function (episode) { return episode.id === episodeGroup.parentEpisodeId; });
                        var isChildrenAvailable = !!((_a = episodeGroup === null || episodeGroup === void 0 ? void 0 : episodeGroup.childrenEpisodes) === null || _a === void 0 ? void 0 : _a.find(function (childrenEpisode) {
                            return availableEpisodesIds.includes(childrenEpisode.id);
                        }));
                        var episodeLabel = (parentEpisode === null || parentEpisode === void 0 ? void 0 : parentEpisode.dropdown_label) || (parentEpisode === null || parentEpisode === void 0 ? void 0 : parentEpisode.title);
                        return (_jsxs(Col, __assign({ xs: 12, style: { height: "fit-content" } }, { children: [_jsx(Typography.Title, __assign({ level: 2, className: "".concat(baseClass, "__column-title"), "data-testid": "group-parent-episode-title" }, { children: episodeLabel })), _jsx("div", __assign({ className: "".concat(baseClass, "__desktop-parent-column") }, { children: _jsx(Row, __assign({ gutter: rowGutter }, { children: !isChildrenAvailable && !!parentEpisode ? (_jsx(Col, __assign({ xs: 24, "data-testid": "episode-group-single-parent-selection-card", style: { display: "flex" }, onClick: function () { return setCurrentEpisode({ episodeId: parentEpisode.id }); } }, { children: _jsx(SelectEpisodeCard, { episode: parentEpisode, playerChannelAction: playerChannelAction }) }), parentEpisode.id)) : ((_b = episodeGroup.childrenEpisodes) === null || _b === void 0 ? void 0 : _b.map(function (childrenEpisode) {
                                            var episodeToRender = episodes.find(function (episode) { return episode.id === childrenEpisode.id; });
                                            if (!episodeToRender)
                                                return null;
                                            else {
                                                return (_jsx(Col, __assign({ xs: 24, "data-testid": "child-episode-selection-card", style: { display: "flex" }, onClick: function () { return setCurrentEpisode({ episodeId: childrenEpisode.id }); } }, { children: _jsx(Card, __assign({ style: { width: "100%", maxWidth: "100%" }, hoverable: true, cover: _jsx("div", { style: {
                                                                backgroundImage: "url(".concat(childrenEpisode.thumbnail, ")")
                                                            }, className: "".concat(baseClass, "__selection-card-cover") }) }, { children: _jsx(Meta, { title: episodeToRender.title }) })) }), childrenEpisode.id));
                                            }
                                        })) })) }))] }), String(episodeGroup.parentEpisodeId)));
                    }) })) })), _jsx("div", __assign({ className: classNames(baseClass, "".concat(baseClass, "--mobile-view")) }, { children: _jsx(Tabs, { "data-testid": "episodes-groups-tabs", activeKey: selectedEpisodeGroupTab, onChange: function (newActiveKey) { return dispatch(setSelectedEpisodesGroupTab(newActiveKey)); }, items: (_c = publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.episodesGroups) === null || _c === void 0 ? void 0 : _c.map(function (episodeGroup) {
                        var _a, _b;
                        var parentEpisode = episodes.find(function (episode) { return episode.id === episodeGroup.parentEpisodeId; });
                        var isChildrenAvailable = !!((_a = episodeGroup === null || episodeGroup === void 0 ? void 0 : episodeGroup.childrenEpisodes) === null || _a === void 0 ? void 0 : _a.find(function (childrenEpisode) {
                            return availableEpisodesIds.includes(childrenEpisode.id);
                        }));
                        var episodeLabel = (parentEpisode === null || parentEpisode === void 0 ? void 0 : parentEpisode.dropdown_label) || (parentEpisode === null || parentEpisode === void 0 ? void 0 : parentEpisode.title);
                        return {
                            key: String(episodeGroup.parentEpisodeId),
                            label: episodeLabel,
                            forceRender: false,
                            children: (_jsx(Row, __assign({ gutter: [24, 24] }, { children: !isChildrenAvailable && !!parentEpisode ? (_jsx(Col, __assign({ xs: 24, "data-testid": "episode-group-single-parent-selection-card-in-tab", style: { display: "flex" }, onClick: function () { return setCurrentEpisode({ episodeId: parentEpisode.id }); } }, { children: _jsx(SelectEpisodeCard, { episode: parentEpisode, playerChannelAction: playerChannelAction }) }), parentEpisode.id)) : ((_b = episodeGroup.childrenEpisodes) === null || _b === void 0 ? void 0 : _b.map(function (childrenEpisode) {
                                    var episodeToRender = episodes.find(function (episode) { return episode.id === childrenEpisode.id; });
                                    if (!episodeToRender)
                                        return null;
                                    else {
                                        return (_jsx(Col, __assign({ xs: 24, "data-testid": "child-episode-selection-card", style: { display: "flex" }, onClick: function () { return setCurrentEpisode({ episodeId: childrenEpisode.id }); } }, { children: _jsx(Card, __assign({ style: { width: "100%", maxWidth: "100%" }, hoverable: true, cover: _jsx("div", { style: {
                                                        backgroundImage: "url(".concat(childrenEpisode.thumbnail, ")")
                                                    }, className: "".concat(baseClass, "__selection-card-cover") }) }, { children: _jsx(Meta, { title: episodeToRender.title }) })) }), childrenEpisode.id));
                                    }
                                })) })))
                        };
                    }) }) }))] })));
};
