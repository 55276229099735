import { selectCurrentEpisode, selectComprehensiveQuizAnswerStatus } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
export var useShowQuizButton = function () {
    var _a;
    var comprehensiveQuizAnswersStatus = useSelector(selectComprehensiveQuizAnswerStatus);
    var episodeId = useSelector(selectCurrentEpisode).id;
    var _b = useSelector(selectCurrentEpisode), is_quiz_open = _b.is_quiz_open, quiz_categories = _b.quiz_categories;
    var isQuizOpen = is_quiz_open && (quiz_categories === null || quiz_categories === void 0 ? void 0 : quiz_categories.find(function (quizCategory) { var _a; return ((_a = quizCategory === null || quizCategory === void 0 ? void 0 : quizCategory.questions) === null || _a === void 0 ? void 0 : _a.length) > 0; })) !== undefined;
    var isQuizAlreadyAnswered = comprehensiveQuizAnswersStatus && !!((_a = comprehensiveQuizAnswersStatus === null || comprehensiveQuizAnswersStatus === void 0 ? void 0 : comprehensiveQuizAnswersStatus[episodeId]) === null || _a === void 0 ? void 0 : _a.is_passed) ? true : false;
    return { isQuizOpen: isQuizOpen, isQuizAlreadyAnswered: isQuizAlreadyAnswered };
};
