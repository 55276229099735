var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Card from "../Card/Card";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectLocaleLanguage, selectPublicConfig } from "@adm-media/adam-store-plugin";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { SanitizeHtml } from "../SanitizeHtml/SanitizeHtml";
import Typography from "../Typography/Typography";
import { Collapse } from "antd";
import { List } from "../List/List";
import "./AgendaItem.scss";
import { AgendaItemSpeakers } from "./AgendaItemSpeakers/AgendaItemSpeakers";
var Title = Typography.Title;
export var AgendaItem = function (_a) {
    var _b;
    var _c, _d, _e;
    var withAnimation = _a.withAnimation, timeout = _a.timeout, hiddenSpeakersId = _a.hiddenSpeakersId, agendaItem = _a.agendaItem, _f = _a.withBorder, withBorder = _f === void 0 ? false : _f, _g = _a.type, type = _g === void 0 ? "card" : _g, hideAgendaStartTime = _a.hideAgendaStartTime, props = __rest(_a, ["withAnimation", "timeout", "hiddenSpeakersId", "agendaItem", "withBorder", "type", "hideAgendaStartTime"]);
    var baseClass = "agenda-card";
    var publicConfigs = useSelector(selectPublicConfig);
    var locale = useSelector(selectLocaleLanguage);
    var ItemWrapper = type === "card" ? Card : "div";
    var speakersToShow = hiddenSpeakersId
        ? (_c = agendaItem === null || agendaItem === void 0 ? void 0 : agendaItem.speakers) === null || _c === void 0 ? void 0 : _c.filter(function (speaker) { return !(hiddenSpeakersId === null || hiddenSpeakersId === void 0 ? void 0 : hiddenSpeakersId.includes(speaker.id)); })
        : agendaItem === null || agendaItem === void 0 ? void 0 : agendaItem.speakers;
    var agendaHasChildren = Array.isArray(agendaItem.children) && agendaItem.children.length > 0;
    var agendaDurationLabel = hideAgendaStartTime ? agendaItem.label : "(".concat(agendaItem.label, ")");
    var speakersGroupsConfigs = locale
        ? ((_e = (_d = publicConfigs === null || publicConfigs === void 0 ? void 0 : publicConfigs.agendaSpeakersGroups) === null || _d === void 0 ? void 0 : _d.find(function (item) { return item.agendaId === agendaItem.id; })) === null || _e === void 0 ? void 0 : _e[locale]) || null
        : null;
    return (_jsx("div", __assign({ className: classNames(baseClass), "data-testid": "agenda-card-container" }, { children: !agendaHasChildren ? (_jsx(ItemWrapper, __assign({ className: classNames("".concat(baseClass, "__content"), "".concat(baseClass, "__content--").concat(type), withAnimation), bordered: type === "card" ? withBorder : undefined }, { children: _jsxs("div", __assign({ className: "".concat(baseClass, "__wrapper"), "data-testid": "agenda-card" }, { children: [type !== "card" && (_jsxs("div", __assign({ className: "".concat(baseClass, "__date-info-wrapper") }, { children: [!!!hideAgendaStartTime && _jsx("div", __assign({ className: "".concat(baseClass, "__time ").concat(baseClass, "__time--card") }, { children: agendaItem.start_at })), agendaItem.label && _jsx("div", __assign({ className: "".concat(baseClass, "__timing") }, { children: agendaDurationLabel }))] }))), _jsxs("div", __assign({ className: "".concat(baseClass, "__texts") }, { children: [_jsx(Title, __assign({ level: props.titleLevel || 4, className: "".concat(baseClass, "__title") }, { children: _jsx(SanitizeHtml, { stringToSanitize: agendaItem.title, tag: "div" }) })), type === "card" && (_jsxs("div", __assign({ className: "".concat(baseClass, "__date-tag") }, { children: [!!!hideAgendaStartTime && _jsxs("span", __assign({ className: "".concat(baseClass, "__time") }, { children: [agendaItem.start_at, " "] })), agendaItem.label && _jsx("span", __assign({ className: "".concat(baseClass, "__timing") }, { children: agendaDurationLabel }))] }))), agendaItem.description && (_jsx(SanitizeHtml, { className: "".concat(baseClass, "__paragraph"), stringToSanitize: agendaItem.description, tag: "div" })), _jsx(AgendaItemSpeakers, { baseClass: baseClass, speakersGroupsConfigs: speakersGroupsConfigs, speakersToShow: speakersToShow })] }))] })) }))) : (_jsx(Collapse, { collapsible: "header", bordered: true, expandIconPosition: "end", expandIcon: function (_a) {
                var isActive = _a.isActive;
                return (!isActive ? _jsx(PlusOutlined, {}) : _jsx(MinusOutlined, {}));
            }, className: classNames("".concat(baseClass, "__collapsible"), (_b = {},
                _b["".concat(baseClass, "__collapsible--card")] = type === "card",
                _b["".concat(baseClass, "__collapsible--list-item")] = type !== "card",
                _b)), items: [
                {
                    key: agendaItem.id,
                    label: (_jsxs("div", __assign({ className: "".concat(baseClass, "__collapsible-header-wrapper"), "data-testid": "agenda-card-nested-header" }, { children: [type !== "card" && (_jsxs("div", __assign({ className: "".concat(baseClass, "__date-info-wrapper") }, { children: [!!!hideAgendaStartTime && (_jsx("div", __assign({ className: "".concat(baseClass, "__time ").concat(baseClass, "__time--card") }, { children: agendaItem.start_at }))), agendaItem.label && _jsx("div", __assign({ className: "".concat(baseClass, "__timing") }, { children: agendaDurationLabel }))] }))), _jsxs("div", __assign({ className: "".concat(baseClass, "__texts") }, { children: [_jsx(Title, __assign({ level: props.titleLevel || 4, className: "".concat(baseClass, "__title") }, { children: _jsx(SanitizeHtml, { stringToSanitize: agendaItem.title, tag: "div" }) })), type === "card" && (_jsxs("div", __assign({ className: "".concat(baseClass, "__date-tag") }, { children: [!!!hideAgendaStartTime && _jsxs("span", __assign({ className: "".concat(baseClass, "__time") }, { children: [agendaItem.start_at, " "] })), agendaItem.label && _jsx("span", __assign({ className: "".concat(baseClass, "__timing") }, { children: agendaDurationLabel }))] }))), agendaItem.description && (_jsx(SanitizeHtml, { className: "".concat(baseClass, "__paragraph"), stringToSanitize: agendaItem.description, tag: "div" }))] }))] }))),
                    children: (_jsx(List, { dataSource: agendaItem.children, renderItem: function (item) {
                            var _a, _b, _c;
                            var speakersToShowChildren = hiddenSpeakersId
                                ? (_a = item === null || item === void 0 ? void 0 : item.speakers) === null || _a === void 0 ? void 0 : _a.filter(function (speaker) { return !(hiddenSpeakersId === null || hiddenSpeakersId === void 0 ? void 0 : hiddenSpeakersId.includes(speaker.id)); })
                                : item === null || item === void 0 ? void 0 : item.speakers;
                            var childAgendaDurationLabel = hideAgendaStartTime ? item.label : "(".concat(item.label, ")");
                            var speakersGroupsConfigsChild = locale
                                ? ((_c = (_b = publicConfigs === null || publicConfigs === void 0 ? void 0 : publicConfigs["agendaSpeakersGroups"]) === null || _b === void 0 ? void 0 : _b.find(function (childItem) { return childItem.agendaId === item.id; })) === null || _c === void 0 ? void 0 : _c[locale]) || null
                                : null;
                            return (_jsxs("div", __assign({ className: "".concat(baseClass, "__nested-item"), "data-testid": "agenda-card-nested-element" }, { children: [_jsx(Title, __assign({ level: 5, className: "".concat(baseClass, "__nested-title") }, { children: _jsx(SanitizeHtml, { stringToSanitize: item.title, tag: "div" }) })), _jsxs("div", __assign({ className: "".concat(baseClass, "__date-tag") }, { children: [!!!hideAgendaStartTime && _jsxs("span", __assign({ className: "".concat(baseClass, "__time") }, { children: [item.start_at, " "] })), item.label && _jsx("span", __assign({ className: "".concat(baseClass, "__timing") }, { children: childAgendaDurationLabel }))] })), item.description && (_jsx(SanitizeHtml, { className: "".concat(baseClass, "__paragraph"), stringToSanitize: item.description, tag: "div" })), _jsx(AgendaItemSpeakers, { baseClass: baseClass, speakersGroupsConfigs: speakersGroupsConfigsChild, speakersToShow: speakersToShowChildren })] })));
                        } }))
                }
            ] })) })));
};
