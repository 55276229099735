var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { logoutUserThunk, passwordChangeThunk, selectAppContent, selectLocaleLanguage, selectPasswordChange, selectUser } from "@adm-media/adam-store-plugin";
import { Button, Form, Input } from "antd";
import { LoadingOutlinedIcon } from "../../ui/Icons/Icons";
import { mapErrorsToFields } from "../../../utils/map-errors-to-fields";
import { useAppDispatch } from "../../../store";
import { useNotification } from "../../../hooks/useNotification";
import { useSelector } from "react-redux";
import Typography from "../../ui/Typography/Typography";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
var Title = Typography.Title;
export var ChangePassword = function (_a) {
    var onSuccessCallback = _a.onSuccessCallback;
    var form = Form.useForm()[0];
    var dispatch = useAppDispatch();
    var notification = useNotification().notification;
    var contents = useSelector(selectAppContent);
    var currentLocale = useSelector(selectLocaleLanguage);
    var loadingPasswordChange = useSelector(selectPasswordChange).loading;
    var change_password_required = useSelector(selectUser).identity.change_password_required;
    var handleSubmitForm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(passwordChangeThunk({
                old_password: change_password_required !== 1 ? values.old_password : "",
                password: values.password,
                password_confirmation: values.password_confirmation,
                lang_id: currentLocale
            }))
                .unwrap()
                .then(function () {
                dispatch(logoutUserThunk())
                    .unwrap()
                    .catch(function () { })
                    .finally(function () {
                    onSuccessCallback && onSuccessCallback();
                });
            })
                .catch(function (error) {
                if ((error === null || error === void 0 ? void 0 : error.status) === 422) {
                    var valuesWithErrors = mapErrorsToFields({ error: error.data, values: values });
                    form.setFields(valuesWithErrors);
                    notification.showGenericError();
                }
                else {
                    notification.showGenericError();
                    form.resetFields();
                }
            });
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(Title, __assign({ level: 3 }, { children: contents.change_password || "Change passsword" })), contents.change_password_description && _jsx(SanitizeHtml, { stringToSanitize: contents.change_password_description, tag: "div" }), _jsxs(Form, __assign({ form: form, "data-testid": "forced-change-password-form", layout: "vertical", onFinish: handleSubmitForm }, { children: [change_password_required !== 1 && (_jsx(Form.Item, __assign({ name: "old_password", "data-testid": "change-password-old-password-field", label: contents.old_password || "Old Password", rules: [
                            {
                                required: true,
                                message: contents.field_required
                            }
                        ] }, { children: _jsx(Input.Password, {}) }))), _jsx(Form.Item, __assign({ name: "password", "data-testid": "change-password-password-field", label: contents.password || "Password", rules: [
                            {
                                required: true,
                                message: contents.field_required
                            }
                        ] }, { children: _jsx(Input.Password, {}) })), _jsx(Form.Item, __assign({ name: "password_confirmation", "data-testid": "change-password-password-confirmation-field", label: contents.password_confirmation || "Confirm password", rules: [
                            {
                                required: true,
                                message: contents.field_required
                            },
                            function (_a) {
                                var getFieldValue = _a.getFieldValue;
                                return ({
                                    validator: function (_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Passwords do not match"));
                                    }
                                });
                            }
                        ] }, { children: _jsx(Input.Password, {}) })), _jsx(Form.Item, { children: _jsxs(Button, __assign({ "data-testid": "forced-change-password-submit-button", htmlType: "submit", type: "primary" }, { children: [loadingPasswordChange === "pending" && _jsx(LoadingOutlinedIcon, {}), contents.change_password || "Change password"] })) })] }))] }));
};
export var getChangePasswordModalProps = function () {
    return {
        maskClosable: false,
        closable: false,
        footer: null
    };
};
