import { selectEpisodes, selectPublicConfig } from "@adm-media/adam-store-plugin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
export var useEpisodesToShowInHomepage = function () {
    var publicConfig = useSelector(selectPublicConfig);
    var episodes = useSelector(selectEpisodes);
    var _a = useState([]), episodesToShowInHomepage = _a[0], setEpisodesToShowInHomepage = _a[1];
    useEffect(function () {
        var _a, _b;
        var episodesIdsToShow = typeof ((_a = publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.homepageAgendaEpisodesIds) === null || _a === void 0 ? void 0 : _a.length) === "number" && ((_b = publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.homepageAgendaEpisodesIds) === null || _b === void 0 ? void 0 : _b.length) > 0
            ? publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.homepageAgendaEpisodesIds
            : null;
        var episodesToShow = episodes.filter(function (episode) {
            if (episodesIdsToShow === null)
                return true;
            else
                return episodesIdsToShow.includes(episode.id);
        });
        setEpisodesToShowInHomepage(episodesToShow);
    }, [episodes, publicConfig]);
    return { episodesToShowInHomepage: episodesToShowInHomepage };
};
