import { useEffect, useState } from "react";
export function useIntersectionObserver(elementRef, _a) {
    var _b = _a.threshold, threshold = _b === void 0 ? 0.3 : _b, _c = _a.root, root = _c === void 0 ? null : _c, _d = _a.rootMargin, rootMargin = _d === void 0 ? "0%" : _d, _e = _a.freezeOnceVisible, freezeOnceVisible = _e === void 0 ? false : _e;
    var elementThreshold = 0.05;
    var _f = useState(), entry = _f[0], setEntry = _f[1];
    var frozen = (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && freezeOnceVisible;
    var updateEntry = function (_a) {
        var entry = _a[0];
        setEntry(entry);
    };
    useEffect(function () {
        var node = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current; // DOM Ref
        var hasIOSupport = !!window.IntersectionObserver;
        if (!hasIOSupport || frozen || !node)
            return;
        var observerParams = { threshold: elementThreshold, root: root, rootMargin: rootMargin };
        var observer = new IntersectionObserver(updateEntry, observerParams);
        observer.observe(node);
        return function () { return observer.disconnect(); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef === null || elementRef === void 0 ? void 0 : elementRef.current, JSON.stringify(elementThreshold), root, rootMargin, frozen]);
    return entry;
}
