import { useEffect, useState } from "react";
export var useMutationObservable = function (targetEl, mutationCallback) {
    var _a = useState(null), observer = _a[0], setObserver = _a[1];
    useEffect(function () {
        var obs = new MutationObserver(mutationCallback);
        setObserver(obs);
    }, [mutationCallback, setObserver]);
    useEffect(function () {
        if (!observer || !targetEl)
            return;
        observer.observe(targetEl, {
            attributes: true,
            attributeFilter: ["class"],
            childList: false,
            characterData: false
        });
        return function () {
            if (observer) {
                try {
                    observer.disconnect();
                }
                catch (_a) {
                    console.warn("Failed to disconnect observer");
                }
            }
        };
    }, [observer, targetEl]);
};
