import _map from "lodash/map";
var responseErrorList = [401, 422, 500];
var convertTimezonesToAntdOptionList = function (array) {
    return _map(array, function (element) {
        return { key: element.id, value: element.id, label: "".concat(element.id, " (").concat(element.abbreviation, ")") };
    });
};
var findCountry = function (countryCode, countries) {
    return countries.find(function (country) { return country.id === countryCode; });
};
export { responseErrorList, convertTimezonesToAntdOptionList, findCountry };
