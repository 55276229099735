var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { displayModal, hideModal, logoutUserThunk, selectAppContent } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import Button from "../../ui/Buttons/Button";
import Space from "../../ui/Space/Space";
import { useAppDispatch } from "../../../store";
import { EVALUATION_MODAL, FORCE_EVALUATION_FORM_MODAL } from "..";
import Divider from "../../ui/Divider/Divider";
export var ForceEvaluationFormModal = function () {
    var dispatch = useAppDispatch();
    var contents = useSelector(selectAppContent);
    var handleLogoutChoice = function () {
        dispatch(logoutUserThunk());
        dispatch(hideModal({ name: FORCE_EVALUATION_FORM_MODAL }));
    };
    var handleShowEvaluationFormChoice = function () {
        dispatch(displayModal({ name: EVALUATION_MODAL }));
        dispatch(hideModal({ name: FORCE_EVALUATION_FORM_MODAL }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(SanitizeHtml, { stringToSanitize: contents.force_ef_popup_content, tag: "div" }), _jsx(Divider, {}), _jsxs(Space, __assign({ direction: "horizontal", align: "end" }, { children: [_jsx(Button, __assign({ onClick: handleLogoutChoice }, { children: contents.force_ef_popup_deny_button_text })), _jsx(Button, __assign({ onClick: handleShowEvaluationFormChoice, type: "primary" }, { children: contents.force_ef_popup_confirm_button_text }))] }))] }));
};
export var getForceEvaluationFormModalProps = function () {
    return {};
};
