import Pusher from "pusher-js";
//https://pusher.com/docs/channels/getting_started/javascript/
export var pusherInitUnauthenticated = function () {
    return new Pusher(process.env.PUSHER_APP_KEY || "", {
        cluster: process.env.PUSHER_APP_CLUSTER,
        forceTLS: true,
        authEndpoint: process.env.PUSHER_BASE_URL + "broadcasting/auth",
        pongTimeout: 6000,
        activityTimeout: 30000
    });
};
