import { selectChapterTimeSelection, selectPlayerSource, setChapterTimeSelection } from "@adm-media/adam-store-plugin";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { VOD } from "../types/player";
import { useAppDispatch } from "../store";
export var useSetChapterTimeSelection = function (_a) {
    var playerRef = _a.playerRef;
    var dispatch = useAppDispatch();
    var playerSource = useSelector(selectPlayerSource);
    var chapterTimeSelection = useSelector(selectChapterTimeSelection);
    useEffect(function () {
        if (chapterTimeSelection !== null && playerRef && playerSource.status === VOD) {
            if (playerRef.duration > chapterTimeSelection) {
                playerRef.currentTime = chapterTimeSelection;
                playerRef.play();
            }
            dispatch(setChapterTimeSelection(null));
        }
        else {
            dispatch(setChapterTimeSelection(null));
        }
    }, [chapterTimeSelection, playerRef, playerSource.status]);
};
