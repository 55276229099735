var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Flex, Space, Typography } from "antd";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./NotificationItem.scss";
import { useAppDispatch } from "../../../../store";
import { addReadNotificationId, markNotificationAsReadThunk, selectReadNotificationsIds } from "@adm-media/adam-store-plugin";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export var NotificationItem = function (_a) {
    var title = _a.title, description = _a.description, id = _a.id, url = _a.url;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var readNotificationsIds = useSelector(selectReadNotificationsIds);
    var baseClass = "notification-item";
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var handleNotificationClick = function (notificationId) {
        setIsLoading(true);
        dispatch(markNotificationAsReadThunk({ notificationId: notificationId }))
            .unwrap()
            .then(function () {
            dispatch(addReadNotificationId(notificationId));
        })
            .catch(function (e) { return console.warn(e); })
            .finally(function () {
            setIsLoading(false);
            if (url === null || url === void 0 ? void 0 : url.startsWith("#"))
                navigate(url);
        });
    };
    return (_jsxs(Flex, __assign({ onClick: function (e) {
            e.stopPropagation();
            if (!isLoading && !readNotificationsIds.includes(id))
                handleNotificationClick(id);
        }, gap: 15, className: baseClass, align: "center", justify: "space-between", style: !readNotificationsIds.includes(id) ? { cursor: "pointer" } : undefined, "data-testid": "notification-item" }, { children: [_jsxs(Space, __assign({ direction: "vertical" }, { children: [_jsx(Typography.Title, __assign({ style: { margin: 0 }, level: 5 }, { children: title })), _jsx(Typography.Text, { children: description })] })), !isLoading && !readNotificationsIds.includes(id) && _jsx(Badge, { dot: true }), isLoading && _jsx(LoadingOutlined, { size: 9 })] })));
};
