// UNKNOWN
export var UNKNOWN = 99;
// LIVE ONLY
export var PRE_LIVE = 101;
export var LIVE = 121;
// LIVE & VOD
export var BUFFER = 202;
export var BYE = 262;
// VOD ONLY
export var PRE_VOD = 303;
export var VOD = 323;
export var POST_VOD = 343;
// PLAY - PAUSE
export var PLAY = "play";
export var PAUSE = "pause";
export var UPDATE = "update";
export var PlayerReadyState;
(function (PlayerReadyState) {
    PlayerReadyState[PlayerReadyState["HAVE_NOTHING"] = 1] = "HAVE_NOTHING";
    PlayerReadyState[PlayerReadyState["HAVE_METADATA"] = 2] = "HAVE_METADATA";
    PlayerReadyState[PlayerReadyState["HAVE_CURRENT_DATA"] = 3] = "HAVE_CURRENT_DATA";
    PlayerReadyState[PlayerReadyState["HAVE_FUTURE_DATA"] = 4] = "HAVE_FUTURE_DATA";
    PlayerReadyState[PlayerReadyState["HAVE_ENOUGH_DATA"] = 5] = "HAVE_ENOUGH_DATA"; // The player has enough data available, and the download rate is high enough that the media can be played through to the end without interruption.
})(PlayerReadyState || (PlayerReadyState = {}));
