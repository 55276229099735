var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { selectAppLanguages, selectEpisodes, selectLoadingDetect } from "@adm-media/adam-store-plugin";
import { useNavigate } from "react-router";
import Navbar from "../../components/blocks/Navbar/Navbar";
import Footer from "../../components/blocks/Footer/Footer";
import Row from "../../components/ui/Row/Row";
import { PublicQuestion } from "../../components/blocks/PublicQuestion/PublicQuestion";
import SelectLang from "../../components/ui/Select/SelectLang/SelectLang";
import { useEffect } from "react";
import "./PublicQandA.scss";
export var PublicQandAPage = function () {
    var navigate = useNavigate();
    var loadingDetect = useSelector(selectLoadingDetect);
    var availableLanguages = useSelector(selectAppLanguages);
    var episodes = useSelector(selectEpisodes);
    var baseClass = "public-qanda";
    useEffect(function () {
        var episodesWithPublicQandA = episodes.filter(function (episode) { return !!episode.is_public_qaa_open; });
        if (episodes.length > 0 && episodesWithPublicQandA.length === 0)
            navigate("/");
    }, [episodes]);
    var actions = [];
    if ((availableLanguages === null || availableLanguages === void 0 ? void 0 : availableLanguages.length) > 1)
        actions = __spreadArray([_jsx(SelectLang, {}, "withSelectLang")], actions, true);
    if (loadingDetect === "uninitialized" || loadingDetect === "pending")
        return null;
    return (_jsxs(Row, __assign({ "data-testid": "public-qanda", className: baseClass }, { children: [_jsx(Navbar, { verticalSpacing: "small", actions: actions }), _jsx(PublicQuestion, { className: "".concat(baseClass, "__question"), noNavbarElements: true }), _jsx(Footer, {})] })));
};
