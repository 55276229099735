var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { selectCurrentEpisode, selectUser } from "@adm-media/adam-store-plugin";
import { JaaSMeeting } from "@jitsi/react-sdk";
import { useSelector } from "react-redux";
import { Spin } from "../../ui/Spin/Spin";
import { useEffect, useState } from "react";
import sha256 from "crypto-js/sha256";
import { useNotification } from "../../../hooks/useNotification";
import "./JitsiMeeting.scss";
export var JitsiMeeting = function (_a) {
    var conferenceId = _a.conferenceId, onReadyToClose = _a.onReadyToClose;
    var _b = useSelector(selectCurrentEpisode), room_name = _b.room_name, channel = _b.channel;
    var notification = useNotification().notification;
    var identity = useSelector(selectUser).identity;
    var _c = useState(null), meetingConfig = _c[0], setMeetingConfig = _c[1];
    useEffect(function () {
        var _a, _b;
        if (channel && identity && meetingConfig === null) {
            var defaultToolbarButtons_1 = [
                "camera",
                "microphone",
                "filmstrip",
                "fullscreen",
                "hangup",
                "help",
                "highlight",
                "noisesuppression",
                "profile",
                "raisehand",
                "select-background",
                "settings",
                "shortcuts",
                "tileview",
                "toggle-camera",
                "videoquality",
                "chat",
                "security",
                "whiteboard"
            ];
            if (identity.is_chairman) {
                ["recording", "sharedvideo", "desktop", "shareaudio", "livestreaming", "participants-pane"].forEach(function (item) {
                    defaultToolbarButtons_1.push(item);
                });
            }
            var baseConfig = {
                remoteVideoMenu: {
                    disabled: false,
                    disablePrivateChat: true
                },
                apiLogLevels: ["error"],
                disableThirdPartyRequests: true,
                disableLocalVideoFlip: true,
                backgroundAlpha: 0.5,
                disableInviteFunctions: true,
                disablePolls: false,
                hiddenPremeetingButtons: ["invite"],
                mobileAppPromo: false,
                toolbarButtons: defaultToolbarButtons_1
            };
            if (identity.is_chairman) {
                var moderatorConfig = ((_a = channel.conference_config) === null || _a === void 0 ? void 0 : _a.moderator) || {};
                setMeetingConfig(__assign(__assign({}, baseConfig), moderatorConfig));
            }
            else {
                var attendeeConfig = ((_b = channel.conference_config) === null || _b === void 0 ? void 0 : _b.attendee) || {};
                setMeetingConfig(__assign(__assign({}, baseConfig), attendeeConfig));
            }
        }
    }, [channel, identity, meetingConfig]);
    var _d = useState(false), isLoadingJitsiToken = _d[0], setIsLoadingJitsiToken = _d[1];
    var _e = useState(null), jitsiToken = _e[0], setJitsiToken = _e[1];
    var getToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payloadString, sha256Hash, headerUserId, response, responseData, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payloadString = JSON.stringify({
                        name: identity === null || identity === void 0 ? void 0 : identity.firstname,
                        email: identity === null || identity === void 0 ? void 0 : identity.email,
                        id: identity === null || identity === void 0 ? void 0 : identity.id
                    });
                    sha256Hash = sha256(payloadString);
                    headerUserId = String(identity.is_chairman ? identity === null || identity === void 0 ? void 0 : identity.id : (identity === null || identity === void 0 ? void 0 : identity.id) + 1).padStart(7, "0");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    setIsLoadingJitsiToken(true);
                    return [4 /*yield*/, fetch(process.env.FETCH_JITSI_TOKEN_URL, {
                            // `https://utils.adam-webcast.com/conference`
                            method: "POST",
                            credentials: "same-origin",
                            headers: {
                                Accept: "application/json",
                                Origin: location.protocol + "//" + location.host,
                                "X-ADaM": headerUserId,
                                "x-amz-content-sha256": sha256Hash.toString()
                            },
                            body: payloadString
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    responseData = _a.sent();
                    setJitsiToken(responseData.token);
                    return [3 /*break*/, 6];
                case 4:
                    e_1 = _a.sent();
                    notification.showGenericError();
                    return [3 /*break*/, 6];
                case 5:
                    setIsLoadingJitsiToken(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getToken();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Spin, { fullPage: true, className: "jitsi-meeting-loading-spinner", spinning: isLoadingJitsiToken }), jitsiToken && conferenceId && meetingConfig !== null && (_jsx(JaaSMeeting, { appId: conferenceId, roomName: room_name || "Conference room", "data-testid": "jitsi-meeting-component", jwt: jitsiToken, configOverwrite: meetingConfig, onReadyToClose: onReadyToClose }))] }));
};
