import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import render from "./render";
/**
 * Layout directly imported in Homepage
 * @nodes JSON Layout
 * @returns
 */
export var Layout = function (_a) {
    var nodes = _a.nodes;
    return _jsx(_Fragment, { children: render(nodes) });
};
