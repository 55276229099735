var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { selectStyles, selectMedia } from "@adm-media/adam-store-plugin";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./GlobalStyles";
import { defaultAntdFontFamily } from "./utils/fonts";
var StyleWrapper = function (_a) {
    var children = _a.children;
    var media = useSelector(selectMedia);
    var styles = useSelector(selectStyles);
    // Unify styles and media
    var builtTheme = __assign(__assign({}, styles), media);
    var getParsedDimension = function (dimensionInString) {
        if (!dimensionInString)
            return null;
        var parsedNumber = parseInt(dimensionInString.replace("px", ""));
        if (Number.isNaN(parsedNumber))
            return null;
        else
            return parsedNumber;
    };
    var token = { colorPrimary: (styles === null || styles === void 0 ? void 0 : styles.primary_color) || "#1677ff" };
    if ((styles === null || styles === void 0 ? void 0 : styles.default_font_family) !== defaultAntdFontFamily)
        token = __assign(__assign({}, token), { fontFamily: (styles === null || styles === void 0 ? void 0 : styles.default_font_family) ? "".concat(styles === null || styles === void 0 ? void 0 : styles.default_font_family, ", Helvetica, Arial, Sans-Serif") : "Helvetica, Arial, Sans-Serif" });
    return (_jsx(ConfigProvider, __assign({ theme: {
            token: __assign(__assign({}, token), { controlHeight: 36 }),
            components: {
                Button: {
                    borderRadius: getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) === null
                        ? 6
                        : getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) || 0,
                    borderRadiusLG: getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) === null
                        ? 6
                        : getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) || 0,
                    controlOutline: undefined,
                    fontWeight: styles.buttons_font_weight || "normal"
                },
                Input: {
                    borderRadius: getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) === null
                        ? 6
                        : getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) || 0,
                    colorText: (styles === null || styles === void 0 ? void 0 : styles.form_input_color) || undefined,
                    colorBgContainer: (styles === null || styles === void 0 ? void 0 : styles.form_input_background_color) || undefined,
                    boxShadow: styles.form_input_shadow ? "".concat(styles.form_input_shadow, " 0 0 5px 1px") : undefined,
                    colorBorder: (styles === null || styles === void 0 ? void 0 : styles.form_input_border_color) || (styles === null || styles === void 0 ? void 0 : styles.default_border_color) || "#95a5a6",
                    colorTextPlaceholder: styles.form_placeholder_color || undefined,
                    hoverBg: (styles === null || styles === void 0 ? void 0 : styles.form_input_background_color) || undefined,
                    activeBg: (styles === null || styles === void 0 ? void 0 : styles.form_input_background_color) || undefined
                },
                Select: {
                    borderRadius: getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) === null
                        ? 6
                        : getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.buttons_default_border_radius) || 0,
                    colorBgContainer: (styles === null || styles === void 0 ? void 0 : styles.form_input_background_color) || undefined,
                    colorTextPlaceholder: styles.form_placeholder_color || undefined,
                    boxShadow: styles.form_input_shadow ? "".concat(styles.form_input_shadow, " 0 0 5px 1px") : undefined,
                    colorBorder: (styles === null || styles === void 0 ? void 0 : styles.form_input_border_color) || (styles === null || styles === void 0 ? void 0 : styles.default_border_color) || "#95a5a6",
                    controlOutline: undefined,
                    selectorBg: (styles === null || styles === void 0 ? void 0 : styles.form_input_background_color) || "white"
                },
                Card: {
                    borderRadiusSM: getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.cards_default_border_radius) || 8,
                    borderRadiusXS: getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.cards_default_border_radius) || 8,
                    borderRadiusLG: getParsedDimension(styles === null || styles === void 0 ? void 0 : styles.cards_default_border_radius) || 8
                }
            }
        } }, { children: _jsxs(ThemeProvider, __assign({ theme: builtTheme }, { children: [_jsx(GlobalStyles, {}), children] })) })));
};
export default StyleWrapper;
