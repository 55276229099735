var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Checkbox, Radio } from "antd";
import { useSelector } from "react-redux";
import { answerQuizQuestionThunk, selectAnswerQuizQuestion, selectCurrentEpisode, selectComprehensiveQuizAnswerStatus, resetAnswerQuizQuestion, selectAppContent } from "@adm-media/adam-store-plugin";
import Form from "../Form/Form";
import Col from "../Col/Col";
import Button from "../Buttons/Button";
import Typography from "../Typography/Typography";
import "./QuizForm.scss";
import Row from "../Row/Row";
import { useAppDispatch } from "../../../store";
import { useNotification } from "../../../hooks/useNotification";
var Title = Typography.Title, Link = Typography.Link;
var QuizForm = function () {
    var _a, _b, _c, _d, _e, _f;
    var dispatch = useAppDispatch();
    var form = Form.useForm()[0];
    var _g = useState(false), isFormHidden = _g[0], setIsFormHidden = _g[1];
    var episodeId = useSelector(selectCurrentEpisode).id;
    var quiz_categories = useSelector(selectCurrentEpisode).quiz_categories;
    var notification = useNotification().notification;
    var contents = useSelector(selectAppContent);
    var loadingAnswerQuestion = useSelector(selectAnswerQuizQuestion).loading;
    var comprehensiveQuizAnswersStatus = useSelector(selectComprehensiveQuizAnswerStatus);
    var handleSubmit = function (values) {
        dispatch(answerQuizQuestionThunk({
            episode_id: episodeId,
            answers: values
        }))
            .unwrap()
            .catch(function () { return notification.showGenericError(); });
    };
    useEffect(function () {
        if (loadingAnswerQuestion === "succeeded") {
            setIsFormHidden(true);
            dispatch(resetAnswerQuizQuestion());
        }
    }, [loadingAnswerQuestion]);
    var isQuizPassed = !!((_a = comprehensiveQuizAnswersStatus === null || comprehensiveQuizAnswersStatus === void 0 ? void 0 : comprehensiveQuizAnswersStatus[episodeId]) === null || _a === void 0 ? void 0 : _a.is_passed);
    var isQuizFailedCanTryAgain = ((!!!((_b = comprehensiveQuizAnswersStatus === null || comprehensiveQuizAnswersStatus === void 0 ? void 0 : comprehensiveQuizAnswersStatus[episodeId]) === null || _b === void 0 ? void 0 : _b.is_passed) && ((_c = comprehensiveQuizAnswersStatus === null || comprehensiveQuizAnswersStatus === void 0 ? void 0 : comprehensiveQuizAnswersStatus[episodeId]) === null || _c === void 0 ? void 0 : _c.available_try) === null) ||
        ((_d = comprehensiveQuizAnswersStatus === null || comprehensiveQuizAnswersStatus === void 0 ? void 0 : comprehensiveQuizAnswersStatus[episodeId]) === null || _d === void 0 ? void 0 : _d.available_try) > 0) &&
        isFormHidden;
    var isQuizFailedFinally = !!!((_e = comprehensiveQuizAnswersStatus === null || comprehensiveQuizAnswersStatus === void 0 ? void 0 : comprehensiveQuizAnswersStatus[episodeId]) === null || _e === void 0 ? void 0 : _e.is_passed) && ((_f = comprehensiveQuizAnswersStatus === null || comprehensiveQuizAnswersStatus === void 0 ? void 0 : comprehensiveQuizAnswersStatus[episodeId]) === null || _f === void 0 ? void 0 : _f.available_try) === 0;
    return (_jsx("div", __assign({ className: "quiz-form" }, { children: isQuizPassed ? (_jsx("div", __assign({ "data-testid": "quiz-passed-message", className: "quiz-form__wrapper-text-output" }, { children: _jsx(Title, __assign({ level: 3, style: { margin: 0 } }, { children: contents.quiz_passed || "Quiz passed, congratulations" })) }))) : isQuizFailedCanTryAgain ? (_jsx("div", __assign({ className: "quiz-form__wrapper-text-output" }, { children: _jsx(Link, __assign({ style: { fontWeight: "bold", cursor: "pointer", textDecoration: "underline" }, onClick: function () {
                    form.resetFields();
                    setIsFormHidden(false);
                } }, { children: contents.quiz_failed_try_again || "Quiz failed, retry" })) }))) : isQuizFailedFinally ? (_jsx("div", __assign({ className: "quiz-form__wrapper-text-output" }, { children: contents.quiz_finally_failed || "Quiz failed, no other attempts are possible at this time" }))) : (_jsx("div", __assign({ "data-testid": "quiz-form" }, { children: _jsxs(Form, __assign({ onFinish: handleSubmit, form: form, layout: "vertical", scrollToFirstError: true }, { children: [quiz_categories === null || quiz_categories === void 0 ? void 0 : quiz_categories.map(function (_a) {
                        var questions = _a.questions, id = _a.id;
                        return (_jsx("div", __assign({ className: "quiz-form__section" }, { children: questions.map(function (_a, index) {
                                var options = _a.options, is_radio = _a.is_radio, is_required = _a.is_required, id = _a.id, content = _a.content, max_allowed_choices = _a.max_allowed_choices, legend = _a.legend;
                                var rules = [];
                                if (is_required)
                                    rules.push({ required: true, message: contents.field_required });
                                if (max_allowed_choices)
                                    rules.push(function () { return ({
                                        validator: function (_, value) {
                                            if (Array.isArray(value) && value.length > max_allowed_choices) {
                                                return Promise.reject(new Error(contents.error_too_many_options_selected ||
                                                    "The number of answers entered exceeds the maximum permitted"));
                                            }
                                            return Promise.resolve();
                                        }
                                    }); });
                                return (_jsx(Form.Item, __assign({ name: id, valuePropName: is_radio ? undefined : "checked", rules: rules, label: _jsx(Title, __assign({ level: 5, style: { marginTop: 15 } }, { children: content })), extra: legend, "data-testid": "single-quiz-question" }, { children: is_radio ? (_jsx(Radio.Group, { children: _jsx(Row, __assign({ gutter: [10, 10] }, { children: Array.isArray(options) &&
                                                options.map(function (option) { return (_jsx(Col, __assign({ xs: 24 }, { children: _jsx(Radio, __assign({ "data-testid": "quiz-option", className: "single-radio", value: option.id }, { children: option.body }), option.id) }), option.id)); }) })) })) : (_jsx(Checkbox.Group, __assign({ name: String(id), style: { width: "100%" } }, { children: _jsx(Row, __assign({ gutter: [10, 10] }, { children: options === null || options === void 0 ? void 0 : options.map(function (option) { return (_jsx(Col, __assign({ xs: 24 }, { children: _jsx(Checkbox, __assign({ "data-testid": "quiz-option", className: "single-radio", value: String(option.id) }, { children: option.body }), option.id) }), option.id)); }) })) }))) }), index));
                            }) }), id));
                    }), _jsx(Button, __assign({ "data-testid": "quiz-submit-button", loading: loadingAnswerQuestion === "pending", type: "primary", htmlType: "submit", className: "quiz-form__submit-button" }, { children: contents.submit_button_text || "Submit" }))] })) }))) })));
};
export default QuizForm;
