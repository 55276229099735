var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import Button from "../../components/ui/Buttons/Button";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import "./ErrorPage.scss";
import { selectAppContent } from "@adm-media/adam-store-plugin";
var ErrorsCode;
(function (ErrorsCode) {
    ErrorsCode["code404"] = "404";
    ErrorsCode["code500"] = "500";
})(ErrorsCode || (ErrorsCode = {}));
export var ErrorPage = function (_a) {
    var content = _a.content, _b = _a.fullHeight, fullHeight = _b === void 0 ? true : _b, navigateTo = _a.navigateTo, props = __rest(_a, ["content", "fullHeight", "navigateTo"]);
    var navigate = useNavigate();
    var contents = useSelector(selectAppContent);
    var navigateOnClick = function (navigateTo) {
        navigate(navigateTo);
    };
    return (_jsx("div", __assign({ className: classNames("container", {
            "full-height": fullHeight
        }), "data-testid": "error-page" }, props, { children: content ? (_jsxs("div", __assign({ className: "content" }, { children: [_jsx("span", { children: content }), navigateTo && (_jsx("span", { children: _jsx(Button, __assign({ className: "navigate-button", onClick: function () { return navigateOnClick(navigateTo); } }, { children: contents.error_navigate_home })) }))] }))) : null })));
};
