var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { selectLayoutUi, selectStyles } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { Layout } from "../components/layout/Layout";
var HomePage = function () {
    var _a;
    var styles = useSelector(selectStyles);
    var layoutUi = useSelector(selectLayoutUi);
    var filteredLayoutUi = __assign(__assign({}, layoutUi), { children: (_a = layoutUi === null || layoutUi === void 0 ? void 0 : layoutUi.children) === null || _a === void 0 ? void 0 : _a.filter(function (element) {
            if ((element === null || element === void 0 ? void 0 : element.component) === "Speakers" && (styles === null || styles === void 0 ? void 0 : styles.display_homepage_speakers_section) !== true)
                return false;
            else
                return true;
        }) });
    return (_jsx("div", __assign({ "data-testid": "home" }, { children: _jsx(Layout, { nodes: filteredLayoutUi }) })));
};
export { HomePage };
