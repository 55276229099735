var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
import { useState } from "react";
export var enrichRegistrationFormPropsEurogin = function () {
    var _a = useState(false), showAllInputs = _a[0], setShowAllInputs = _a[1];
    var onChange = function (changed) {
        if (!showAllInputs && (changed["country_id"] !== undefined || changed["is_internal"] !== undefined)) {
            setShowAllInputs(true);
        }
    };
    var _b = useRegistrationForm(), contents = _b.contents, allCountries = _b.allCountries, eventCountries = _b.eventCountries, hasInternal = _b.hasInternal, isInternalUser = _b.isInternalUser, languages = _b.languages, getRegistrationFormProps = _b.getRegistrationFormProps;
    var _c = contents, error_country_required = _c.error_country_required, error_lastname_required = _c.error_lastname_required, error_firstname_required = _c.error_firstname_required, error_language_required = _c.error_language_required, email = _c.email, affiliation = _c.affiliation, phone = _c.phone, country_of_work = _c.country_of_work, language = _c.language, internal = _c.internal, firstname = _c.firstname, lastname = _c.lastname, country = _c.country, password = _c.password, error_affiliation_required = _c.error_affiliation_required, error_specialty_required = _c.error_specialty_required, password_confirmation = _c.password_confirmation, error_password_required = _c.error_password_required, error_psw_do_not_match = _c.error_psw_do_not_match, specialty = _c.specialty, error_email_invalid = _c.error_email_invalid, error_email_required = _c.error_email_required, internal_true_value_label = _c.internal_true_value_label, internal_false_value_label = _c.internal_false_value_label;
    var inputs = [];
    if (hasInternal) {
        inputs.push({
            type: "select",
            label: internal,
            required: true,
            name: "is_internal",
            rules: [
                {
                    required: true,
                    message: ""
                }
            ],
            options: [
                {
                    value: 1,
                    label: internal_true_value_label
                },
                {
                    value: 0,
                    label: internal_false_value_label
                }
            ]
        });
    }
    var countriesSelect = {
        type: "select",
        label: country,
        required: true,
        showSearch: true,
        name: "country_id",
        rules: [
            {
                required: true,
                message: error_country_required
            }
        ],
        options: eventCountries
    };
    if (isInternalUser) {
        countriesSelect = {
            type: "select",
            label: country_of_work,
            required: true,
            showSearch: true,
            name: "country_id_of_work",
            rules: [
                {
                    required: true,
                    message: error_country_required
                }
            ],
            options: allCountries
        };
    }
    inputs.push(countriesSelect);
    var languageInput = {
        type: "hidden",
        name: "lang_id",
        value: languages[0].value
    };
    if (languages.length > 1) {
        languageInput = {
            type: "select",
            label: language,
            required: true,
            name: "lang_id",
            options: languages,
            rules: [
                {
                    required: true,
                    message: error_language_required
                }
            ]
        };
    }
    inputs.push(languageInput);
    if (showAllInputs) {
        inputs = __spreadArray(__spreadArray([], inputs, true), [
            {
                type: "text",
                label: firstname,
                required: true,
                name: "firstname",
                rules: [
                    {
                        required: true,
                        message: error_firstname_required
                    }
                ]
            },
            {
                type: "text",
                label: lastname,
                required: true,
                name: "lastname",
                rules: [
                    {
                        required: true,
                        message: error_lastname_required
                    }
                ]
            },
            {
                type: "text",
                label: specialty,
                required: true,
                name: "specialty",
                rules: [
                    {
                        required: true,
                        message: error_specialty_required
                    }
                ]
            },
            {
                type: "text",
                label: affiliation,
                required: true,
                name: "affiliation",
                rules: [
                    {
                        required: true,
                        message: error_affiliation_required
                    }
                ]
            },
            {
                type: "email",
                label: email,
                required: true,
                name: "email",
                rules: [
                    { type: "email", message: error_email_invalid },
                    {
                        required: true,
                        message: error_email_required
                    }
                ]
            },
            {
                type: "password",
                label: password || "Password",
                required: true,
                name: "password",
                rules: [
                    {
                        required: true,
                        message: error_password_required
                    }
                ]
            },
            {
                type: "password",
                label: password_confirmation,
                required: true,
                name: "password_confirmation",
                rules: [
                    {
                        required: true,
                        message: error_psw_do_not_match
                    }
                ]
            },
            {
                type: "text",
                label: phone,
                name: "phone"
            },
            {
                type: "hidden",
                name: "change_password_required",
                value: false
            }
        ], false);
    }
    return {
        inputs: getRegistrationFormProps({ inputs: inputs }).inputs,
        onChange: function (changedValues) {
            getRegistrationFormProps({ inputs: inputs }).onChange(changedValues);
            onChange(changedValues);
        }
    };
};
