export var NEW_QUESTION_TO_MODERATE_PUSHER_EVENT = "App\\Events\\NewQuestionToModerate";
export var NEW_QUESTION_TO_ANSWER_PUSHER_EVENT = "App\\Events\\NewQuestionToAnswer";
export var QUESTION_MODERATED = "App\\Events\\QuestionModerated";
export var QUESTION_READ_BY_CHAIRMAN = "App\\Events\\QuestionRead"; // question id
export var QUESTION_DISABLED_FROM_MODERATOR = "App\\Events\\QuestionDisabled"; // question id
export var subscribeToQAQuestionChannel = function (_a, callback, fromItemsToSubmittedCallback, manuallyDeleteCallback) {
    var pusherSingleton = _a.pusherSingleton, appId = _a.appId, episodeId = _a.episodeId, isChairman = _a.isChairman, isModerator = _a.isModerator;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        fetch(process.env.PUSHER_BASE_URL + "broadcasting/auth", { method: "POST" });
        var who = isModerator ? "moderator" : isChairman ? "speaker" : "";
        var channel = pusherSingleton.subscribe("private-qaa-".concat(who, "-").concat(appId, ".").concat(episodeId));
        if (isChairman) {
            channel.bind(NEW_QUESTION_TO_ANSWER_PUSHER_EVENT, function (_a) {
                var question = _a.question;
                callback(question);
            });
            channel.bind(QUESTION_READ_BY_CHAIRMAN, function (_a) {
                var question = _a.question;
                fromItemsToSubmittedCallback(question);
            });
        }
        if (isModerator) {
            channel.bind(NEW_QUESTION_TO_MODERATE_PUSHER_EVENT, function (_a) {
                var question = _a.question;
                callback(question);
            });
            channel.bind(QUESTION_MODERATED, function (_a) {
                var question = _a.question;
                fromItemsToSubmittedCallback(question);
            });
            channel.bind(QUESTION_DISABLED_FROM_MODERATOR, function (_a) {
                var question = _a.question;
                manuallyDeleteCallback(question);
            });
        }
        if (window.Cypress) {
            window.Cypress.pusher = pusherSingleton;
        }
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        try {
            if (pusherSingleton) {
                var who = isModerator ? "moderator" : isChairman ? "speaker" : "";
                var channel = pusherSingleton.subscribe("private-qaa-".concat(who, "-").concat(appId, ".").concat(episodeId));
                if (isChairman) {
                    // ==========
                    channel.bind(NEW_QUESTION_TO_ANSWER_PUSHER_EVENT, function (_a) {
                        var question = _a.question;
                        callback(question);
                    });
                    // ==========
                    channel.bind(QUESTION_READ_BY_CHAIRMAN, function (_a) {
                        var question = _a.question;
                        fromItemsToSubmittedCallback(question);
                    });
                }
                if (isModerator) {
                    // ==========
                    channel.bind(NEW_QUESTION_TO_MODERATE_PUSHER_EVENT, function (_a) {
                        var question = _a.question;
                        callback(question);
                    });
                    // ==========
                    channel.bind(QUESTION_MODERATED, function (_a) {
                        var question = _a.question;
                        fromItemsToSubmittedCallback(question);
                    });
                    channel.bind(QUESTION_DISABLED_FROM_MODERATOR, function (_a) {
                        var question = _a.question;
                        manuallyDeleteCallback(question);
                    });
                }
                return channel;
            }
            else
                return null;
        }
        catch (_b) {
            return null;
        }
    }
};
