import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectMediaFavicon } from "@adm-media/adam-store-plugin";
export var useSetFavicon = function () {
    var mediaFavicon = useSelector(selectMediaFavicon);
    useEffect(function () {
        var faviconElement = document.getElementById("favicon");
        var favicon = (mediaFavicon === null || mediaFavicon === void 0 ? void 0 : mediaFavicon.url) || "favicon.ico";
        if (faviconElement !== null) {
            faviconElement.href = favicon;
        }
    }, [mediaFavicon]);
};
