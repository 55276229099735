var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { moment } from "../../../utils/moment";
import classNames from "classnames";
import Input from "../Input/Input";
import Button from "../Buttons/Button";
import { DeleteIcon } from "../Icons/Icons";
import { Popconfirm } from "antd";
import { useSelector } from "react-redux";
import { selectCurrentEpisode, selectUser } from "@adm-media/adam-store-plugin";
import "./QuestionCard.scss";
import { isChannelConference } from "../../../utils/isChannelConference";
var TextArea = Input.TextArea;
var QuestionCard = function (_a) {
    var _b;
    var _c = _a.cancelButtonText, cancelButtonText = _c === void 0 ? "Cancel" : _c, date = _a.date, _d = _a.editable, editable = _d === void 0 ? false : _d, _e = _a.hoverable, hoverable = _e === void 0 ? false : _e, label = _a.label, _f = _a.loading, loading = _f === void 0 ? false : _f, onClick = _a.onClick, onDismiss = _a.onDismiss, onSubmit = _a.onSubmit, questionText = _a.questionText, submitted = _a.submitted, user = _a.user, _g = _a.submitButtonText, submitButtonText = _g === void 0 ? "Send question" : _g, withActions = _a.withActions, onDeleteQuestion = _a.onDeleteQuestion, questionId = _a.questionId;
    var _h = useSelector(selectUser), isModerator = _h.isModerator, isChairman = _h.isChairman;
    var cardClassNames = classNames("question-card", {
        hoverable: hoverable,
        editable: editable,
        submitted: submitted,
        noOnClick: onClick === undefined
    });
    var channel = useSelector(selectCurrentEpisode).channel;
    var _j = useState(questionText), editableQuestion = _j[0], changeEditableQuestion = _j[1];
    var handleOnChangeTextArea = function (e) { return changeEditableQuestion(e.target.value); };
    var handleSubmit = function () { return onSubmit && onSubmit(editableQuestion); };
    var handleDismiss = function () { return onDismiss && onDismiss(); };
    var handleDeleteQuestion = function () {
        onDeleteQuestion && onDeleteQuestion(questionId);
    };
    return (_jsxs("div", __assign({ role: "presentation", onClick: onClick, className: cardClassNames, "data-testid": "question-item" }, { children: [_jsxs("div", __assign({ className: "question-card__header" }, { children: [_jsx("div", { children: _jsx("div", __assign({ className: "question-card__avatar" }, { children: _jsx("span", { children: user[0].toUpperCase() }) })) }), _jsxs("div", { children: [_jsx("p", __assign({ className: "question-card__user", "data-testid": "question-card-user" }, { children: user })), label && _jsx("p", __assign({ className: "question-card__label" }, { children: label })), _jsxs("span", __assign({ className: "question-card__date", "data-testid": "question-card-date" }, { children: ["Last updated: ", (_b = moment(date)) === null || _b === void 0 ? void 0 : _b.fromNow()] }))] }), (isModerator || (isChannelConference(channel) && isChairman)) && hoverable && !(editable || withActions) && (_jsx("div", __assign({ onClick: function (event) { return event.stopPropagation(); } }, { children: _jsx(Popconfirm, __assign({ title: "Delete the question", description: "Are you sure you want to delete this question?", onConfirm: handleDeleteQuestion, okText: "Yes", cancelText: "No", "data-testid": "question-card-confirm-delete", okButtonProps: {
                                "data-testid": "confirm-delete-question-button"
                            } }, { children: _jsx(Button, { icon: _jsx(DeleteIcon, {}), "data-testid": "question-card-delete-button", className: "question-card__delete-button", onClick: function (event) { return event.stopPropagation(); } }) })) })))] })), _jsx("div", __assign({ className: "question-card__question", "data-testid": "question-card-message" }, { children: questionText })), editable && (_jsx("div", { children: _jsx(TextArea, { "data-testid": "edit-question-textarea", autoSize: { minRows: 4, maxRows: 10 }, className: "question-card__textarea", onChange: handleOnChangeTextArea, value: editableQuestion }) })), (editable || withActions) && (_jsxs("div", __assign({ className: "question-card__actions" }, { children: [_jsx(Button, __assign({ "data-testid": "cancel-question-selection-button", onClick: handleDismiss }, { children: cancelButtonText })), _jsx(Button, __assign({ "data-testid": "confirm-selection-button", loading: loading, type: "primary", onClick: handleSubmit }, { children: submitButtonText }))] })))] })));
};
export default QuestionCard;
