import moment from "moment";
import momentTimezone from "moment-timezone";
var isTimeAfterNow = function (date) {
    var nowUtc = moment.utc();
    var dateUtc = moment.utc(date);
    return dateUtc.isAfter(nowUtc);
};
var isTimeBeforeNow = function (date) {
    var nowUtc = moment.utc();
    var dateUtc = moment.utc(date);
    return dateUtc.isBefore(nowUtc);
};
export { moment, momentTimezone, isTimeAfterNow, isTimeBeforeNow };
