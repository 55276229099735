import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { setDocumentVisibilityStatus } from "@adm-media/adam-store-plugin";
export var useSetDocumentVisibilityStatus = function () {
    var dispatch = useAppDispatch();
    useEffect(function () {
        var handler = function (event) { return dispatch(setDocumentVisibilityStatus(event.target.visibilityState)); };
        document.addEventListener("visibilitychange", handler);
        return function () { return document.removeEventListener("visibilitychange", handler); };
    }, []);
};
