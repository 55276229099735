var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormPropsMsdHncrResearchForum = function () {
    var _a = useRegistrationForm(), departmentInputValue = _a.departmentInputValue, allCountries = _a.allCountries, specialtyInputValue = _a.specialtyInputValue, isInternalUser = _a.isInternalUser, contents = _a.contents, eventCountries = _a.eventCountries, languages = _a.languages, getRegistrationFormProps = _a.getRegistrationFormProps, hasInternal = _a.hasInternal, getTranslatedOptions = _a.getTranslatedOptions;
    var _b = contents, department = _b.department, error_country_required = _b.error_country_required, otherSpecialty = _b.otherSpecialty, istitution = _b.istitution, specialty = _b.specialty, error_email_required = _b.error_email_required, internal = _b.internal, internal_false_value_label = _b.internal_false_value_label, error_language_required = _b.error_language_required, email = _b.email, other_department = _b.other_department, country_of_work = _b.country_of_work, language = _b.language, firstname = _b.firstname, lastname = _b.lastname, country = _b.country, internal_true_value_label = _b.internal_true_value_label;
    var inputs = [];
    var languageInput = languages.length > 1
        ? {
            label: language,
            name: "lang_id",
            options: languages,
            required: true,
            type: "select",
            rules: [{ required: true, message: error_language_required }]
        }
        : {
            type: "hidden",
            name: "lang_id",
            value: languages[0].value
        };
    inputs.push(languageInput);
    if (hasInternal)
        inputs.push({
            type: "select",
            label: internal,
            required: true,
            name: "is_internal",
            rules: [
                {
                    required: true,
                    message: ""
                }
            ],
            options: [
                {
                    value: 1,
                    label: internal_true_value_label
                },
                {
                    value: 0,
                    label: internal_false_value_label
                }
            ]
        });
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            type: "text",
            label: firstname,
            required: true,
            name: "firstname",
            rules: [
                {
                    required: true,
                    message: contents.error_firstname_required
                }
            ]
        },
        {
            type: "text",
            label: lastname,
            required: true,
            name: "lastname",
            rules: [
                {
                    required: true,
                    message: contents.error_lastname_required
                }
            ]
        }
    ], false);
    if (!isInternalUser) {
        inputs.push({
            label: country,
            name: "country_id",
            options: eventCountries,
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: "Please input your firstname!"
                }
            ]
        }, {
            type: "text",
            label: istitution,
            required: true,
            name: "institution",
            rules: [
                {
                    required: true,
                    message: "Please input your institution!"
                }
            ]
        }, {
            label: specialty,
            name: "specialty",
            options: getTranslatedOptions(),
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: "Please input your speciality!"
                }
            ]
        });
        if (specialtyInputValue === "Other") {
            inputs.push({
                type: "text",
                label: otherSpecialty,
                required: true,
                name: "other_specialty",
                rules: [
                    {
                        required: true,
                        message: "Please input your speciality!"
                    }
                ]
            });
        }
    }
    else {
        inputs.push({
            label: country_of_work,
            name: "country_id_of_work",
            options: allCountries,
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: error_country_required
                }
            ]
        }, {
            type: "select",
            label: department,
            required: true,
            name: "department",
            rules: [
                {
                    required: true,
                    message: "Please select your department!"
                }
            ],
            options: [
                {
                    value: "MRL/GMSA",
                    label: "MRL/GMSA"
                },
                {
                    value: null,
                    label: "Other"
                }
            ]
        });
        if (departmentInputValue === "Other") {
            inputs.push({
                type: "text",
                label: other_department,
                required: true,
                name: "other_department",
                rules: [
                    {
                        required: true,
                        message: "Please input your other department!"
                    }
                ]
            });
        }
    }
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            label: email,
            name: "email",
            required: true,
            type: "email",
            rules: [
                {
                    required: true,
                    message: error_email_required
                }
            ]
        }
    ], false);
    return getRegistrationFormProps({ inputs: inputs });
};
