import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormStandardPasswordCompany = function () {
    var _a = useRegistrationForm(), contents = _a.contents, eventCountries = _a.eventCountries, languages = _a.languages, getRegistrationFormProps = _a.getRegistrationFormProps;
    var error_language_required = contents.error_language_required, error_psw_do_not_match = contents.error_psw_do_not_match, error_email_invalid = contents.error_email_invalid, error_lastname_required = contents.error_lastname_required, error_firstname_required = contents.error_firstname_required, email = contents.email, password = contents.password, language = contents.language, firstname = contents.firstname, password_confirmation = contents.password_confirmation, lastname = contents.lastname, country = contents.country, error_institution_reuired = contents.error_institution_reuired, istitution = contents.istitution, error_email_required = contents.error_email_required, error_password_required = contents.error_password_required, error_country_required = contents.error_country_required;
    var inputs = [
        {
            type: "text",
            label: firstname,
            required: true,
            name: "firstname",
            rules: [
                {
                    required: true,
                    message: error_firstname_required
                }
            ]
        },
        {
            type: "text",
            label: lastname,
            required: true,
            name: "lastname",
            rules: [
                {
                    required: true,
                    message: error_lastname_required
                }
            ]
        },
        {
            type: "email",
            label: email,
            required: true,
            name: "email",
            rules: [
                { type: "email", message: error_email_invalid },
                {
                    required: true,
                    message: error_email_required
                }
            ]
        },
        {
            type: "password",
            label: password || "Password",
            required: true,
            name: "password",
            rules: [
                {
                    required: true,
                    message: error_password_required
                }
            ]
        },
        {
            type: "password",
            label: password_confirmation,
            required: true,
            name: "password_confirmation",
            rules: [
                {
                    required: true,
                    message: error_psw_do_not_match
                }
            ]
        },
        {
            type: "select",
            label: country,
            required: true,
            name: "country_id",
            rules: [
                {
                    required: true,
                    message: error_country_required
                }
            ],
            options: eventCountries,
            showSearch: true
        },
        {
            type: "text",
            label: istitution,
            required: true,
            name: "institution",
            rules: [
                {
                    required: true,
                    message: error_institution_reuired
                }
            ]
        },
        {
            type: "hidden",
            name: "change_password_required",
            value: false
        }
    ];
    var languageInput = {
        type: "hidden",
        name: "lang_id",
        value: languages[0].value
    };
    if (languages.length > 1) {
        languageInput = {
            type: "select",
            label: language,
            required: true,
            name: "lang_id",
            rules: [
                {
                    required: true,
                    message: error_language_required
                }
            ],
            options: languages
        };
    }
    inputs.push(languageInput);
    return getRegistrationFormProps({ inputs: inputs });
};
