import { selectTagManagerId } from "@adm-media/adam-store-plugin";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
export var useInitializeGtm = function () {
    var gtmId = useSelector(selectTagManagerId);
    useEffect(function () {
        if (gtmId)
            TagManager.initialize({ gtmId: gtmId });
    }, [gtmId]);
};
