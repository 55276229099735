import { selectAnswerEvaluationForm, selectCurrentEpisode, selectUserEvaluationFormAnswers } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
export var useIsEvaluationFormAvailable = function () {
    var _a;
    var _b = useSelector(selectCurrentEpisode), selectedEpisodeId = _b.id, is_evaluation_open = _b.is_evaluation_open, evaluation_questions = _b.evaluation_questions;
    var evaluationFormAnswers = useSelector(selectUserEvaluationFormAnswers);
    var episodesCompleted = useSelector(selectAnswerEvaluationForm).episodesCompleted;
    var isEvaluationFormAlreadyAnswered = false;
    try {
        isEvaluationFormAlreadyAnswered = ((_a = evaluationFormAnswers === null || evaluationFormAnswers === void 0 ? void 0 : evaluationFormAnswers[selectedEpisodeId]) === null || _a === void 0 ? void 0 : _a.answered) || episodesCompleted.includes(selectedEpisodeId);
    }
    catch (_c) {
        isEvaluationFormAlreadyAnswered = false;
    }
    var isEvaluationFormAvailable = is_evaluation_open &&
        (evaluation_questions === null || evaluation_questions === void 0 ? void 0 : evaluation_questions.find(function (evalQuestionCategory) { var _a; return ((_a = evalQuestionCategory === null || evalQuestionCategory === void 0 ? void 0 : evalQuestionCategory.evaluationquestions) === null || _a === void 0 ? void 0 : _a.length) > 0; })) !== undefined;
    return { isEvaluationFormAvailable: isEvaluationFormAvailable, isEvaluationFormAlreadyAnswered: isEvaluationFormAlreadyAnswered };
};
