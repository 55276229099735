var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormPropsMsdSpecial = function () {
    var _a = useRegistrationForm(), languageInput = _a.languageInput, contents = _a.contents, allCountries = _a.allCountries, eventCountries = _a.eventCountries, hasInternal = _a.hasInternal, isInternalUser = _a.isInternalUser, specialtyInputValue = _a.specialtyInputValue, departmentInputValue = _a.departmentInputValue, countryInputValue = _a.countryInputValue, getTranslatedOptions = _a.getTranslatedOptions, getRegistrationFormProps = _a.getRegistrationFormProps;
    var _b = contents, email = _b.email, country_of_work = _b.country_of_work, internal = _b.internal, internal_false_value_label = _b.internal_false_value_label, firstname = _b.firstname, lastname = _b.lastname, country = _b.country, istitution = _b.istitution, specialty = _b.specialty, other_department = _b.other_department, otherSpecialty = _b.otherSpecialty, department = _b.department, internal_true_value_label = _b.internal_true_value_label;
    var inputs = [];
    if (hasInternal) {
        inputs.push({
            type: "select",
            label: internal,
            required: true,
            name: "is_internal",
            rules: [
                {
                    required: true,
                    message: ""
                }
            ],
            options: [
                {
                    value: 1,
                    label: internal_true_value_label
                },
                {
                    value: 0,
                    label: internal_false_value_label
                }
            ]
        });
    }
    if (!isInternalUser) {
        inputs = __spreadArray(__spreadArray([], inputs, true), [
            {
                type: "select",
                label: country,
                required: true,
                name: "country_id",
                options: eventCountries,
                rules: [
                    {
                        required: true,
                        message: "Please select your country!"
                    }
                ]
            },
            {
                type: "text",
                label: istitution,
                required: true,
                name: "istitution",
                rules: [
                    {
                        required: true,
                        message: "Please input your institution!"
                    }
                ]
            },
            {
                type: "select",
                label: specialty,
                required: true,
                name: "specialty",
                rules: [
                    {
                        required: true,
                        message: "Please select your speciality!"
                    }
                ],
                options: getTranslatedOptions()
            }
        ], false);
        if (specialtyInputValue === "Other") {
            inputs.push({
                type: "text",
                label: otherSpecialty,
                required: true,
                name: "other_specialty",
                rules: [
                    {
                        required: true,
                        message: "Please input your other speciality!"
                    }
                ]
            });
        }
    }
    else {
        inputs = __spreadArray(__spreadArray([], inputs, true), [
            {
                type: "select",
                label: country_of_work,
                required: true,
                name: "country_id_of_work",
                options: allCountries,
                rules: [
                    {
                        required: true,
                        message: "Please select your country!"
                    }
                ]
            },
            {
                type: "select",
                label: department,
                required: true,
                name: "department",
                rules: [
                    {
                        required: true,
                        message: "Please select your department!"
                    }
                ],
                options: [
                    {
                        value: "MRL/GMSA",
                        label: "MRL/GMSA"
                    },
                    {
                        value: "Other",
                        label: "Other"
                    }
                ]
            }
        ], false);
        if (departmentInputValue === "Other") {
            inputs.push({
                type: "text",
                label: other_department,
                required: true,
                name: "other_department",
                rules: [
                    {
                        required: true,
                        message: "Please input your other department!"
                    }
                ]
            });
        }
    }
    inputs.push(languageInput);
    if (countryInputValue !== undefined) {
        inputs = __spreadArray(__spreadArray([], inputs, true), [
            {
                type: "text",
                label: firstname,
                required: true,
                name: "firstname",
                rules: [
                    {
                        required: true,
                        message: "Please input your firstname!"
                    }
                ]
            },
            {
                type: "text",
                label: lastname,
                required: true,
                name: "lastname",
                rules: [
                    {
                        required: true,
                        message: "Please input your lastname!"
                    }
                ]
            },
            {
                type: "email",
                label: email,
                required: true,
                name: "email",
                rules: [
                    { type: "email", message: "Please insert a valid email" },
                    {
                        required: true,
                        message: "Please input your email!"
                    }
                ]
            }
        ], false);
    }
    return getRegistrationFormProps({ inputs: inputs });
};
