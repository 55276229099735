export var convertSourceId = function (watermarkTimelineEncoded, channelStatus) {
    try {
        var watermarkContentToDecode = watermarkTimelineEncoded.slice(0, 2) + watermarkTimelineEncoded.slice(3);
        var watermarkTimelineString = atob(watermarkContentToDecode);
        var watermarkTimeline = JSON.parse(watermarkTimelineString);
        var watermarkTimelineForCurrentStatus = watermarkTimeline[channelStatus];
        if (!Array.isArray(watermarkTimelineForCurrentStatus))
            return null;
        else
            return watermarkTimelineForCurrentStatus;
    }
    catch (_a) {
        return null;
    }
};
