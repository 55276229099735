import { useSelector } from "react-redux";
import { useNotification } from "./useNotification";
import { addQuestion, deleteQuestionManually, logoutUserThunk, selectAppContent, selectEventId, selectSelectedEpisodeId, selectUser, sendFromItemsToSubmitted } from "@adm-media/adam-store-plugin";
import { useAppDispatch } from "../store";
import { useEffect, useState } from "react";
import init from "../pusher/base";
import { PusherMock } from "pusher-js-mock";
import { subscribeToQAQuestionChannel } from "../pusher/subscribe-to-qa-questions-channel";
export var useModeratorPusherConnection = function () {
    var contents = useSelector(selectAppContent);
    var notification = useNotification().notification;
    var dispatch = useAppDispatch();
    var eventId = useSelector(selectEventId);
    var selectedEpisodeId = useSelector(selectSelectedEpisodeId);
    var _a = useSelector(selectUser), token = _a.token, isModerator = _a.isModerator, isChairman = _a.isChairman;
    var logoutWithConnectionError = function () {
        dispatch(logoutUserThunk())
            .unwrap()
            .finally(function () {
            notification.info({ message: contents.offline_forced_logout_notification_message || "Connection error, please login again." });
        });
    };
    var _b = useState(null), questionChannelEpId = _b[0], setQuestionChannelEpId = _b[1];
    var _c = useState(null), qaQuestionChannel = _c[0], setQaQuestionChannel = _c[1];
    var _d = useState(null), moderatorPusherSingleton = _d[0], setModeratorPusherSingleton = _d[1];
    useEffect(function () {
        if (token && eventId && moderatorPusherSingleton === null && (isModerator || isChairman)) {
            if (!(window === null || window === void 0 ? void 0 : window.Cypress)) {
                var pusher = init(token);
                setModeratorPusherSingleton(pusher);
                pusher.connection.bind("unavailable", logoutWithConnectionError);
                pusher.connection.bind("failed", logoutWithConnectionError);
            }
            else
                setModeratorPusherSingleton(new PusherMock());
        }
        else if (moderatorPusherSingleton && !token && qaQuestionChannel === null) {
            try {
                moderatorPusherSingleton.disconnect();
            }
            catch (_a) { }
            setModeratorPusherSingleton(null);
        }
    }, [token, eventId, moderatorPusherSingleton, isChairman, isModerator, qaQuestionChannel]);
    useEffect(function () {
        if (eventId &&
            token &&
            selectedEpisodeId &&
            questionChannelEpId !== selectedEpisodeId &&
            moderatorPusherSingleton &&
            (isModerator || isChairman) &&
            qaQuestionChannel === null) {
            var episodeID = String(selectedEpisodeId);
            setQaQuestionChannel(subscribeToQAQuestionChannel({
                pusherSingleton: moderatorPusherSingleton,
                appId: eventId,
                episodeId: episodeID,
                isModerator: isModerator,
                isChairman: isChairman
            }, function (question) {
                dispatch(addQuestion(question));
                notification.success({ message: "You received a new question!" });
            }, function (question) {
                dispatch(sendFromItemsToSubmitted(question));
            }, function (question) {
                dispatch(deleteQuestionManually(question.id));
            }));
            setQuestionChannelEpId(selectedEpisodeId);
        }
        else if (eventId &&
            token &&
            selectedEpisodeId &&
            questionChannelEpId !== selectedEpisodeId &&
            moderatorPusherSingleton &&
            (isModerator || isChairman) &&
            qaQuestionChannel !== null) {
            try {
                qaQuestionChannel.unbind_all();
                qaQuestionChannel.unsubscribe();
            }
            catch (_a) { }
            setQaQuestionChannel(null);
        }
        else if (!token && qaQuestionChannel) {
            try {
                qaQuestionChannel.unsubscribe();
            }
            catch (_b) { }
            setQaQuestionChannel(null);
            setQuestionChannelEpId(null);
        }
    }, [eventId, token, selectedEpisodeId, isChairman, isModerator, moderatorPusherSingleton, qaQuestionChannel, questionChannelEpId]);
};
