import { selectIsConsoleWarningVisible, selectUser } from "@adm-media/adam-store-plugin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getConsoleWarningMessage } from "../utils/getConsoleWarningMessage";
export var useWriteOnDOM = function () {
    var identity = useSelector(selectUser).identity;
    var _a = useState(false), isWarningOnRootWritten = _a[0], setIsWarningOnRootWritten = _a[1];
    var isConsoleWarningVisible = useSelector(selectIsConsoleWarningVisible);
    useEffect(function () {
        var _a;
        if (identity.email && !!isConsoleWarningVisible && !isWarningOnRootWritten) {
            setIsWarningOnRootWritten(true);
            (_a = document
                .getElementById("root")) === null || _a === void 0 ? void 0 : _a.append(document.createComment(getConsoleWarningMessage({ email: identity.email, name: identity === null || identity === void 0 ? void 0 : identity.firstname, lastname: identity === null || identity === void 0 ? void 0 : identity.lastname })));
        }
    }, [identity, isWarningOnRootWritten, isConsoleWarningVisible]);
};
