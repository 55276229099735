var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement } from "react";
import UI from "./ui";
import { getEnrichedProps } from "./enrich-props";
/**
 * Method that create HTML for from a JSON
 * @param nodes
 * @returns
 */
var render = function (nodes) {
    // Return if there is nothing to map
    if (nodes === undefined) {
        return;
    }
    // Adapt single node for mapping
    if (!Array.isArray(nodes)) {
        nodes = [nodes];
    }
    return nodes.map(function (node, i) {
        if (node) {
            /**
             * Applying referred props for each node.component found
             */
            var _a = getEnrichedProps({
                componentTag: node.component,
                propsFromTheme: __assign(__assign({}, node.props), { key: i })
            }), enrichedChildren = _a.children, enrichedProps = __rest(_a, ["children"]);
            var nodeChildren = typeof node.children === "string" ? node.children : render(node.children);
            var children = enrichedChildren || nodeChildren;
            // setting known elements with animation true by default
            var elementEnrichedProps = UI[node.component]
                ? __assign({ animation: enrichedProps.animation === undefined ? true : enrichedProps.animation }, enrichedProps) : enrichedProps;
            return createElement(UI[node.component || "Row"] || "div", elementEnrichedProps, children);
        }
        return undefined;
    });
};
export default render;
