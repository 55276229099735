import enrichNavbarProps from "./enrich-navbar-props";
import enrichRegistrationFormProps from "./enrich-registration-form-props";
var propsEnrichers = {
    Navbar: enrichNavbarProps,
    RegistrationForm: enrichRegistrationFormProps
};
var getEnrichedProps = function (_a) {
    var componentTag = _a.componentTag, propsFromTheme = _a.propsFromTheme;
    if (Object.keys(propsEnrichers).includes(componentTag)) {
        var enricherFunction = propsEnrichers[componentTag];
        return enricherFunction(propsFromTheme);
    }
    else
        return propsFromTheme;
};
export { getEnrichedProps };
