var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { selectAppContent } from "@adm-media/adam-store-plugin";
import { AgendaItem } from "../../ui/AgendaItem/AgendaItem";
import Col from "../../ui/Col/Col";
import classNames from "classnames";
import useAgenda from "../../../hooks/useAgenda";
import { rowGutter } from "../configs";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import Tabs from "../../ui/Tabs/Tabs";
import { Row, Space, Tag, Typography } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import "./Agenda.scss";
import { useAnimationClassName } from "../../../hooks/useAnimationClassName";
import { useEpisodesToShowInHomepage } from "../../../hooks/useEpisodesToShowInHomepage";
import { AgendaTableItem } from "../../ui/AgendaTableItem/AgendaTableItem";
export var Agenda = function (_a) {
    var _b, _c, _d;
    var agendaItems = _a.agendaItems, hiddenSpeakersId = _a.hiddenSpeakersId, animation = _a.animation, title = _a.title, _e = _a.viewType, viewType = _e === void 0 ? "grid" : _e, isDrawerElement = _a.isDrawerElement, props = __rest(_a, ["agendaItems", "hiddenSpeakersId", "animation", "title", "viewType", "isDrawerElement"]);
    var formatAgenda = useAgenda().formatAgenda;
    var contents = useSelector(selectAppContent);
    var episodesToShowInHomepage = useEpisodesToShowInHomepage().episodesToShowInHomepage;
    var titleRef = useRef(null);
    var titleAnimationClass = useAnimationClassName({ ref: titleRef, animation: animation });
    var contentRef = useRef(null);
    var contentAnimationClass = useAnimationClassName({ ref: contentRef, animation: animation });
    var baseClass = "block-agenda";
    var modifyerClass = baseClass + (isDrawerElement ? "--drawer" : "--element");
    var blockClassName = classNames(baseClass, modifyerClass);
    var isCardsViewType = viewType === "grid";
    return (_jsxs(BlockWrapper, __assign({ id: "agenda" }, props, { className: blockClassName, "data-testid": "block-agenda" }, { children: [title !== null && contents.agenda_title && (_jsx(Typography.Title, __assign({ ref: titleRef, style: { marginBottom: 20 }, className: "".concat(baseClass, "__title ").concat(titleAnimationClass), level: props.titleLevel || 2 }, { children: contents.agenda_title }))), agendaItems && (_jsx(Row, __assign({ ref: contentRef, className: contentAnimationClass, gutter: !isDrawerElement ? rowGutter : [16, 16] }, { children: agendaItems === null || agendaItems === void 0 ? void 0 : agendaItems.map(function (agendaItem, i) {
                    var formattedAgendaItem = formatAgenda({ agendaItem: agendaItem });
                    return (_jsx(Col, __assign({ xs: 24, style: { display: "flex", alignItems: "stretch", flexDirection: "column" } }, { children: _jsx(AgendaItem, { agendaItem: formattedAgendaItem, hiddenSpeakersId: hiddenSpeakersId, withBorder: true, type: "list-item", titleLevel: ((props === null || props === void 0 ? void 0 : props.titleLevel) && (props === null || props === void 0 ? void 0 : props.titleLevel) <= 4 ? props.titleLevel + 1 : 4), hideAgendaStartTime: props.hideAgendaStartTime }, formattedAgendaItem.id) }), i));
                }) }))), !agendaItems && (_jsxs("div", __assign({ ref: contentRef, className: contentAnimationClass }, { children: [props.columnDisplayFrom !== "xs" && props.tableDisplayFrom !== "xs" && (_jsx("div", __assign({ className: classNames("".concat(baseClass, "__tabs-container"), (_b = {},
                            _b["".concat(baseClass, "__tabs-container--hide-").concat(props.columnDisplayFrom || props.tableDisplayFrom)] = props.columnDisplayFrom !== undefined || props.tableDisplayFrom !== undefined,
                            _b)), "data-testid": "agenda-tabs-container" }, { children: _jsx(Tabs, { tabBarStyle: { marginBottom: 30, display: episodesToShowInHomepage.length === 1 ? "none" : "flex" }, animated: true, "data-testid": "block-agenda-tabs", items: episodesToShowInHomepage.map(function (episode) {
                                var _a;
                                return {
                                    key: String(episode.id),
                                    label: episode.dropdown_label || episode.title,
                                    forceRender: true,
                                    children: (_jsx(Row, __assign({ gutter: rowGutter }, { children: (_a = episode.agenda) === null || _a === void 0 ? void 0 : _a.map(function (agendaItem, i) {
                                            var _a;
                                            var formattedAgendaItem = formatAgenda({ agendaItem: agendaItem });
                                            var hiddenSpeakerIds = !!(episode === null || episode === void 0 ? void 0 : episode.is_agenda_speakers_hidden)
                                                ? (_a = episode.speakers) === null || _a === void 0 ? void 0 : _a.map(function (speaker) { return speaker.id; })
                                                : episode.hidden_speakers_cards;
                                            return (_jsx(Col, __assign({ xs: 24, md: isCardsViewType ? 12 : 24, lg: isCardsViewType ? 8 : 24, xl: isCardsViewType ? 8 : 24, xxl: isCardsViewType ? 8 : 24, style: { display: "flex", alignItems: "stretch", flexDirection: "column" } }, { children: _jsx(AgendaItem, { hiddenSpeakersId: hiddenSpeakerIds, agendaItem: formattedAgendaItem, withBorder: true, type: isCardsViewType ? "card" : "list-item", hideAgendaStartTime: props.hideAgendaStartTime, titleLevel: ((props === null || props === void 0 ? void 0 : props.titleLevel) && (props === null || props === void 0 ? void 0 : props.titleLevel) <= 4
                                                        ? props.titleLevel + 1
                                                        : 4) }, formattedAgendaItem.id) }), i));
                                        }) })))
                                };
                            }) }) }))), props.columnDisplayFrom !== undefined && props.tableDisplayFrom === undefined && (_jsx("div", __assign({ className: classNames("".concat(baseClass, "__agenda-columns"), (_c = {},
                            _c["".concat(baseClass, "__agenda-columns--show-").concat(props.columnDisplayFrom)] = props.columnDisplayFrom !== undefined,
                            _c)), "data-testid": "agenda-columns-container" }, { children: _jsx(Row, __assign({ gutter: rowGutter }, { children: episodesToShowInHomepage.map(function (episode) {
                                var _a;
                                return (_jsxs(Col, __assign({ "data-testid": "agenda-column", xs: 24, lg: 12, style: { height: "fit-content" } }, { children: [_jsx(Typography.Title, __assign({ "data-testid": "agenda-table-episode-title", level: 2, className: "".concat(baseClass, "__column-title") }, { children: _jsxs(Space, { children: [_jsx(CalendarOutlined, { style: { fontSize: 35 } }), episode.dropdown_label || episode.title] }) })), _jsx(Row, __assign({ gutter: rowGutter }, { children: (_a = episode.agenda) === null || _a === void 0 ? void 0 : _a.map(function (agendaItem, i) {
                                                var _a;
                                                var formattedAgendaItem = formatAgenda({ agendaItem: agendaItem });
                                                var isCardsViewType = viewType === "grid";
                                                var hiddenSpeakerIds = !!(episode === null || episode === void 0 ? void 0 : episode.is_agenda_speakers_hidden)
                                                    ? (_a = episode.speakers) === null || _a === void 0 ? void 0 : _a.map(function (speaker) { return speaker.id; })
                                                    : episode.hidden_speakers_cards;
                                                return (_jsx(Col, __assign({ xs: 24 }, { children: _jsx(AgendaItem, { hiddenSpeakersId: hiddenSpeakerIds, agendaItem: formattedAgendaItem, withBorder: true, type: isCardsViewType ? "card" : "list-item", hideAgendaStartTime: props.hideAgendaStartTime, titleLevel: ((props === null || props === void 0 ? void 0 : props.titleLevel) && (props === null || props === void 0 ? void 0 : props.titleLevel) <= 4
                                                            ? props.titleLevel + 1
                                                            : 4) }, formattedAgendaItem.id) }), i));
                                            }) }))] }), episode.id));
                            }) })) }))), props.tableDisplayFrom !== undefined && props.columnDisplayFrom === undefined && (_jsx("div", __assign({ className: classNames("".concat(baseClass, "__agenda-table"), (_d = {},
                            _d["".concat(baseClass, "__agenda-table--show-").concat(props.tableDisplayFrom)] = props.tableDisplayFrom !== undefined,
                            _d)), "data-testid": "agenda-table-container" }, { children: _jsx(Row, __assign({ gutter: rowGutter }, { children: episodesToShowInHomepage.map(function (episode) {
                                var _a;
                                return (_jsxs(Col, __assign({ "data-testid": "agenda-column", xs: 24, lg: 24, style: { height: "fit-content" } }, { children: [_jsxs("div", __assign({ className: "".concat(baseClass, "__table-title-content") }, { children: [_jsx(Typography.Title, __assign({ "data-testid": "agenda-table-episode-title", level: 2, className: "".concat(baseClass, "__table-title") }, { children: _jsx(Tag, { children: _jsxs(Space, { children: [_jsx(CalendarOutlined, { style: { fontSize: 35 } }), episode.dropdown_label || episode.title] }) }) })), _jsx("div", __assign({ "data-testid": "agenda-table-episode-timing-tag", className: "".concat(baseClass, "__table-title-timings") }, { children: contents.timetables })), _jsx("div", __assign({ "data-testid": "agenda-table-episode-speakers-tag", className: "".concat(baseClass, "__table-title-speakers") }, { children: contents.speakers_info }))] })), _jsx(Row, __assign({ gutter: rowGutter }, { children: (_a = episode.agenda) === null || _a === void 0 ? void 0 : _a.map(function (agendaItem, i) {
                                                var _a;
                                                var formattedAgendaItem = formatAgenda({ agendaItem: agendaItem });
                                                var hiddenSpeakerIds = !!(episode === null || episode === void 0 ? void 0 : episode.is_agenda_speakers_hidden)
                                                    ? (_a = episode.speakers) === null || _a === void 0 ? void 0 : _a.map(function (speaker) { return speaker.id; })
                                                    : episode.hidden_speakers_cards;
                                                return (_jsx(Col, __assign({ xs: 24 }, { children: _jsx(AgendaTableItem, { hiddenSpeakersId: hiddenSpeakerIds, agendaItem: formattedAgendaItem, hideAgendaStartTime: props.hideAgendaStartTime }, formattedAgendaItem.id) }), i));
                                            }) }))] }), episode.id));
                            }) })) })))] })))] })));
};
