var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Button from "../Buttons/Button";
import Col from "../Col/Col";
import Checkbox from "../Checkbox/Checkbox";
import Form from "../Form/Form";
import Input from "../Input/Input";
import Row from "../Row/Row";
import Typography from "../Typography/Typography";
import { isEmpty } from "../../../utils/obj-check";
import { useSelector } from "react-redux";
import { selectAppContent } from "@adm-media/adam-store-plugin";
var Title = Typography.Title;
var QAForm = function (_a) {
    var confirmationLabel = _a.confirmationLabel, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.loading, loading = _c === void 0 ? false : _c, onSubmit = _a.onSubmit, placeholder = _a.placeholder, question = _a.question, submitButtonText = _a.submitButtonText, title = _a.title, _d = _a.withConfirmation, withConfirmation = _d === void 0 ? false : _d;
    var form = Form.useForm()[0];
    var minLengthQuestion = 5;
    var _e = useState(true), disableButton = _e[0], setDisableButton = _e[1];
    var contents = useSelector(selectAppContent);
    var disableSubmit = function (e) {
        if (!isEmpty(e.target.value)) {
            setDisableButton(false);
        }
        else
            setDisableButton(true);
    };
    useEffect(function () {
        if (question) {
            form.setFieldsValue({
                question: question
            });
        }
    }, [question]);
    return (_jsxs(Row, { children: [title && (_jsx(Col, __assign({ span: 24 }, { children: _jsx(Title, __assign({ level: 4 }, { children: title })) }))), _jsx(Col, __assign({ span: 24 }, { children: _jsxs(Form, __assign({ form: form, layout: "vertical", onFinish: onSubmit, onChange: disableSubmit }, { children: [_jsx(Form.Item, __assign({ name: "question", label: contents.qna_form_textarea_label, rules: [{ required: true, message: contents.field_required }] }, { children: _jsx(Input.TextArea, { name: "qa-textarea-input", "data-testid": "qa-textarea", autoSize: { minRows: 4, maxRows: 10 }, className: "qa-form__input", placeholder: placeholder, minLength: minLengthQuestion, disabled: disabled }) })), withConfirmation && (_jsx(Form.Item, __assign({ name: "confirmed", valuePropName: "checked" }, { children: _jsx(Checkbox, { children: confirmationLabel }) }))), _jsx(Form.Item, { children: _jsx(Button, __assign({ "data-testid": "qa-submit-button", type: "primary", disabled: disabled || disableButton, className: "qa-form__button", htmlType: "submit", loading: loading }, { children: submitButtonText })) })] })) }))] }));
};
export default QAForm;
