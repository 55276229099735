import { jsx as _jsx } from "react/jsx-runtime";
import Result from "../../ui/Result/Status";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
export var ErrorModal = function (_a) {
    var content = _a.content;
    var errorMessage = content || "We apologise, something went wrong. <br /> We are working on it, please check back again shortly.";
    return _jsx(Result, { status: "500", title: _jsx(SanitizeHtml, { stringToSanitize: errorMessage, tag: "div" }) });
};
export var getErrorModalProps = function () {
    return {
        noLogo: true
    };
};
