import _isEmpty from "lodash/isEmpty";
import _isNull from "lodash/isNull";
/**
 * Check if input is empty
 * - Created utils so we don't import multiple time lodash pckg
 * @returns
 */
var isEmpty = function (input) {
    return _isEmpty(input);
};
var isNull = function (input) {
    return _isNull(input);
};
export { isEmpty, isNull };
