var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Navbar from "../components/blocks/Navbar/Navbar";
import { LogoutButton } from "../components/ui/Buttons/LogoutButton/LogoutButton";
import { moment } from "../utils/moment";
import { useSelector } from "react-redux";
import { selectAppContent, selectMedia, selectTimezone, selectVirtual } from "@adm-media/adam-store-plugin";
import Row from "../components/ui/Row/Row";
import Col from "../components/ui/Col/Col";
import Statistic from "../components/ui/Statistic/Statistic";
var CountdownComponent = Statistic.Countdown;
export var CountdownPage = function () {
    var timezone = useSelector(selectTimezone);
    var media = useSelector(selectMedia);
    var _a = useSelector(selectVirtual), startDate = _a.startDate, url = _a.url;
    var contents = useSelector(selectAppContent);
    var handleFinishCounter = function () { return window.location.replace(url || "/"); };
    return (_jsxs(Row, { children: [_jsx(Col, __assign({ span: 24 }, { children: _jsx(Navbar, { actions: [_jsx(LogoutButton, { className: "navbar-logout-button" }, "logout")], verticalSpacing: "small" }) })), _jsx(Col, __assign({ span: 24 }, { children: _jsxs("div", __assign({ className: "virtual-countdown" }, { children: [_jsx("h1", { children: contents.event_title }), _jsx("p", { dangerouslySetInnerHTML: { __html: contents.virtual_world_will_open } }), _jsx("div", __assign({ className: "countdown" }, { children: _jsx(CountdownComponent, { value: moment.tz(startDate, timezone || "utc").format("YYYY-MM-DDTHH:mm:ss"), format: "D[d] H[h] m[m] s[s]", onFinish: handleFinishCounter }) }))] })) }))] }));
};
