var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichRegistrationFormAnimalHealth = function () {
    var _a;
    var _b = useRegistrationForm(), timezonesSelectedCountry = _b.timezonesSelectedCountry, contents = _b.contents, countries = _b.countries, languages = _b.languages, roleInputValue = _b.roleInputValue, infoAboutEventInputValue = _b.infoAboutEventInputValue, getTranslatedOptions = _b.getTranslatedOptions, getRegistrationFormProps = _b.getRegistrationFormProps;
    var roles = function () {
        var _a;
        var CLINICAL_VETERINARIAN = "Clinical veterinarian";
        var VETERINARIAN_NURSE = "Veterinary nurse / technician";
        var RECEPTIONIST = "Receptionist";
        var CLINIC_MANAGEMENT = "Clinic management";
        var STUDENT = "Student";
        var ACADEMIA = "Academia";
        var INDUSTRY = "Industry";
        var OTHER = "Other";
        var allTranslatedRoles = (_a = {},
            _a[CLINICAL_VETERINARIAN] = "Clinical veterinarian",
            _a[VETERINARIAN_NURSE] = "Veterinary nurse / technician",
            _a[RECEPTIONIST] = "Receptionist",
            _a[CLINIC_MANAGEMENT] = "Clinic management",
            _a[STUDENT] = "Student",
            _a[ACADEMIA] = "Academia",
            _a[INDUSTRY] = "Industry",
            _a[OTHER] = "Other",
            _a);
        return allTranslatedRoles;
    };
    var aboutEventOptions = function () {
        var _a;
        var SALES_REPRESENTATIVE = "Sales representative";
        var EMAIL = "Email";
        var PRINTED_INVITATION = "Printed invitation";
        var FLYER = "Flyer";
        var ADVERTISEMENT = "Advertisement";
        var LINKEDIN = "LinkedIn";
        var COLLEAGUE = "Colleague";
        var OTHER = "Other";
        var allTranslatedAboutOptions = (_a = {},
            _a[SALES_REPRESENTATIVE] = "Sales representative",
            _a[EMAIL] = "Email",
            _a[PRINTED_INVITATION] = "Printed invitation",
            _a[FLYER] = "Flyer",
            _a[ADVERTISEMENT] = "Advertisement",
            _a[LINKEDIN] = "LinkedIn",
            _a[COLLEAGUE] = "Colleague",
            _a[OTHER] = "Other",
            _a);
        return allTranslatedAboutOptions;
    };
    var _c = contents, error_language_required = _c.error_language_required, error_lastname_required = _c.error_lastname_required, error_firstname_required = _c.error_firstname_required, error_email_required = _c.error_email_required, error_country_required = _c.error_country_required, 
    // error_role_required,
    email = _c.email, language = _c.language, firstname = _c.firstname, lastname = _c.lastname, country = _c.country, specialty = _c.specialty
    // licence_number_label,
    // licence_state_label,
    // timezone
    // role
    ;
    var inputs = [];
    var languageInput = languages.length > 1
        ? {
            label: language,
            name: "lang_id",
            options: languages,
            required: true,
            type: "select",
            rules: [{ required: true, message: error_language_required }]
        }
        : {
            type: "hidden",
            name: "lang_id",
            value: languages[0].value
        };
    inputs.push(languageInput);
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            type: "text",
            label: firstname,
            required: true,
            name: "firstname",
            rules: [
                {
                    required: true,
                    message: error_firstname_required
                }
            ]
        },
        {
            label: lastname,
            name: "lastname",
            required: true,
            type: "text",
            rules: [
                {
                    required: true,
                    message: error_lastname_required
                }
            ]
        },
        {
            label: "Role",
            name: "role",
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: "Error role required"
                }
            ],
            options: getTranslatedOptions(roles())
        }
    ], false);
    if (roleInputValue === "Other") {
        inputs.push({
            type: "text",
            label: "Other role",
            required: true,
            name: "other_role",
            rules: [
                {
                    required: true,
                    message: "Error role required"
                }
            ]
        });
    }
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            label: email,
            name: "email",
            required: true,
            type: "email",
            rules: [
                {
                    required: true,
                    message: error_email_required
                }
            ]
        },
        {
            label: country,
            name: "country_id",
            options: countries,
            required: true,
            type: "select",
            rules: [
                {
                    required: true,
                    message: error_country_required
                }
            ]
        },
        {
            type: timezonesSelectedCountry.length > 1 ? "select" : "hidden",
            label: "Timezone",
            required: timezonesSelectedCountry.length > 1 ? true : false,
            name: "timezone",
            options: timezonesSelectedCountry,
            value: timezonesSelectedCountry.length > 1 ? null : (_a = timezonesSelectedCountry[0]) === null || _a === void 0 ? void 0 : _a.value,
            showSearch: true
        },
        {
            type: "select",
            label: "Learn about event",
            required: true,
            name: "info_about_event",
            options: getTranslatedOptions(aboutEventOptions())
        }
    ], false);
    if (infoAboutEventInputValue === "Other") {
        inputs.push({
            type: "text",
            label: "Other info about event",
            required: true,
            name: "other_about_event",
            rules: [
                {
                    required: true,
                    message: "Info about event required"
                }
            ]
        });
    }
    inputs = __spreadArray(__spreadArray([], inputs, true), [
        {
            type: "text",
            label: "Licence State number",
            required: false,
            name: "licence_number"
        },
        {
            type: "text",
            label: "Licence State label",
            required: false,
            name: "licence_state"
        }
    ], false);
    return getRegistrationFormProps({ inputs: inputs });
};
